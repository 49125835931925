import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import LockIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import { AppContext, withContext } from './App';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import ErrorIcon from '@material-ui/icons/Error';
import { Redirect } from 'react-router-dom';
import { withI18n } from 'react-i18next';
import NativeSelect from '@material-ui/core/NativeSelect';
import i18n from 'i18next';
import { refreshPersonalization, refreshModels } from './refresh';
import PasswordField from 'material-ui-password-field';

const styles = theme => ({
	
	layout: {
		width: 'auto',
		display: 'block', // Fix IE 11 issue.
		marginLeft: theme.spacing.unit * 3,
		marginRight: theme.spacing.unit * 3,
		[theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
			width: 400,
			marginLeft: 'auto',
			marginRight: 'auto',
		},
	},
	
	paperHeader: {
		marginTop: theme.spacing.unit * 2,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: "10px",
	},

	paper: {
		marginTop: 0,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
	},
	
	avatar: {
		margin: theme.spacing.unit,
	},
	
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing.unit,
	},
	
	submit: {
		marginTop: theme.spacing.unit * 3,
	},
	
	forgotPasswordButton: {
		marginTop: theme.spacing.unit,
	},
	
	icon: {
		fontSize: 20,
		marginRight: theme.spacing.unit,
	},
	
	snackbarError: {
		backgroundColor: theme.palette.error.dark,
	},
	
	snackbar: {
	},
	
	message: {
		display: 'flex',
		alignItems: 'center',
	},
});

class SignIn extends Component {
	
	constructor(props) {
		super(props);
		
		this.state = {
			snackbarClosed: true,
			errorMessage: null,
			message: null,
			username: "",
			password: "",
			verificationCode: "",
			newPassword: "",
			newPasswordRepeat: "",
			display: "none",
			changePassword: false,
			showLogoInMenu: false,
			showLogoInHeader: false,
		};
		
		this.handleCloseSnackbar = this.handleCloseSnackbar.bind(this);
		this.handleLogin = this.handleLogin.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleChangePassword = this.handleChangePassword.bind(this);
		this.handleLanguageChange = this.handleLanguageChange.bind(this);
	}
	
	componentDidMount() {
		this.props.context.showActivityIndicator();
		refreshPersonalization(this.props.theme, this.props.context.baseUrl).then(result => {
			//this.forceUpdate();
			
			if (this.props.theme.supportedLanguages.filter(language => i18n.language == language).length == 0) {
				i18n.changeLanguage(this.props.theme.supportedLanguages[0]);
			}
			else {
				i18n.changeLanguage(i18n.language);
			}
			
			if (this.props.theme.enableIAM && window.location.hash == "") {
				document.location = (this.props.theme.iamExternalUrl != null ? this.props.theme.iamExternalUrl : "") + '/auth/realms/airflows/protocol/openid-connect/auth?response_type=token&client_id=airflows&redirect_uri=' + window.location;
			}
			else {
				this.setState({
					showLogoInMenu: this.props.theme.showLogoInMenu,
					showLogoInHeader: this.props.theme.showLogoInHeader,
					display: (window.location.hash != null && window.location.hash != "" ? "hidden" : ""),
					language: i18n.language,
				}, () => {
					let hash = window.location.hash;
					if (hash != null && hash != "") {
						hash.split(/[#&,]/).forEach(item => { 
							if (item.split("=")[0] == "access_token") { 
								let accessToken = item.split("=")[1];
								
								const query2 = '{ refreshToken(token: "' + accessToken + '") }';
								let variables2 = {
									"authorization": accessToken,
						    	};
								
								let request2 = JSON.stringify({query: query2, variables: variables2});
								fetch(this.props.context.baseUrl + "/graphql", {
									method: "POST",
									body: request2
								})
								.then(response => response.json())
								.then(json2 => {
									if (json2.errors != null) {
										console.log(json2.errors);
									}
									else {
										accessToken = json2.data.refreshToken;
										
										const query = '{ getCurrentUser { currentUser locale }}';
										
										let variables = {
											"authorization": accessToken,
								    	};
										let request = JSON.stringify({query: query, variables: variables});
										fetch(this.props.context.baseUrl + "/graphql", {
											method: "POST",
											body: request
										})
										.then(response => response.json())
										.then(json => {
											if (json.errors != null) {
												console.log(json.errors);
											}
											else {
												// Si el usuario en el IAM tiene un locale, utilizarlo para seleccionar el idioma
												let locale = json.data.getCurrentUser.locale;
												if (locale != null) {
													let selectedLanguages = this.props.theme.supportedLanguages.filter(language => language == locale);
													if (selectedLanguages.length > 0) {
														i18n.changeLanguage(selectedLanguages[0]);
													}
													else {
														selectedLanguages = this.props.theme.supportedLanguages.filter(language => language.startsWith(locale));
														if (selectedLanguages.length > 0) {
															i18n.changeLanguage(selectedLanguages[0]);
														}
													}
												}
												if (json.data.getCurrentUser.currentUser != null) { // && json.data.getCurrentUser.currentUser != "anonymous") {
													refreshModels(accessToken, json.data.getCurrentUser.currentUser, this.props.context).then(result => {
														//this.forceUpdate();
													});
												}
												else {
													this.setState({
														display: "",
													}, () => {
														this.props.context.hideActivityIndicator();
													});
												}
											}
										});
									}
								});
							}
						});
					}
					else {
						this.props.context.hideActivityIndicator();
					}
				});
			}
		});
	}
	
	handleLanguageChange(event) {
		i18n.changeLanguage(event.target.value);
		this.setState({
			language: event.target.value
		});
	}
	
	handleCloseSnackbar(event) {
		this.setState({
			snackbarClosed: true
		});
	}
	
	handleLogin(event) {
		const { t } = this.props;
		
		event.preventDefault();
		
		this.props.context.showActivityIndicator();
		
		const username = this.state.username;
		const password = this.state.password;
		const verificationCode = this.state.verificationCode;
		
		//console.log("Username: " + username + ", Password: " + password);
		
		const query = 'mutation login(' +
			'  $username: String!' +
			'  $password: String!' +
			'  $verificationCode: String' +
			'){' +
			'  login(' +
			'    username: $username' +
			'    password: $password' +
			'    verificationCode: $verificationCode' +
			'  )' +
			'}';
		
		let variables = {
			"username": username,
			"password": password,
			"verificationCode": verificationCode
    	};
		let request = JSON.stringify({query: query, variables: variables});
		fetch(this.props.context.baseUrl + "/graphql", {
			method: "POST",
			body: request
		})
		.then(response => response.json())
		.then(json => {
			if (json.errors != null) {
				console.log(json.errors);
			}
			else {
				if (json.data.login == null) {
					this.setState({
						errorMessage: true,
						message: t('incorrectUsernameOrPassword'),
						snackbarClosed: false
					});
					document.cookie = 'loginstatus=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
					localStorage.removeItem("access_token");
					this.props.context.setState({
						username: null,
						accessToken: null,
						model: null
					});
					this.props.context.hideActivityIndicator();
				}
				else if (password == "Admin1234!") {
					this.props.context.hideActivityIndicator();
					document.cookie = "loginstatus=loggedin; path=/;";
					localStorage.setItem("access_token", json.data.login);
					this.setState({
						changePassword: true,
						accessToken: json.data.login,
					});
				}
				else {
					document.cookie = "loginstatus=loggedin; path=/;";
					localStorage.setItem("access_token", json.data.login);
					refreshModels(json.data.login, username, this.props.context).then(result => {
						this.forceUpdate();
					});
				}
			}
		});
	}
	
	handleChange(event) {
		const target = event.target;
		const value = (target.type === 'checkbox' ? target.checked : target.value);
		this.setState({
			[target.name]: value
		});
	}

	handleChangePassword(event) {
		event.preventDefault();
		
		//console.log(this.state.username);
		//console.log(this.state.password);
		//console.log(this.state.newPassword);
		//console.log(this.state.newPasswordRepeat);
		//console.log(this.state.accessToken);
		
		const { t } = this.props;
		
		if (this.state.newPassword != this.state.newPasswordRepeat) {
			this.setState({
				errorMessage: true,
				message: t('passwordsDontMatch'),
				snackbarClosed: false
			}, () => {
				this.props.context.hideActivityIndicator();
			});
		}
		else if (this.state.newPassword == this.state.password) {
			this.setState({
				errorMessage: true,
				message: t('chooseANewPassword'),
				snackbarClosed: false
			}, () => {
				this.props.context.hideActivityIndicator();
			});
		}
		else {
			this.props.context.showActivityIndicator();

			const query = 'mutation changePassword( ' +
				'  $username: String! ' +
				'  $password: String! ' +
				') {' +
				'  Models_UserUpdate( ' +
				'    entity: {' +
				'      password: $password ' +
				'    } ' +
				'  where: { ' +
				'    username: {EQ: $username} ' +
				'  }) {id}' +
				'}';

			let variables = {
				username: this.state.username,
				password: this.state.newPassword,
	    		authorization: this.state.accessToken,
	    	};
			let request = JSON.stringify({query: query, variables: variables});
			fetch(this.props.context.baseUrl + "/graphql", {
				method: "POST",
				body: request
			})
			.then(response => response.json())
			.then(json => {
				if (json.errors != null) {
					this.setState({
						errorMessage: true,
						message: json.errors[0].message,
						snackbarClosed: false
					}, () => {
						this.props.context.hideActivityIndicator();
					});
				}
				else {
					this.setState({
						changePassword: false,
						accessToken: null,
						password: "",
						errorMessage: false,
						message: t('passwordChangeSuccess'),
						snackbarClosed: false
					}, () => {
						this.props.context.hideActivityIndicator();
					});
				}
			});
		}
	}
	
	render() {
		const { classes, t } = this.props;
		
		return (
			<React.Fragment>
				<CssBaseline />
				
				<AppContext.Consumer> 
					{(context) => (context.accessToken != null ? <Redirect to="/admin"/> : null)}
				</AppContext.Consumer>
				
				<Snackbar
						data-qa="message-snackbar"
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'left',
						}}
						autoHideDuration={5000}
						onClose={this.handleCloseSnackbar}
						open={!this.state.snackbarClosed}>
					<SnackbarContent
							className={this.state.errorMessage ? classes.snackbarError : classes.snackbar}
							message={<><span className={classes.message}>{this.state.errorMessage && <ErrorIcon className={classes.icon}/>}{this.state.message}</span></>}
					/>
				</Snackbar>
				
				<main className={classes.layout}>
					<Paper square elevation={1}>
						<div className={classes.paperHeader} style={{ display: this.state.display, backgroundColor: (!this.state.showLogoInHeader ? "#FFFFFF" : this.props.theme.palette.primary.main),}}>
							<img src={this.props.theme.customerLogoImageSrc} style={{maxWidth: "288px", maxHeight: "90px"}} alt=""/>
						</div>
						<div className={classes.paper} style={{display: this.state.display}}>
	
							<Avatar className={classes.avatar} style={{backgroundColor: this.props.theme.palette.secondary.main}}>
								<LockIcon />
							</Avatar>
	
							{(!this.state.changePassword && 
								<>
									<Typography variant="h5">
										{t('accessControl')}
									</Typography>
									<form className={classes.form} onSubmit={this.handleLogin}>
										<FormControl margin="normal" required fullWidth>
											<InputLabel shrink htmlFor="username">{t('username')}</InputLabel>
											<Input inputProps={{"data-qa": "username-input"}}
													id="username" 
													name="username" 
													autoComplete="username" 
													value={this.state.username} 
													onChange={this.handleChange} 
													autoFocus={this.state.display == ""}/>
										</FormControl>
										
										<FormControl margin="normal" required fullWidth>
											<InputLabel shrink htmlFor="password">{t('password')}</InputLabel>
											<PasswordField 
													inputProps={{"data-qa": "password-input"}}
													type="password" 
													id="password" 
													name="password" 
													autoComplete="current-password" 
													value={this.state.password} 
													onChange={this.handleChange}/>
										</FormControl>
										
										<FormControl margin="normal" fullWidth>
											<InputLabel shrink htmlFor="verificationCode">{t('twoFactorAuthenticationVerificationCode')}</InputLabel>
											<Input inputProps={{"data-qa": "verificationCode-input"}}
													id="verificationCode" 
													name="verificationCode" 
													value={this.state.verificationCode} 
													onChange={this.handleChange}/>
										</FormControl>
										
										<FormControl margin="normal" required fullWidth>
											<InputLabel htmlFor="language">{t('language')}</InputLabel>
											<NativeSelect
													id="language" 
													name="language"
													value={this.state.language}
													onChange={this.handleLanguageChange}
													inputProps={{
														name: 'Idioma',
														"data-qa": "language-input"
													}}>
												{
													this.props.theme.supportedLanguages.map(supportedLanguage => 
														<option key={supportedLanguage} value={supportedLanguage}>{t('supportedLanguages.' + supportedLanguage)}</option>
													)
												}
											</NativeSelect>
										</FormControl>
										
										<Button data-qa="login-button" type="submit" color="secondary" variant="outlined" style={{marginTop: "30px", borderRadius: "5px"}} fullWidth disableRipple className={classes.submit}>
											{t('login')}
										</Button>
										{/*
										<Button data-qa="forgot-password-button" variant="text" fullWidth className={classes.forgotPasswordButton} style={{textTransform: "none"}}>
											{t('forgotPassword')}
										</Button>
										*/}
									</form>
								</>
							)
							
							|| (
								<>
									<Typography variant="h5">
										{t('changePassword')}
									</Typography>
									<Typography>
										{t('changePasswordDescription')}
									</Typography>
									<form className={classes.form} onSubmit={this.handleChangePassword}>
										<FormControl margin="normal" required fullWidth>
											<InputLabel shrink htmlFor="password">{t('newPassword')}</InputLabel>
											<PasswordField
													name="newPassword"
													required 
													autoComplete="off"
													inputProps={{
														"data-qa": "newPassword-input",
														pattern: "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\@\#\$\!\%\*\?\&\_])[A-Za-z0-9\@\#\$\!\%\*\?\&\_]{8,}$",
														title: t('invalidPassword'),
													}}
													autoFocus
													value={this.state.newPassword}
													onChange={this.handleChange}/>
										</FormControl>
										
										<FormControl margin="normal" required fullWidth>
											<InputLabel shrink htmlFor="password">{t('newPasswordRepeat')}</InputLabel>
											<PasswordField
													name="newPasswordRepeat"
													required 
													autoComplete="off"
													inputProps={{
														"data-qa": "newPasswordRepeat-input",
														pattern: "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\@\#\$\!\%\*\?\&\_])[A-Za-z0-9\@\#\$\!\%\*\?\&\_]{8,}$",
														title: t('invalidPassword'),
													}}
													value={this.state.newPasswordRepeat}
													onChange={this.handleChange}/>
										</FormControl>
										
										<Button data-qa="change-password-button" type="submit" variant="contained" fullWidth className={classes.submit}>
											{t('toChangePassword')}
										</Button>
									</form>
								</>
							)}
						</div>
					</Paper>
				</main>
				<div style={{position: "fixed", right: "10px", bottom: "10px", display: "flex", alignItems: "flex-end"}}>
					<Typography
							variant="body2"
							noWrap>
						Powered by&nbsp;&nbsp;
					</Typography>
					<a href="https://airflows.com" target="_blank" rel="noopener noreferrer"><img src="./logo-standard.png" className={classes.icon} style={{width: "90px"}} alt="Airflows"/></a>
				</div>
			</React.Fragment>
		);
	}
}

SignIn.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(withContext(withI18n()(SignIn)));
