import i18n from 'i18next';
import { reactI18nextModule } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

export const resources = {
	en_US: {
		translation: {
			"title": "Data Administration",
			
			"modelingTool": "Modeling tool",
			
			"manage": "Manage",
			"open": "View selected",

			"getMoreData": "Load more data",
			
			"anyValue": "Any value",
			"notSpecified": "Value not specified",
			
			"logViewer": "Log Viewer",
			"tail": "Number of lines to load",
			"refreshLog": "Refresh log",
			"clearLog": "Clear log",

			"fileRejected": "File rejected.",
			"fileTypeNotSupported": "File type not supported.",
			"fileTooBig": "Size limit is ${limit}.",
			
			"filtersApplied": "The result is filtered",
			"accessControl": "Access control",
			"changePassword": "Change Password",
			"toChangePassword": "Change",
			"changePasswordDescription": "Please, set a new password",
			"passwordChangeSuccess": "Password changed successfully",
			"newPassword": "New password",
			"newPasswordRepeat": "Repeat new password",
			"passwordsDontMatch": "Password don't match",
			"chooseANewPassword": "Please, choose a new password",
			"username": "Username",
			"password": "Password",
			"twoFactorAuthenticationVerificationCode": "2FA Verification code",
			"language": "Language",
			"login": "Login",
			"forgotPassword": "Forgot password?",
			"incorrectUsernameOrPassword": "Access denied",
			"invalidPassword": "Password must be at least 8 characters long, and have 1 uppercase letter, 1 lowercase letter, 1 digit and 1 symbol",
			"generateCode": "Generate code",
			"logicCategory": "Logic",
			"loopsCategory": "Loops",
			"mathCategory": "Math",
			"textCategory": "Text",
			"listsCategory": "Lists",
			"variablesCategory": "Variables",

			"userInfo": "User info",
			"user": "User",
			
			"fullscreen": "Fullscreen mode",
			
			"prediction": "Prediction",
			
			"error": "An unknown error has occurred",
			
			"primaryKeyWithOnlyOneAttributeNeeded": "The primary key can only have one attribute",
			
			"invalidValue": "The entered value is not valid",
			
			"selected": "selected (s)",
			
			"deleteConfirmation": "Confirmation",
			"deleteQuestion": "Delete",
			"document": "document",
			
			"cancel": "Cancel",
			"save": "Save",
			"scanMrz": "Scan MRZ (Machine Readable Zone) from identity card",
			
			"mrzError": "Read error or unknown MRZ",
			
			"clear": "Delete",
			
			"zoomToFit": "Zoom to fit",
			
			"scanBarcode": "Scan barcode",
			
			"with": "with",
			
			"dragOrSelect": "Drag or select the document that you want to add",
			"multipleDragOrSelect": "Drag or select the documents that you want to add",
			
			"latitude": "Latitude",
			"longitude": "Longitude",
			
			"tableView": "Table view",
			"calendarView": "Calendar view",
			"mapView": "Map view",
			"galleryView": "Gallery view",
			"diagramView": "Diagram view",
			
			"calendarCulture": "is",
			"calendarDate": "Date",
			"calendarTime": "Time",
			"calendarEvent": "Event",
			"calendarAllDay": "All day",
			"calendarWeek": "Week",
			"calendarWorkWeek": "Work week",
			"calendarDay": "Day",
			"calendarMonth": "Month",
			"calendarPrevious": "Previous",
			"calendarNext": "Next",
			"calendarYesterday": "Yesterday",
			"calendarTomorrow": "Tomorrow",
			"calendarToday": "Today",
			"calendarAgenda": "Agenda",
			
			"view": "View",
			"edit": "Edit",
			"delete": "Delete",
			"copyShortUrlToClipboard": "Copy short URL to clipboard",
			"download": "Download",
			"exportToCSV": "Export to Excel",
			"new": "Add",
			"newRecord": "New",
			
			"fileContents": "File contents",
			
			"noOptions": "No options",
			"loading": "Loading ...",
			"selectValue": "",
			
			"noData": "No results found",
			"noDataWithImage": "No results with images found",
			
			"enableBasicFilters": "Show basic filters",
			"disableBasicFilters": "Hide basic filters",

			"back": "Go back",
			"forward": "Go forward",
			"refresh": "Refresh page",
			"sessionInfo": "Session info",
			"enableAIAssistant": "Enable AI assistant",
			"disableAIAssistant": "Disable AI assistant",
			
			"enableIntelligence": "Enable intelligence",
			"disableIntelligence": "Disable intelligence",
			
			"search": "Search",
			"applyFilter": "Apply search filter",
			"searchHowTo": "Search expression must consist of single words separated by the operators & (AND), | (OR) and ! (NOT). These operators can be grouped using parentheses. Also, :* can be attached to a word to specify prefix matching (i.e. 'super:*'). It can also accept quoted phrases (i.e. '\"best price\"'). Asterisks can be used to indicate contained searches (i.e. '*bla*').",
			"searchCriteria": "Search criteria",
			"clearCriteria": "Clear search criteria",
			
			"deleteSuccess": "item (s) deleted (s) correctly",
			
			"referenceCreated": "Reference created successfully",
			"referenceDeleted": "Reference deleted successfully",
			"deleteNotImplemented": "Delete from diagram is not implemented",
			
			"virtualAnalyst": "Indicators",
			
			"closeSession": "Logout",
			"documentation": "Documentation",

			"searchFilter": "Search filter",

			"adminGroup": "Admin",
			"dashboardsGroup": "Dashboards",
			"admin": "Settings",
			"backupBackup": "Backup",
			"downloadBackup": "Make and download backup",

			"systemTab": "System info",
			"exportImportTab": "Export / Import",
			"domainTab": "Domain",
			"iamTab": "IAM",
			"dashboardsTab": "Dashboards",
			"databaseTab": "Database",
			"otherTab": "Other parameters",
			
			"excelExport": "Export model to Excel",
			"excelImport": "Import data or model from Excel or photo",
			"downloadExcel": "Download model", 
			"uploadExcel":  "Upload data",
			"importConfirmation": "Import confirmation",
			"importQuestion": "You are going to import data, Are you sure?",
			"import": "Import",
			"importSuccess": "The data has been imported successfully",
			
			"updateFQDN": "Update FQDN (Fully Qualified Domain Name)",
			"fqdn": "FQDN (Fully Qualified Domain Name)",
			"changeFQDN": "Apply change",
			"confirmFQDNChange": "SSL certificate will be generated for the new FQDN and platform will be restarted, Are you sure?",
			"newFQDNDoesNotPointToIP": "Please, make sure the new FQDN points to the correct IP address",
			"newFQDNTryAgain": "Then, try again.",

			"updateEmbeddingSecretKey": "Update Metabase embedding secret key",
			"embeddingSecretKey": "Embedding secret key",
			"changeEmbeddingSecretKey": "Apply change",
			"confirmEmbeddingSecretKeyChange": "Metabase's embedding secret key will be updated, Are you sure?",
			"updateEmbeddingSecretKeySuccess": "Metabase's embedding secret key updated successfully",
			
			"updateDocumentMaxFileSizeMB": "Update maximum document file size",
			"documentMaxFileSizeMB": "Maximum document file size (MB)",
			"changeDocumentMaxFileSizeMB": "Apply change",
			"confirmDocumentMaxFileSizeMBChange": "Maximum document file size will be updated, Are you sure?",
			"updateDocumentMaxFileSizeMBSuccess": "Maximum document file size updated successfully",
			"updateDocumentMaxFileSizeMBError": "Please, specify a valid value between 1 and 2000",
			
			"updateIAM": "Update IAM configuration",
			"publicKey": "Public key",
			"defaultRole": "Default role",
			"externalUrl": "External IAM URL",
			"changeIAM": "Apply change",
			"confirmIAMChange": "IAM's configuration will be updated, Are you sure?",
			"updateIAMSuccess": "IAM's configuration updated successfully",
			
			"version": "Platform version",
			"edition": "Edition",
			
			"dataVolumeSizes": "Data volume size",
			"dataVolumeSize": "Size",
			"dataVolumeUsed": "Used",
			"dataVolumeAvailable": "Available",
			"unknown": "Unknown",
			
			"cpus": "CPU(s)",
			
			"pitrBackup": "Backup database",
			"pitrBackup": "Request database base backup",
			"pitrDeletePrevious": "Delete previous database base backups",
			"pitrBackupConfirmation": "Backup confirmation",
			"pitrDeletePreviousConfirmation": "Delete previous backups confirmation",
			"pitrBackupQuestion": "You are going to request a new base backup, Are you sure?",
			"pitrDeletePreviousQuestion": "You are going to delete previous base backups, Are you sure?",
			"pitrBackupSuccess": "The system has been successfully backed up",
			"pitrDeletePreviousSuccess": "Previous database base backups has been successfully deleted",
			
			"pitrExport": "Export base backup",
			"pitrDownload": "Download base backup", 
			
			"pitrRestore": "Restore database",
			"pitrRestoreTargetTime": "Target timestamp (UTC)",
			"pitrRestoreConfirmation": "Restore database confirmation",
			"pitrRestoreQuestion": "You are going to restore the database, Are you sure?",
			"pitrRestoreSuccess": "The system has been successfully restored",
			
			"deleteDatabase": "Delete database",
			"deleteRestoreToDefault": "Delete database and restore to factory values",
			"deleteDatabaseConfirmation": "Delete confirmation",
			"deleteDatabaseQuestion": "You are going to erase all the data, Are you sure?",
			"deleteDatabaseSuccess": "The system has been successfully restored to the factory settings",

			"restartPlatform": "Restart platform",
			"restartPlatformConfirmation": "Restart platform",
			"restartPlatformQuestion": "You are going to restart the platform, Are you sure?",
			"restartPlatformSuccess": "The platform has been successfully restarted",
			
			"backupRestore": "Restore backup",
			"uploadBackup": "Drag or select the backup file that you want to restore",
			"restoreConfirmation": "Restoration confirmation",
			"restoreQuestion": "You are going to restore a backup of the system, all data will be deleted, Are you sure?",
			"restore": "Restore",
			"restoreSuccess": "The backup has been restored successfully",
			"restoreOnlyOneFile": "Please select only one file",
			
			"supportedLanguages": {
				"af": "Afrikaans",
				"am": "Amharic",
				"bg": "Bulgarian",
				"ca": "Catalan",
				"zh_HK": "Chinese (Hong Kong)",
				"zh_CN": "Chinese (PRC)",
				"zh_TW": "Chinese (Taiwan)",
				"hr": "Croatian",
				"cs": "Czech",
				"da": "Danish",
				"nl": "Dutch",
				"en_GB": "English (UK)",
				"en_US": "English (US)",
				"et": "Estonian",
				"fil": "Filipino",
				"fi": "Finnish",
				"fr_CA": "French (Canada)",
				"fr_FR": "French (France)",
				"de": "German",
				"el": "Greek",
				"he": "Hebrew",
				"hi": "Hindi",
				"hu": "Hungarian",
				"is": "Icelandic",
				"id": "Indonesian",
				"it": "Italian",
				"ja": "Japanese",
				"ko": "Korean",
				"lv": "Latvian",
				"lt": "Lithuanian",
				"ms": "Malay",
				"no": "Norwegian",
				"pl": "Polish",
				"pt_BR": "Portuguese (Brazil)",
				"pt_PT": "Portuguese (Portugal)",
				"ro": "Romanian",
				"ru": "Russian",
				"sr": "Serbian",
				"sk": "Slovak",
				"sl": "Slovenian",
				"es_419": "Spanish (Latin America)",
				"es_ES": "Spanish (Spain)",
				"sw": "Swahili",
				"sv": "Swedish",
				"th": "Thai",
				"tr": "Turkish",
				"uk": "Ukrainian",
				"vi": "Vietnamese",
				"zu": "Zulu",
			},
			
			// enums = EnumTypes
			"enums": {
				// Schemas
				"Models": {
					"metricType": {
						"v": {
							"AUC": "AUC",
							"Accuracy": "Accuracy",
							"BinaryAccuracy": "Binary accuracy",
							"BinaryCrossentropy": "Binary crossentropy",
							"BinaryIoU": "Binary intersection over union",
							"CategoricalAccuracy": "Categorical accuracy",
							"CategoricalCrossentropy": "Categorical crossentropy",
							"CategoricalHinge": "Categorical hinge",
							"CosineSimilarity": "Cosine similarity",
							"FalseNegatives": "False negatives",
							"FalsePositives": "False positives",
							"Hinge": "Hinge",
							"KLDivergence": "Kullback-Libler divergence",
							"LogCoshError": "Log cosh error",
							"MeanAbsoluteError": "Mean absolute error",
							"MeanAbsolutePercentageError": "Mean absolute percentage error",
							"MeanSquaredError": "Mean squared error",
							"MeanSquaredLogarithmicError": "Mean squared logarithmic error",
							"Poisson": "Poisson",
							"Precision": "Precision",
							"Recall": "Recall",
							"RootMeanSquaredError": "Root mean squared error",
							"SparseCategoricalAccuracy": "Sparse categorical accuracy",
							"SparseTopKCategoricalAccuracy": "Sparse top k categorical accuracy",
							"SquaredHinge": "Squared hinge",
							"TopKCategoricalAccuracy": "Top k categorical accuracy",
							"TrueNegatives": "True negatives",
							"TruePositives": "True positives",
						},
					},
					"objectiveDirectionType": {
						"v": {
							"min": "Minimize",
							"max": "Maximize",
						},
					},
					"problemType": {
						"v": {
							"CLASSIFICATION": "Classification (Classify/Predict a discrete value such as Male or Female, True or False, ...)",
							"REGRESSION": "Regression (Predict a continuous value such as price, salary, age, ...)",
						},
					},
					"trainingPeriodicityType": {
						"v": {
							"DAILY": "Daily",
							"WEEKLY": "Weekly",
							"MONTHLY": "Monthly",
						},
					},
					"humanLanguageType": {
						"v": {
							"af": "Afrikaans",
							"am": "Amharic",
							"bg": "Bulgarian",
							"ca": "Catalan",
							"zh_HK": "Chinese (Hong Kong)",
							"zh_CN": "Chinese (PRC)",
							"zh_TW": "Chinese (Taiwan)",
							"hr": "Croatian",
							"cs": "Czech",
							"da": "Danish",
							"nl": "Dutch",
							"en_GB": "English (UK)",
							"en_US": "English (US)",
							"et": "Estonian",
							"fil": "Filipino",
							"fi": "Finnish",
							"fr_CA": "French (Canada)",
							"fr_FR": "French (France)",
							"de": "German",
							"el": "Greek",
							"he": "Hebrew",
							"hi": "Hindi",
							"hu": "Hungarian",
							"is": "Icelandic",
							"id": "Indonesian",
							"it": "Italian",
							"ja": "Japanese",
							"ko": "Korean",
							"lv": "Latvian",
							"lt": "Lithuanian",
							"ms": "Malay",
							"no": "Norwegian",
							"pl": "Polish",
							"pt_BR": "Portuguese (Brazil)",
							"pt_PT": "Portuguese (Portugal)",
							"ro": "Romanian",
							"ru": "Russian",
							"sr": "Serbian",
							"sk": "Slovak",
							"sl": "Slovenian",
							"es_419": "Spanish (Latin America)",
							"es_ES": "Spanish (Spain)",
							"sw": "Swahili",
							"sv": "Swedish",
							"th": "Thai",
							"tr": "Turkish",
							"uk": "Ukrainian",
							"vi": "Vietnamese",
							"zu": "Zulu",
						}
					},
					"entityPermissionType": {
						"v": {
							"SELECT": "Query permission",
							"INSERT": "Create permission",
							"UPDATE": "Update permission",
							"DELETE": "Delete permission",
							"MENU": "Menu permission"
						}
					},
					"policyAsType": {
						"v": {
							"PERMISSIVE": "Permissive policy",
							"RESTRICTIVE": "Restrictive policy",
						}
					},
					"policyForType": {
						"v": {
							"ALL": "Apply to all operations",
							"SELECT": "Query operations",
							"INSERT": "Insert operations",
							"UPDATE": "Update operations",
							"DELETE": "Delete operations",
						}
					},
					"accessType": {
						"v": {
							"ACCEPT": "Accept access",
							"REJECT": "Reject access",
						}
					},
					"barcodeType": {
						"v": {
							"EAN_8": "EAN-8",
							"EAN_13": "EAN-13",
							"CODE_39": "Code 39",
							"CODE_128": "Code 128",
							"ITF": "ITF",
							"RSS_14": "RSS-14",
							"QR_CODE": "QR Code",
							"DATA_MATRIX": "Data Matrix",
							"PDF_417": "PDF 417",
						}
					},
					"entityAttributePermissionType": {
						"v": {
							"SELECT": "Query permission",
							"INSERT": "Permission to create",
							"UPDATE": "Modification permission"
						}
					},
					"customTypeAttributeType": {
						"v": {
							"TEXT": "Text string of any length",
							"BOOLEAN": "Yes or no",
							"INTEGER": "Whole number (from -2147483648 to 2147483647)",
							"DECIMAL": "Number with decimals of any precision",
							"MONEY": "Amount of currency (from -92233720368547758.08 to 92233720368547758.07)",
							"DATE": "Date",
							"TIMESTAMP": "Date and time (with microsecond accuracy)",
							"CUSTOM_TYPE": "Custom type",
							"BYTEA": "Raw binary data of any size",
							"SMALLINT": "Small whole number (from -32768 to 32767)",
							"BIGINT": "Large whole number (from -9223372036854775808 to 9223372036854775807)",
							"DOUBLE_PRECISION": "Number with up to 15 decimals",
							"REAL": "Number with up to 6 decimals",
							"VARCHAR": "Variable length text string, with limit",
							"CHAR": "Fixed-length text string, filled with spaces",
							"TIME": "Time (with microsecond accuracy)",
							"INTERVAL": "Time interval",
							"TIMESTAMP_WITH_TIME_ZONE": "Date and time with time zone",
							"TIME_WITH_TIME_ZONE": "Time with time zone",
							"POINT": "Point in a plane (x, y)",
							"POLYGON": "Polygon ((x1, y1), ...)",
						}
					},
					"entityAttributeType": {
						"v": {
							"TEXT": "Text string",
							"BOOLEAN": "Yes or no",
							"INTEGER": "Whole number",
							"DECIMAL": "Number with decimals",
							"MONEY": "Amount of currency (from -92233720368547758.08 to 92233720368547758.07)",
							"DATE": "Date",
							"TIMESTAMP": "Date and time",
							"CUSTOM_TYPE": "Custom type",
							"SERIAL": "Sequential number",
							"BYTEA": "Binary data of any size (images, sound, ...)",
							"SMALLINT": "Small whole number (from -32768 to 32767)",
							"BIGINT": "Large whole number (from -9223372036854775808 to 9223372036854775807)",
							"DOUBLE_PRECISION": "Number with up to 15 decimals",
							"REAL": "Number with up to 6 decimals",
							"SMALLSERIAL": "Sequential number (from 1 to 32767)",
							"BIGSERIAL": "Sequential number (from 1 to 9223372036854775807)",
							"VARCHAR": "Variable length text string, with limit",
							"CHAR": "Fixed-length text string, filled with spaces",
							"TIME": "Time",
							"INTERVAL": "Time interval",
							"TIMESTAMP_WITH_TIME_ZONE": "Date and time with time zone",
							"TIME_WITH_TIME_ZONE": "Time with time zone",
							"POINT": "Point in a plane or map",
							"POLYGON": "Polygon ((x1, y1), ...)",
							"DOCUMENT": "Document (image, audio, PDF, Word, Excel, ...)",
							"SIGNATURE": "Handwritten signature",
							"BARCODE": "Barcode",
							"VECTOR": "Vector",
						}
					},
					"iconType": {
						"v": {
							"ac_unit": "ac_unit",
							"access_alarm": "access_alarm",
							"access_alarms": "access_alarms",
							"access_time": "access_time",
							"accessibility": "accessibility",
							"accessibility_new": "accessibility_new",
							"accessible": "accessible",
							"accessible_forward": "accessible_forward",
							"account_balance": "account_balance",
							"account_balance_wallet": "account_balance_wallet",
							"account_box": "account_box",
							"account_circle": "account_circle",
							"adb": "adb",
							"add": "add",
							"add_a_photo": "add_a_photo",
							"add_alarm": "add_alarm",
							"add_alert": "add_alert",
							"add_box": "add_box",
							"add_circle": "add_circle",
							"add_circle_outline": "add_circle_outline",
							"add_comment": "add_comment",
							"add_location": "add_location",
							"add_photo_alternate": "add_photo_alternate",
							"add_shopping_cart": "add_shopping_cart",
							"add_to_home_screen": "add_to_home_screen",
							"add_to_photos": "add_to_photos",
							"add_to_queue": "add_to_queue",
							"adjust": "adjust",
							"airline_seat_flat": "airline_seat_flat",
							"airline_seat_flat_angled": "airline_seat_flat_angled",
							"airline_seat_individual_suite": "airline_seat_individual_suite",
							"airline_seat_legroom_extra": "airline_seat_legroom_extra",
							"airline_seat_legroom_normal": "airline_seat_legroom_normal",
							"airline_seat_legroom_reduced": "airline_seat_legroom_reduced",
							"airline_seat_recline_extra": "airline_seat_recline_extra",
							"airline_seat_recline_normal": "airline_seat_recline_normal",
							"airplanemode_active": "airplanemode_active",
							"airplanemode_inactive": "airplanemode_inactive",
							"airplay": "airplay",
							"airport_shuttle": "airport_shuttle",
							"alarm": "alarm",
							"alarm_add": "alarm_add",
							"alarm_off": "alarm_off",
							"alarm_on": "alarm_on",
							"album": "album",
							"all_inbox": "all_inbox",
							"all_inclusive": "all_inclusive",
							"all_out": "all_out",
							"alternate_email": "alternate_email",
							"android": "android",
							"announcement": "announcement",
							"apps": "apps",
							"archive": "archive",
							"arrow_back": "arrow_back",
							"arrow_back_ios": "arrow_back_ios",
							"arrow_downward": "arrow_downward",
							"arrow_drop_down": "arrow_drop_down",
							"arrow_drop_down_circle": "arrow_drop_down_circle",
							"arrow_drop_up": "arrow_drop_up",
							"arrow_forward": "arrow_forward",
							"arrow_forward_ios": "arrow_forward_ios",
							"arrow_left": "arrow_left",
							"arrow_right": "arrow_right",
							"arrow_right_alt": "arrow_right_alt",
							"arrow_upward": "arrow_upward",
							"art_track": "art_track",
							"aspect_ratio": "aspect_ratio",
							"assessment": "assessment",
							"assignment": "assignment",
							"assignment_ind": "assignment_ind",
							"assignment_late": "assignment_late",
							"assignment_return": "assignment_return",
							"assignment_returned": "assignment_returned",
							"assignment_turned_in": "assignment_turned_in",
							"assistant": "assistant",
							"assistant_photo": "assistant_photo",
							"atm": "atm",
							"attach_file": "attach_file",
							"attach_money": "attach_money",
							"attachment": "attachment",
							"audiotrack": "audiotrack",
							"autorenew": "autorenew",
							"av_timer": "av_timer",
							"backspace": "backspace",
							"backup": "backup",
							"ballot": "ballot",
							"bar_chart": "bar_chart",
							"battery_20": "battery_20",
							"battery_30": "battery_30",
							"battery_50": "battery_50",
							"battery_60": "battery_60",
							"battery_80": "battery_80",
							"battery_90": "battery_90",
							"battery_alert": "battery_alert",
							"battery_charging_20": "battery_charging_20",
							"battery_charging_30": "battery_charging_30",
							"battery_charging_50": "battery_charging_50",
							"battery_charging_60": "battery_charging_60",
							"battery_charging_80": "battery_charging_80",
							"battery_charging_90": "battery_charging_90",
							"battery_charging_full": "battery_charging_full",
							"battery_full": "battery_full",
							"battery_std": "battery_std",
							"battery_unknown": "battery_unknown",
							"beach_access": "beach_access",
							"beenhere": "beenhere",
							"block": "block",
							"bluetooth": "bluetooth",
							"bluetooth_audio": "bluetooth_audio",
							"bluetooth_connected": "bluetooth_connected",
							"bluetooth_disabled": "bluetooth_disabled",
							"bluetooth_searching": "bluetooth_searching",
							"blur_circular": "blur_circular",
							"blur_linear": "blur_linear",
							"blur_off": "blur_off",
							"blur_on": "blur_on",
							"book": "book",
							"bookmark": "bookmark",
							"bookmark_border": "bookmark_border",
							"bookmarks": "bookmarks",
							"border_all": "border_all",
							"border_bottom": "border_bottom",
							"border_clear": "border_clear",
							"border_color": "border_color",
							"border_horizontal": "border_horizontal",
							"border_inner": "border_inner",
							"border_left": "border_left",
							"border_outer": "border_outer",
							"border_right": "border_right",
							"border_style": "border_style",
							"border_top": "border_top",
							"border_vertical": "border_vertical",
							"branding_watermark": "branding_watermark",
							"brightness_1": "brightness_1",
							"brightness_2": "brightness_2",
							"brightness_3": "brightness_3",
							"brightness_4": "brightness_4",
							"brightness_5": "brightness_5",
							"brightness_6": "brightness_6",
							"brightness_7": "brightness_7",
							"brightness_auto": "brightness_auto",
							"brightness_high": "brightness_high",
							"brightness_low": "brightness_low",
							"brightness_medium": "brightness_medium",
							"broken_image": "broken_image",
							"brush": "brush",
							"bubble_chart": "bubble_chart",
							"bug_report": "bug_report",
							"build": "build",
							"burst_mode": "burst_mode",
							"business": "business",
							"business_center": "business_center",
							"cached": "cached",
							"cake": "cake",
							"calendar_today": "calendar_today",
							"calendar_view_day": "calendar_view_day",
							"call": "call",
							"call_end": "call_end",
							"call_made": "call_made",
							"call_merge": "call_merge",
							"call_missed": "call_missed",
							"call_missed_outgoing": "call_missed_outgoing",
							"call_received": "call_received",
							"call_split": "call_split",
							"call_to_action": "call_to_action",
							"camera": "camera",
							"camera_alt": "camera_alt",
							"camera_enhance": "camera_enhance",
							"camera_front": "camera_front",
							"camera_rear": "camera_rear",
							"camera_roll": "camera_roll",
							"cancel": "cancel",
							"cancel_presentation": "cancel_presentation",
							"card_giftcard": "card_giftcard",
							"card_membership": "card_membership",
							"card_travel": "card_travel",
							"casino": "casino",
							"cast": "cast",
							"cast_connected": "cast_connected",
							"cast_for_education": "cast_for_education",
							"category": "category",
							"cell_wifi": "cell_wifi",
							"center_focus_strong": "center_focus_strong",
							"center_focus_weak": "center_focus_weak",
							"change_history": "change_history",
							"chat": "chat",
							"chat_bubble": "chat_bubble",
							"chat_bubble_outline": "chat_bubble_outline",
							"check": "check",
							"check_box": "check_box",
							"check_box_outline_blank": "check_box_outline_blank",
							"check_circle": "check_circle",
							"check_circle_outline": "check_circle_outline",
							"chevron_left": "chevron_left",
							"chevron_right": "chevron_right",
							"child_care": "child_care",
							"child_friendly": "child_friendly",
							"chrome_reader_mode": "chrome_reader_mode",
							"class": "class",
							"clear": "clear",
							"clear_all": "clear_all",
							"close": "close",
							"closed_caption": "closed_caption",
							"cloud": "cloud",
							"cloud_circle": "cloud_circle",
							"cloud_done": "cloud_done",
							"cloud_download": "cloud_download",
							"cloud_off": "cloud_off",
							"cloud_queue": "cloud_queue",
							"cloud_upload": "cloud_upload",
							"code": "code",
							"collections": "collections",
							"collections_bookmark": "collections_bookmark",
							"color_lens": "color_lens",
							"colorize": "colorize",
							"comment": "comment",
							"commute": "commute",
							"compare": "compare",
							"compare_arrows": "compare_arrows",
							"compass_calibration": "compass_calibration",
							"computer": "computer",
							"confirmation_number": "confirmation_number",
							"contact_mail": "contact_mail",
							"contact_phone": "contact_phone",
							"contact_support": "contact_support",
							"contacts": "contacts",
							"control_camera": "control_camera",
							"control_point": "control_point",
							"control_point_duplicate": "control_point_duplicate",
							"copyright": "copyright",
							"create": "create",
							"create_new_folder": "create_new_folder",
							"credit_card": "credit_card",
							"crop": "crop",
							"crop_16_9": "crop_16_9",
							"crop_3_2": "crop_3_2",
							"crop_5_4": "crop_5_4",
							"crop_7_5": "crop_7_5",
							"crop_din": "crop_din",
							"crop_free": "crop_free",
							"crop_landscape": "crop_landscape",
							"crop_original": "crop_original",
							"crop_portrait": "crop_portrait",
							"crop_rotate": "crop_rotate",
							"crop_square": "crop_square",
							"dashboard": "dashboard",
							"data_usage": "data_usage",
							"date_range": "date_range",
							"dehaze": "dehaze",
							"delete": "delete",
							"delete_forever": "delete_forever",
							"delete_outline": "delete_outline",
							"delete_sweep": "delete_sweep",
							"departure_board": "departure_board",
							"description": "description",
							"desktop_access_disabled": "desktop_access_disabled",
							"desktop_mac": "desktop_mac",
							"desktop_windows": "desktop_windows",
							"details": "details",
							"developer_board": "developer_board",
							"developer_mode": "developer_mode",
							"device_hub": "device_hub",
							"device_unknown": "device_unknown",
							"devices": "devices",
							"devices_other": "devices_other",
							"dialer_sip": "dialer_sip",
							"dialpad": "dialpad",
							"directions": "directions",
							"directions_bike": "directions_bike",
							"directions_boat": "directions_boat",
							"directions_bus": "directions_bus",
							"directions_car": "directions_car",
							"directions_railway": "directions_railway",
							"directions_run": "directions_run",
							"directions_subway": "directions_subway",
							"directions_transit": "directions_transit",
							"directions_walk": "directions_walk",
							"disc_full": "disc_full",
							"dns": "dns",
							"dock": "dock",
							"domain": "domain",
							"domain_disabled": "domain_disabled",
							"done": "done",
							"done_all": "done_all",
							"done_outline": "done_outline",
							"donut_large": "donut_large",
							"donut_small": "donut_small",
							"drafts": "drafts",
							"drag_handle": "drag_handle",
							"drag_indicator": "drag_indicator",
							"drive_eta": "drive_eta",
							"duo": "duo",
							"dvr": "dvr",
							"edit": "edit",
							"edit_attributes": "edit_attributes",
							"edit_location": "edit_location",
							"eject": "eject",
							"email": "email",
							"enhanced_encryption": "enhanced_encryption",
							"equalizer": "equalizer",
							"error": "error",
							"error_outline": "error_outline",
							"euro_symbol": "euro_symbol",
							"ev_station": "ev_station",
							"event": "event",
							"event_available": "event_available",
							"event_busy": "event_busy",
							"event_note": "event_note",
							"event_seat": "event_seat",
							"exit_to_app": "exit_to_app",
							"expand_less": "expand_less",
							"expand_more": "expand_more",
							"explicit": "explicit",
							"explore": "explore",
							"explore_off": "explore_off",
							"exposure": "exposure",
							"exposure_neg_1": "exposure_neg_1",
							"exposure_neg_2": "exposure_neg_2",
							"exposure_plus_1": "exposure_plus_1",
							"exposure_plus_2": "exposure_plus_2",
							"exposure_zero": "exposure_zero",
							"extension": "extension",
							"face": "face",
							"fast_forward": "fast_forward",
							"fast_rewind": "fast_rewind",
							"fastfood": "fastfood",
							"favorite": "favorite",
							"favorite_border": "favorite_border",
							"featured_play_list": "featured_play_list",
							"featured_video": "featured_video",
							"feedback": "feedback",
							"fiber_dvr": "fiber_dvr",
							"fiber_manual_record": "fiber_manual_record",
							"fiber_new": "fiber_new",
							"fiber_pin": "fiber_pin",
							"fiber_smart_record": "fiber_smart_record",
							"file_copy": "file_copy",
							"filter": "filter",
							"filter_1": "filter_1",
							"filter_2": "filter_2",
							"filter_3": "filter_3",
							"filter_4": "filter_4",
							"filter_5": "filter_5",
							"filter_6": "filter_6",
							"filter_7": "filter_7",
							"filter_8": "filter_8",
							"filter_9": "filter_9",
							"filter_9_plus": "filter_9_plus",
							"filter_b_and_w": "filter_b_and_w",
							"filter_center_focus": "filter_center_focus",
							"filter_drama": "filter_drama",
							"filter_frames": "filter_frames",
							"filter_hdr": "filter_hdr",
							"filter_list": "filter_list",
							"filter_none": "filter_none",
							"filter_tilt_shift": "filter_tilt_shift",
							"filter_vintage": "filter_vintage",
							"find_in_page": "find_in_page",
							"find_replace": "find_replace",
							"fingerprint": "fingerprint",
							"first_page": "first_page",
							"fitness_center": "fitness_center",
							"flag": "flag",
							"flare": "flare",
							"flash_auto": "flash_auto",
							"flash_off": "flash_off",
							"flash_on": "flash_on",
							"flight": "flight",
							"flight_land": "flight_land",
							"flight_takeoff": "flight_takeoff",
							"flip": "flip",
							"flip_to_back": "flip_to_back",
							"flip_to_front": "flip_to_front",
							"folder": "folder",
							"folder_open": "folder_open",
							"folder_shared": "folder_shared",
							"folder_special": "folder_special",
							"font_download": "font_download",
							"format_align_center": "format_align_center",
							"format_align_justify": "format_align_justify",
							"format_align_left": "format_align_left",
							"format_align_right": "format_align_right",
							"format_bold": "format_bold",
							"format_clear": "format_clear",
							"format_color_fill": "format_color_fill",
							"format_color_reset": "format_color_reset",
							"format_color_text": "format_color_text",
							"format_indent_decrease": "format_indent_decrease",
							"format_indent_increase": "format_indent_increase",
							"format_italic": "format_italic",
							"format_line_spacing": "format_line_spacing",
							"format_list_bulleted": "format_list_bulleted",
							"format_list_numbered": "format_list_numbered",
							"format_list_numbered_rtl": "format_list_numbered_rtl",
							"format_paint": "format_paint",
							"format_quote": "format_quote",
							"format_shapes": "format_shapes",
							"format_size": "format_size",
							"format_strikethrough": "format_strikethrough",
							"format_textdirection_l_to_r": "format_textdirection_l_to_r",
							"format_textdirection_r_to_l": "format_textdirection_r_to_l",
							"format_underlined": "format_underlined",
							"forum": "forum",
							"forward": "forward",
							"forward_10": "forward_10",
							"forward_30": "forward_30",
							"forward_5": "forward_5",
							"free_breakfast": "free_breakfast",
							"fullscreen": "fullscreen",
							"fullscreen_exit": "fullscreen_exit",
							"functions": "functions",
							"g_translate": "g_translate",
							"gamepad": "gamepad",
							"games": "games",
							"gavel": "gavel",
							"gesture": "gesture",
							"get_app": "get_app",
							"gif": "gif",
							"golf_course": "golf_course",
							"gps_fixed": "gps_fixed",
							"gps_not_fixed": "gps_not_fixed",
							"gps_off": "gps_off",
							"grade": "grade",
							"gradient": "gradient",
							"grain": "grain",
							"graphic_eq": "graphic_eq",
							"grid_off": "grid_off",
							"grid_on": "grid_on",
							"group": "group",
							"group_add": "group_add",
							"group_work": "group_work",
							"hd": "hd",
							"hdr_off": "hdr_off",
							"hdr_on": "hdr_on",
							"hdr_strong": "hdr_strong",
							"hdr_weak": "hdr_weak",
							"headset": "headset",
							"headset_mic": "headset_mic",
							"healing": "healing",
							"hearing": "hearing",
							"help": "help",
							"help_outline": "help_outline",
							"high_quality": "high_quality",
							"highlight": "highlight",
							"highlight_off": "highlight_off",
							"history": "history",
							"home": "home",
							"horizontal_split": "horizontal_split",
							"hot_tub": "hot_tub",
							"hotel": "hotel",
							"hourglass_empty": "hourglass_empty",
							"hourglass_full": "hourglass_full",
							"how_to_reg": "how_to_reg",
							"how_to_vote": "how_to_vote",
							"http": "http",
							"https": "https",
							"image": "image",
							"image_aspect_ratio": "image_aspect_ratio",
							"image_search": "image_search",
							"import_contacts": "import_contacts",
							"import_export": "import_export",
							"important_devices": "important_devices",
							"inbox": "inbox",
							"indeterminate_check_box": "indeterminate_check_box",
							"info": "info",
							"input": "input",
							"insert_chart": "insert_chart",
							"insert_chart_outlined": "insert_chart_outlined",
							"insert_comment": "insert_comment",
							"insert_drive_file": "insert_drive_file",
							"insert_emoticon": "insert_emoticon",
							"insert_invitation": "insert_invitation",
							"insert_link": "insert_link",
							"insert_photo": "insert_photo",
							"invert_colors": "invert_colors",
							"invert_colors_off": "invert_colors_off",
							"iso": "iso",
							"keyboard": "keyboard",
							"keyboard_arrow_down": "keyboard_arrow_down",
							"keyboard_arrow_left": "keyboard_arrow_left",
							"keyboard_arrow_right": "keyboard_arrow_right",
							"keyboard_arrow_up": "keyboard_arrow_up",
							"keyboard_backspace": "keyboard_backspace",
							"keyboard_capslock": "keyboard_capslock",
							"keyboard_hide": "keyboard_hide",
							"keyboard_return": "keyboard_return",
							"keyboard_tab": "keyboard_tab",
							"keyboard_voice": "keyboard_voice",
							"kitchen": "kitchen",
							"label": "label",
							"label_important": "label_important",
							"label_off": "label_off",
							"landscape": "landscape",
							"language": "language",
							"laptop": "laptop",
							"laptop_chromebook": "laptop_chromebook",
							"laptop_mac": "laptop_mac",
							"laptop_windows": "laptop_windows",
							"last_page": "last_page",
							"launch": "launch",
							"layers": "layers",
							"layers_clear": "layers_clear",
							"leak_add": "leak_add",
							"leak_remove": "leak_remove",
							"lens": "lens",
							"library_add": "library_add",
							"library_books": "library_books",
							"library_music": "library_music",
							"line_style": "line_style",
							"line_weight": "line_weight",
							"linear_scale": "linear_scale",
							"link": "link",
							"link_off": "link_off",
							"linked_camera": "linked_camera",
							"list": "list",
							"list_alt": "list_alt",
							"live_help": "live_help",
							"live_tv": "live_tv",
							"local_activity": "local_activity",
							"local_airport": "local_airport",
							"local_atm": "local_atm",
							"local_bar": "local_bar",
							"local_cafe": "local_cafe",
							"local_car_wash": "local_car_wash",
							"local_convenience_store": "local_convenience_store",
							"local_dining": "local_dining",
							"local_drink": "local_drink",
							"local_florist": "local_florist",
							"local_gas_station": "local_gas_station",
							"local_grocery_store": "local_grocery_store",
							"local_hospital": "local_hospital",
							"local_hotel": "local_hotel",
							"local_laundry_service": "local_laundry_service",
							"local_library": "local_library",
							"local_mall": "local_mall",
							"local_movies": "local_movies",
							"local_offer": "local_offer",
							"local_parking": "local_parking",
							"local_pharmacy": "local_pharmacy",
							"local_phone": "local_phone",
							"local_pizza": "local_pizza",
							"local_play": "local_play",
							"local_post_office": "local_post_office",
							"local_printshop": "local_printshop",
							"local_see": "local_see",
							"local_shipping": "local_shipping",
							"local_taxi": "local_taxi",
							"location_city": "location_city",
							"location_disabled": "location_disabled",
							"location_off": "location_off",
							"location_on": "location_on",
							"location_searching": "location_searching",
							"lock": "lock",
							"lock_open": "lock_open",
							"looks": "looks",
							"looks_3": "looks_3",
							"looks_4": "looks_4",
							"looks_5": "looks_5",
							"looks_6": "looks_6",
							"looks_one": "looks_one",
							"looks_two": "looks_two",
							"loop": "loop",
							"loupe": "loupe",
							"low_priority": "low_priority",
							"loyalty": "loyalty",
							"mail": "mail",
							"mail_outline": "mail_outline",
							"map": "map",
							"markunread": "markunread",
							"markunread_mailbox": "markunread_mailbox",
							"maximize": "maximize",
							"meeting_room": "meeting_room",
							"memory": "memory",
							"menu": "menu",
							"merge_type": "merge_type",
							"message": "message",
							"mic": "mic",
							"mic_none": "mic_none",
							"mic_off": "mic_off",
							"minimize": "minimize",
							"missed_video_call": "missed_video_call",
							"mms": "mms",
							"mobile_friendly": "mobile_friendly",
							"mobile_off": "mobile_off",
							"mobile_screen_share": "mobile_screen_share",
							"mode_comment": "mode_comment",
							"monetization_on": "monetization_on",
							"money": "money",
							"money_off": "money_off",
							"monochrome_photos": "monochrome_photos",
							"mood": "mood",
							"mood_bad": "mood_bad",
							"more": "more",
							"more_horiz": "more_horiz",
							"more_vert": "more_vert",
							"motorcycle": "motorcycle",
							"mouse": "mouse",
							"move_to_inbox": "move_to_inbox",
							"movie": "movie",
							"movie_creation": "movie_creation",
							"movie_filter": "movie_filter",
							"multiline_chart": "multiline_chart",
							"music_note": "music_note",
							"music_off": "music_off",
							"music_video": "music_video",
							"my_location": "my_location",
							"nature": "nature",
							"nature_people": "nature_people",
							"navigate_before": "navigate_before",
							"navigate_next": "navigate_next",
							"navigation": "navigation",
							"near_me": "near_me",
							"network_cell": "network_cell",
							"network_check": "network_check",
							"network_locked": "network_locked",
							"network_wifi": "network_wifi",
							"new_releases": "new_releases",
							"next_week": "next_week",
							"nfc": "nfc",
							"no_encryption": "no_encryption",
							"no_meeting_room": "no_meeting_room",
							"no_sim": "no_sim",
							"not_interested": "not_interested",
							"not_listed_location": "not_listed_location",
							"note": "note",
							"note_add": "note_add",
							"notes": "notes",
							"notification_important": "notification_important",
							"notifications": "notifications",
							"notifications_active": "notifications_active",
							"notifications_none": "notifications_none",
							"notifications_off": "notifications_off",
							"notifications_paused": "notifications_paused",
							"offline_bolt": "offline_bolt",
							"offline_pin": "offline_pin",
							"ondemand_video": "ondemand_video",
							"opacity": "opacity",
							"open_in_browser": "open_in_browser",
							"open_in_new": "open_in_new",
							"open_with": "open_with",
							"outlined_flag": "outlined_flag",
							"pages": "pages",
							"pageview": "pageview",
							"palette": "palette",
							"pan_tool": "pan_tool",
							"panorama": "panorama",
							"panorama_fish_eye": "panorama_fish_eye",
							"panorama_horizontal": "panorama_horizontal",
							"panorama_vertical": "panorama_vertical",
							"panorama_wide_angle": "panorama_wide_angle",
							"party_mode": "party_mode",
							"pause": "pause",
							"pause_circle_filled": "pause_circle_filled",
							"pause_circle_outline": "pause_circle_outline",
							"pause_presentation": "pause_presentation",
							"payment": "payment",
							"people": "people",
							"people_outline": "people_outline",
							"perm_camera_mic": "perm_camera_mic",
							"perm_contact_calendar": "perm_contact_calendar",
							"perm_data_setting": "perm_data_setting",
							"perm_device_information": "perm_device_information",
							"perm_identity": "perm_identity",
							"perm_media": "perm_media",
							"perm_phone_msg": "perm_phone_msg",
							"perm_scan_wifi": "perm_scan_wifi",
							"person": "person",
							"person_add": "person_add",
							"person_add_disabled": "person_add_disabled",
							"person_outline": "person_outline",
							"person_pin": "person_pin",
							"person_pin_circle": "person_pin_circle",
							"personal_video": "personal_video",
							"pets": "pets",
							"phone": "phone",
							"phone_android": "phone_android",
							"phone_bluetooth_speaker": "phone_bluetooth_speaker",
							"phone_callback": "phone_callback",
							"phone_forwarded": "phone_forwarded",
							"phone_in_talk": "phone_in_talk",
							"phone_iphone": "phone_iphone",
							"phone_locked": "phone_locked",
							"phone_missed": "phone_missed",
							"phone_paused": "phone_paused",
							"phonelink": "phonelink",
							"phonelink_erase": "phonelink_erase",
							"phonelink_lock": "phonelink_lock",
							"phonelink_off": "phonelink_off",
							"phonelink_ring": "phonelink_ring",
							"phonelink_setup": "phonelink_setup",
							"photo": "photo",
							"photo_album": "photo_album",
							"photo_camera": "photo_camera",
							"photo_filter": "photo_filter",
							"photo_library": "photo_library",
							"photo_size_select_actual": "photo_size_select_actual",
							"photo_size_select_large": "photo_size_select_large",
							"photo_size_select_small": "photo_size_select_small",
							"picture_as_pdf": "picture_as_pdf",
							"picture_in_picture": "picture_in_picture",
							"picture_in_picture_alt": "picture_in_picture_alt",
							"pie_chart": "pie_chart",
							"pin_drop": "pin_drop",
							"place": "place",
							"play_arrow": "play_arrow",
							"play_circle_filled": "play_circle_filled",
							"play_circle_filled_white": "play_circle_filled_white",
							"play_circle_outline": "play_circle_outline",
							"play_for_work": "play_for_work",
							"playlist_add": "playlist_add",
							"playlist_add_check": "playlist_add_check",
							"playlist_play": "playlist_play",
							"plus_one": "plus_one",
							"poll": "poll",
							"polymer": "polymer",
							"pool": "pool",
							"portable_wifi_off": "portable_wifi_off",
							"portrait": "portrait",
							"power": "power",
							"power_input": "power_input",
							"power_off": "power_off",
							"power_settings_new": "power_settings_new",
							"pregnant_woman": "pregnant_woman",
							"present_to_all": "present_to_all",
							"print": "print",
							"print_disabled": "print_disabled",
							"priority_high": "priority_high",
							"public": "public",
							"publish": "publish",
							"query_builder": "query_builder",
							"question_answer": "question_answer",
							"queue": "queue",
							"queue_music": "queue_music",
							"queue_play_next": "queue_play_next",
							"radio": "radio",
							"radio_button_checked": "radio_button_checked",
							"radio_button_unchecked": "radio_button_unchecked",
							"rate_review": "rate_review",
							"receipt": "receipt",
							"recent_actors": "recent_actors",
							"record_voice_over": "record_voice_over",
							"redeem": "redeem",
							"redo": "redo",
							"refresh": "refresh",
							"remove": "remove",
							"remove_circle": "remove_circle",
							"remove_circle_outline": "remove_circle_outline",
							"remove_from_queue": "remove_from_queue",
							"remove_red_eye": "remove_red_eye",
							"remove_shopping_cart": "remove_shopping_cart",
							"reorder": "reorder",
							"repeat": "repeat",
							"repeat_one": "repeat_one",
							"replay": "replay",
							"replay_10": "replay_10",
							"replay_30": "replay_30",
							"replay_5": "replay_5",
							"reply": "reply",
							"reply_all": "reply_all",
							"report": "report",
							"report_off": "report_off",
							"report_problem": "report_problem",
							"restaurant": "restaurant",
							"restaurant_menu": "restaurant_menu",
							"restore": "restore",
							"restore_from_trash": "restore_from_trash",
							"restore_page": "restore_page",
							"ring_volume": "ring_volume",
							"room": "room",
							"room_service": "room_service",
							"rotate_90_degrees_ccw": "rotate_90_degrees_ccw",
							"rotate_left": "rotate_left",
							"rotate_right": "rotate_right",
							"rounded_corner": "rounded_corner",
							"router": "router",
							"rowing": "rowing",
							"rss_feed": "rss_feed",
							"rv_hookup": "rv_hookup",
							"satellite": "satellite",
							"save": "save",
							"save_alt": "save_alt",
							"scanner": "scanner",
							"scatter_plot": "scatter_plot",
							"schedule": "schedule",
							"school": "school",
							"score": "score",
							"screen_lock_landscape": "screen_lock_landscape",
							"screen_lock_portrait": "screen_lock_portrait",
							"screen_lock_rotation": "screen_lock_rotation",
							"screen_rotation": "screen_rotation",
							"screen_share": "screen_share",
							"sd_card": "sd_card",
							"sd_storage": "sd_storage",
							"search": "search",
							"security": "security",
							"select_all": "select_all",
							"send": "send",
							"sentiment_dissatisfied": "sentiment_dissatisfied",
							"sentiment_satisfied": "sentiment_satisfied",
							"sentiment_satisfied_alt": "sentiment_satisfied_alt",
							"sentiment_very_dissatisfied": "sentiment_very_dissatisfied",
							"sentiment_very_satisfied": "sentiment_very_satisfied",
							"settings": "settings",
							"settings_applications": "settings_applications",
							"settings_backup_restore": "settings_backup_restore",
							"settings_bluetooth": "settings_bluetooth",
							"settings_brightness": "settings_brightness",
							"settings_cell": "settings_cell",
							"settings_ethernet": "settings_ethernet",
							"settings_input_antenna": "settings_input_antenna",
							"settings_input_component": "settings_input_component",
							"settings_input_composite": "settings_input_composite",
							"settings_input_hdmi": "settings_input_hdmi",
							"settings_input_svideo": "settings_input_svideo",
							"settings_overscan": "settings_overscan",
							"settings_phone": "settings_phone",
							"settings_power": "settings_power",
							"settings_remote": "settings_remote",
							"settings_system_daydream": "settings_system_daydream",
							"settings_voice": "settings_voice",
							"share": "share",
							"shop": "shop",
							"shop_two": "shop_two",
							"shopping_basket": "shopping_basket",
							"shopping_cart": "shopping_cart",
							"short_text": "short_text",
							"show_chart": "show_chart",
							"shuffle": "shuffle",
							"shutter_speed": "shutter_speed",
							"signal_cellular_0_bar": "signal_cellular_0_bar",
							"signal_cellular_1_bar": "signal_cellular_1_bar",
							"signal_cellular_2_bar": "signal_cellular_2_bar",
							"signal_cellular_3_bar": "signal_cellular_3_bar",
							"signal_cellular_4_bar": "signal_cellular_4_bar",
							"signal_cellular_alt": "signal_cellular_alt",
							"signal_cellular_connected_no_internet_0_bar": "signal_cellular_connected_no_internet_0_bar",
							"signal_cellular_connected_no_internet_1_bar": "signal_cellular_connected_no_internet_1_bar",
							"signal_cellular_connected_no_internet_2_bar": "signal_cellular_connected_no_internet_2_bar",
							"signal_cellular_connected_no_internet_3_bar": "signal_cellular_connected_no_internet_3_bar",
							"signal_cellular_connected_no_internet_4_bar": "signal_cellular_connected_no_internet_4_bar",
							"signal_cellular_no_sim": "signal_cellular_no_sim",
							"signal_cellular_null": "signal_cellular_null",
							"signal_cellular_off": "signal_cellular_off",
							"signal_wifi_0_bar": "signal_wifi_0_bar",
							"signal_wifi_1_bar": "signal_wifi_1_bar",
							"signal_wifi_1_bar_lock": "signal_wifi_1_bar_lock",
							"signal_wifi_2_bar": "signal_wifi_2_bar",
							"signal_wifi_2_bar_lock": "signal_wifi_2_bar_lock",
							"signal_wifi_3_bar": "signal_wifi_3_bar",
							"signal_wifi_3_bar_lock": "signal_wifi_3_bar_lock",
							"signal_wifi_4_bar": "signal_wifi_4_bar",
							"signal_wifi_4_bar_lock": "signal_wifi_4_bar_lock",
							"signal_wifi_off": "signal_wifi_off",
							"sim_card": "sim_card",
							"skip_next": "skip_next",
							"skip_previous": "skip_previous",
							"slideshow": "slideshow",
							"slow_motion_video": "slow_motion_video",
							"smartphone": "smartphone",
							"smoke_free": "smoke_free",
							"smoking_rooms": "smoking_rooms",
							"sms": "sms",
							"sms_failed": "sms_failed",
							"snooze": "snooze",
							"sort": "sort",
							"sort_by_alpha": "sort_by_alpha",
							"spa": "spa",
							"space_bar": "space_bar",
							"speaker": "speaker",
							"speaker_group": "speaker_group",
							"speaker_notes": "speaker_notes",
							"speaker_notes_off": "speaker_notes_off",
							"speaker_phone": "speaker_phone",
							"spellcheck": "spellcheck",
							"star": "star",
							"star_border": "star_border",
							"star_half": "star_half",
							"star_rate": "star_rate",
							"stars": "stars",
							"stay_current_landscape": "stay_current_landscape",
							"stay_current_portrait": "stay_current_portrait",
							"stay_primary_landscape": "stay_primary_landscape",
							"stay_primary_portrait": "stay_primary_portrait",
							"stop": "stop",
							"stop_screen_share": "stop_screen_share",
							"storage": "storage",
							"store": "store",
							"store_mall_directory": "store_mall_directory",
							"straighten": "straighten",
							"streetview": "streetview",
							"strikethrough_s": "strikethrough_s",
							"style": "style",
							"subdirectory_arrow_left": "subdirectory_arrow_left",
							"subdirectory_arrow_right": "subdirectory_arrow_right",
							"subject": "subject",
							"subscriptions": "subscriptions",
							"subtitles": "subtitles",
							"subway": "subway",
							"supervised_user_circle": "supervised_user_circle",
							"supervisor_account": "supervisor_account",
							"surround_sound": "surround_sound",
							"swap_calls": "swap_calls",
							"swap_horiz": "swap_horiz",
							"swap_horizontal_circle": "swap_horizontal_circle",
							"swap_vert": "swap_vert",
							"swap_vertical_circle": "swap_vertical_circle",
							"switch_camera": "switch_camera",
							"switch_video": "switch_video",
							"sync": "sync",
							"sync_disabled": "sync_disabled",
							"sync_problem": "sync_problem",
							"system_update": "system_update",
							"tab": "tab",
							"tab_unselected": "tab_unselected",
							"table_chart": "table_chart",
							"tablet": "tablet",
							"tablet_android": "tablet_android",
							"tablet_mac": "tablet_mac",
							"tag_faces": "tag_faces",
							"tap_and_play": "tap_and_play",
							"terrain": "terrain",
							"text_fields": "text_fields",
							"text_format": "text_format",
							"text_rotate_up": "text_rotate_up",
							"text_rotate_vertical": "text_rotate_vertical",
							"text_rotation_down": "text_rotation_down",
							"text_rotation_none": "text_rotation_none",
							"textsms": "textsms",
							"texture": "texture",
							"theaters": "theaters",
							"thumb_down": "thumb_down",
							"thumb_down_alt": "thumb_down_alt",
							"thumb_up": "thumb_up",
							"thumb_up_alt": "thumb_up_alt",
							"thumbs_up_down": "thumbs_up_down",
							"time_to_leave": "time_to_leave",
							"timelapse": "timelapse",
							"timeline": "timeline",
							"timer": "timer",
							"timer_10": "timer_10",
							"timer_3": "timer_3",
							"timer_off": "timer_off",
							"title": "title",
							"toc": "toc",
							"today": "today",
							"toggle_off": "toggle_off",
							"toggle_on": "toggle_on",
							"toll": "toll",
							"tonality": "tonality",
							"touch_app": "touch_app",
							"toys": "toys",
							"track_changes": "track_changes",
							"traffic": "traffic",
							"train": "train",
							"tram": "tram",
							"transfer_within_a_station": "transfer_within_a_station",
							"transform": "transform",
							"transit_enterexit": "transit_enterexit",
							"translate": "translate",
							"trending_down": "trending_down",
							"trending_flat": "trending_flat",
							"trending_up": "trending_up",
							"trip_origin": "trip_origin",
							"tune": "tune",
							"turned_in": "turned_in",
							"turned_in_not": "turned_in_not",
							"tv": "tv",
							"tv_off": "tv_off",
							"unarchive": "unarchive",
							"undo": "undo",
							"unfold_less": "unfold_less",
							"unfold_more": "unfold_more",
							"unsubscribe": "unsubscribe",
							"update": "update",
							"usb": "usb",
							"verified_user": "verified_user",
							"vertical_align_bottom": "vertical_align_bottom",
							"vertical_align_center": "vertical_align_center",
							"vertical_align_top": "vertical_align_top",
							"vertical_split": "vertical_split",
							"vibration": "vibration",
							"video_call": "video_call",
							"video_label": "video_label",
							"video_library": "video_library",
							"videocam": "videocam",
							"videocam_off": "videocam_off",
							"videogame_asset": "videogame_asset",
							"view_agenda": "view_agenda",
							"view_array": "view_array",
							"view_carousel": "view_carousel",
							"view_column": "view_column",
							"view_comfy": "view_comfy",
							"view_compact": "view_compact",
							"view_day": "view_day",
							"view_headline": "view_headline",
							"view_list": "view_list",
							"view_module": "view_module",
							"view_quilt": "view_quilt",
							"view_stream": "view_stream",
							"view_week": "view_week",
							"vignette": "vignette",
							"visibility": "visibility",
							"visibility_off": "visibility_off",
							"voice_chat": "voice_chat",
							"voice_over_off": "voice_over_off",
							"voicemail": "voicemail",
							"volume_down": "volume_down",
							"volume_mute": "volume_mute",
							"volume_off": "volume_off",
							"volume_up": "volume_up",
							"vpn_key": "vpn_key",
							"vpn_lock": "vpn_lock",
							"wallpaper": "wallpaper",
							"warning": "warning",
							"watch": "watch",
							"watch_later": "watch_later",
							"waves": "waves",
							"wb_auto": "wb_auto",
							"wb_cloudy": "wb_cloudy",
							"wb_incandescent": "wb_incandescent",
							"wb_iridescent": "wb_iridescent",
							"wb_sunny": "wb_sunny",
							"wc": "wc",
							"web": "web",
							"web_asset": "web_asset",
							"weekend": "weekend",
							"whatshot": "whatshot",
							"where_to_vote": "where_to_vote",
							"widgets": "widgets",
							"wifi": "wifi",
							"wifi_lock": "wifi_lock",
							"wifi_off": "wifi_off",
							"wifi_tethering": "wifi_tethering",
							"work": "work",
							"work_off": "work_off",
							"work_outline": "work_outline",
							"wrap_text": "wrap_text",
							"youtube_searched_for": "youtube_searched_for",
							"zoom_in": "zoom_in",
							"zoom_out": "zoom_out",
							"zoom_out_map": "zoom_out_map",
						}
					},
					"momentType": {
						"v": {
							"BEFORE": "BEFORE",
							"AFTER": "AFTER",
							"INSTEAD_OF": "INSTEAD OF",
						}
					},
					"eventType": {
						"v": {
							"INSERT": "INSERT",
							"UPDATE": "UPDATE",
							"DELETE": "DELETE",
						}
					},
					"eachType": {
						"v": {
							"ROW": "ROW",
							"STATEMENT": "STATEMENT",
						}
					},
					"languageType": {
						"v": {
							"SQL": "SQL",
							"C": "C",
							"plpgsql": "PL/pgSQL on Database",
							"pltcl": "PL/Tcl",
							"plperl": "PL/Perl",
							"plpythonu": "PL/Python (Default version)",
							"plpython2u": "PL/Python 2",
							"plpython3u": "PL/Python 3",
							"ECMAScriptNashorn": "JavaScript on JVM (Nashorn)",
						}
					},
					"mrzFieldType": {
						"v": {
							"administrativeCode": "Administrative code",
							"administrativeCode2": "Administrative code 2",
							"birthDate": "Birth date",
							"documentCode": "Document code",
							"documentNumber": "Document number",
							"expirationDate": "Expiration date",
							"firstName": "First name",
							"issueDate": "Issue date",
							"issuingState": "Issuing state",
							"languageCode": "Language code",
							"lastName": "Last name",
							"nationality": "Nationality",
							"optional": "Optional",
							"optional1": "Optional 1",
							"optional2": "Optional 2",
							"personalNumber": "Personal number",
							"pinCode": "PIN code",
							"sex": "Sex",
							"versionNumber": "Version number",
						}
					},
					"webPackageType": {
						"v": {
							"STATIC": "Static website",
							"NODE": "Node.js package (Next.js, ...)",
						}
					},
				},
			},
			
			// e = Entities
			"e": {
				// Schemas
				"Models": {
					"CustomType": {
						"name": "Custom compound type",
						"pluralName": "Composite types",
						"a": {
							"schema": "Name of the application",
							"name": "Name of the compound type",
							"documentation": "Documentation",
							"search": "Search criteria",
							"id": "Identifier",
							"timestamp": "Last modification",
						},
					},
					"CustomTypeAttribute": {
						"name": "Field",
						"pluralName": "Fields",
						"a": {
							"container": "Custom compound type",
							"CustomTypeViaContainer": "Custom compound type",
							"name": "Field name",
							"type": "Content type",
							"isArray": "Supports multiple values",
							"customType": "Custom compound type",
							"CustomTypeViaCustomType": "Custom compound type",
							"enumType": "List of values",
							"EnumTypeViaEnumType": "List of values",
							"length": "Maximum length",
							"precision": "Accuracy (total number of digits)",
							"scale": "Scale (number of decimal digits)",
							"documentation": "Documentation",
							"search": "Search criteria",
							"id": "Identifier",
							"timestamp": "Last modification",
						},
					},
					"CustomTypeAttributeLabel": {
						"name": "Translation",
						"pluralName": "Translations",
						"a": {
							"container": "Field",
							"CustomTypeAttributeViaContainer": "Field",
							"locale": "Language",
							"label": "Name of the field",
							"id": "Identifier",
							"timestamp": "Last modification",
						},
					},
					"CustomTypeLabel": {
						"name": "Translation",
						"pluralName": "Translations",
						"a": {
							"container": "Custom compound type",
							"CustomTypeViaContainer": "Custom compound type",
							"locale": "Language",
							"label": "Name of the compound type",
							"id": "Identifier",
							"timestamp": "Last modification",
						},
					},
					"Variable": {
						"name": "Variable",
						"pluralName": "Variables",
						"a": {
							"name": "Name of the variable",
							"value": "Value of the variable",
							"documentation": "Documentation",
							"search": "Search criteria",
							"id": "Identifier",
						},
					},
					"Application": {
						"name": "Application",
						"pluralName": "Applications",
						"a": {
							"name": "Name of the application",
							"order": "Order number",
							"color": "Theme color",
							"documentation": "Documentation",
							"search": "Search criteria",
							"id": "Identifier",
						},
					},
					"ApplicationLabel": {
						"name": "Translation",
						"pluralName": "Translations",
						"a": {
							"container": "Application",
							"ApplicationViaContainer": "Application",
							"locale": "Language",
							"label": "Name of the application",
							"id": "Identifier",
						},
					},
					"Entity": {
						"name": "Form",
						"pluralName": "Forms",
						"tabs": {
							"1": "Main parameters",
							"2": "Fields",
							"3": "Indices and references",
							"4": "Actions",
							"5": "Intelligence",
							"6": "User interface",
							"7": "Questions",
						},
						"a": {
							"schema": "Name of the application",
							"name": "Name of the form",
							"language": "Contents language (used for text searches)",
							"order": "Order number",
							"menu": "Appears in the menu",
							"icon": "Menu icon",
							"isExportToCSVEnabled": "Export to Excel enabled",
							"documentation": "Documentation",
							"defaultOrderAttribute": "Attribute used for default ordering",
							"EntityAttributeViaDefaultOrderAttribute": "Attribute used for default ordering",
							"defaultOrderDesc": "Descending sort direction",
							"defaultOrderNullsLast": "Nulls go last",
							"search": "Search criteria",
							"id": "Identifier",
							"timestamp": "Last modification",
						},
					},
					"EntityAttribute": {
						"name": "Field",
						"pluralName": "Fields",
						"tabs": {
							"1": "Main parameters",
							"2": "User interface",
							"3": "Type-specific parameters",
							"4": "External selector",
						},
						"groups": {
							"1": "Text string",
							"3": "Number",
							"5": "Date & time",
							"9": "Point in plane",
							"10": "Document",
							"12": "Barcode",
							"13": "MRZ (Machine Readable Zone)",
						},
						"a": {
							"container": "Form",
							"EntityViaContainer": "Form",
							"name": "Field name",
							"type": "Content type",
							"isArray": "Supports multiple values",
							"allowDuplicates": "Allow duplicate values",
							"defaultValue": "Default value",
							"isRequired": "It is mandatory",
							"customType": "Custom compound type",
							"CustomTypeViaCustomType": "Custom compound type",
							"enumType": "List of values",
							"EnumTypeViaEnumType": "List of values",
							"length": "Maximum length",
							"precision": "Accuracy (total number of digits)",
							"scale": "Scale (number of decimal digits)",
							"isLabel": "It is used as a label to represent the item",
							"labelLanguage": "Used as label for a specific language",
							"isList": "Appears in the listings",
							"isVisible": "Appears in the forms",
							"isBasicFilter": "Used as a basic search filter",
							"isTextFilter": "Used as text search filter",
							"isTextSearch": "It accumulates contents from text search filters and enables search functionality (search box)",
							"isLastInRow": "It is the last field in the row",
							"isHidden": "The field is hidden, but the value is sent",
							"isEventStart": "The field is the beginning of an event in the calendar",
							"isEventEnd": "The field is the end of an event in the calendar",
							"isImageInGallery": "The field is an image in the gallery view",
							"indexDocuments": "NOT-USED",
							"browseZip": "Enable browsing on Zip files",
							"isPointInMap": "The field is a point on the map",
							"isEncrypted": "The field is stored encrypted",
							"isComputed": "The field is calculated (not modifiable)",
							"isIcon": "The field is an icon",
							"isColor": "The field is a color",
							"isHighlighter": "The color is used as highlighter",
							"order": "Default order number",
							"orderInList": "Order in lists (it overrides the default order)",
							"tab": "Tab in which the field is displayed",
							"group": "Group of fields in which the field is displayed",
							"variants": "Variants in which the field is visible",
							"isVariantSelector": "The field is used to select the variants",
							"xs": "Number of columns on small screens (in a grid of 12)",
							"sm": "Number of columns on medium or large screens",
							"min": "Minimum value",
							"max": "Maximum value",
							"step": "Resolution",
							"prefix": "Prefix",
							"suffix": "Suffix",
							"disableThousandsSeparator": "Disable thousands separator",
							"pattern": "Validation regular expression",
							"isPassword": "It's a password field",
							"isMultiline": "It's multi-line",
							"isRich": "It's rich-text",
							"isAction": "It's link or function",
							"isActionVisibleInForm": "The action button is visible in the form also",
							"isActionVisibleInToolbar": "The action button is visible in the toolbar also",
							"actionIcon": "Action button icon",
							"maxFiles": "Maximum number of files",
							"acceptedFileTypes": "Types of accepted files",
							"barcodeType": "Type of barcode",
							"mrzField": "MRZ (Machine Readable Zone) field",
							"documentation": "Documentation",
							"search": "Search criteria",
							"id": "Identifier",
							"timestamp": "Last modification",
							"externalSelectorUrl": "External selector URL",
							"externalSelectorTarget": "Target (Name of the browsing context or special one (i.e. _self, _blank, _parent, _top)",
							"externalSelectorWindowFeatures": "Comma-separated list of window features (i.e. popup,width=400,height=300)",
							"addAccessToken": "Add access token to the external selector URL",
						},
					},
					"EntityAttributeLabel": {
						"name": "Translation",
						"pluralName": "Translations",
						"a": {
							"container": "Field",
							"EntityAttributeViaContainer": "Field",
							"locale": "Language",
							"label": "Name of the field",
							"id": "Identifier",
							"timestamp": "Last modification",
						},
					},
					"Question": {
						"name": "Question",
						"pluralName": "Questions",
						"a": {
							"container": "Form",
							"EntityViaContainer": "Form",
							"name": "Question name",
							"questionId": "Metabase question id",
							"isList": "Appears in the listings",
							"isVisible": "Appears in the forms",
							"useIds": "Pass selected item ids as parameters to que question",
							"order": "Order number",
							"search": "Search criteria",
							"id": "Identifier",
							"timestamp": "Last modification",
						},
					},
					"QuestionLabel": {
						"name": "Translation",
						"pluralName": "Translations",
						"a": {
							"container": "Question",
							"QuestionViaContainer": "Question",
							"locale": "Language",
							"label": "Name of the question",
							"id": "Identifier",
							"timestamp": "Last modification",
						},
					},
					"Dashboard": {
						"name": "Dashboard",
						"pluralName": "Dashboards",
						"a": {
							"schema": "Name of the application",
							"name": "Dashboard name",
							"dashboardId": "Metabase dashboard id",
							"order": "Order number",
							"search": "Search criteria",
							"id": "Identifier",
							"timestamp": "Last modification",
						},
					},
					"DashboardLabel": {
						"name": "Translation",
						"pluralName": "Translations",
						"a": {
							"container": "Dashboard",
							"DashboardViaContainer": "Dashboard",
							"locale": "Language",
							"label": "Name of the dashboard",
							"id": "Identifier",
							"timestamp": "Last modification",
						},
					},
					"DashboardPermission": {
						"name": "Dashboard permission",
						"pluralName": "Dashboard permissions",
						"a": {
							"role": "Security role",
							"RoleViaRole": "Security role",
							"dashboard": "Dashboard",
							"DashboardViaDashboard": "Dashboard",
							"id": "Identifier",
							"timestamp": "Last modification",
						},
					},
					"EntityKey": {
						"name": "Index",
						"pluralName": "Indexes",
						"a": {
							"container": "Form",
							"EntityViaContainer": "Form",
							"schema": "Name of the application",
							"name": "Name of the index",
							"isUnique": "You can not repeat (unique)",
							"isTextSearch": "Used to search in texts",
							"isPrimaryKey": "It is a primary key",
							"isNullsNotDistinct": "Null values are equal",
							"documentation": "Documentation",
							"search": "Search criteria",
							"id": "Identifier",
							"timestamp": "Last modification",
						},
					},
					"EntityKeyAttribute": {
						"name": "Field",
						"pluralName": "Fields",
						"a": {
							"container": "Index",
							"EntityKeyViaContainer": "Index",
							"attribute": "Field",
							"EntityAttributeViaAttribute": "Field",
							"id": "Identifier",
							"timestamp": "Last modification",
						},
					},
					"EntityLabel": {
						"name": "Translation",
						"pluralName": "Translations",
						"a": {
							"container": "Form",
							"EntityViaContainer": "Form",
							"locale": "Language",
							"label": "Name of the form",
							"pluralLabel": "Name in plural of the form",
							"id": "Identifier",
							"timestamp": "Last modification",
						},
					},
					"EntityReference": {
						"name": "Reference",
						"pluralName": "References",
						"a": {
							"container": "Form",
							"EntityViaContainer": "Form",
							"name": "Name of the reference",
							"referencedKey": "Index referenced",
							"EntityKeyViaReferencedKey": "Index referenced",
							"additionalFilter": "Additional filter",
							"additionalAttributes": "Additional attributes to get in order to use in additional filter",
							"documentation": "Documentation",
							"isList": "Appears in the listings",
							"isVisible": "Appears in the forms",
							"isLabel": "It is used as a label to represent the item",
							"isBasicFilter": "Used as a basic search filter",
							"order": "Default order number",
							"orderInList": "Order in lists (it overrides the default order)",
							"tab": "Tab in which the field is displayed",
							"group": "Group of fields in which the field is displayed",
							"xs": "Number of columns on small screens (in a grid of 12)",
							"sm": "Number of columns on medium or large screens",
							"variants": "Variants in which the field is visible",
							"listIsVisible": "It appears as a table in the forms",
							"listIsFilteredWhenEmpty": "Option to filter when the list is empty",
							"isCascadeDelete": "Related data will be deleted in cascade",
							"isCascadeSetNull": "Set references to null when master is deleted",
							"listOrder": "Order number of the table",
							"listTab": "Tab in which the table is displayed",
							"listGroup": "Group of fields in which the table is displayed",
							"listXs": "Number of columns in the table on small screens",
							"listSm": "Number of columns in the table on medium or large screens",
							"listVariants": "Variants in which the table is visible",
							"isLinkDisabled": "The link in the listings is disabled",
							"search": "Search criteria",
							"id": "Identifier",
							"timestamp": "Last modification",
						},
					},
					"EntityReferenceAttribute": {
						"name": "Field",
						"pluralName": "Fields",
						"a": {
							"container": "Reference",
							"EntityReferenceViaContainer": "Reference",
							"attribute": "Field",
							"EntityAttributeViaAttribute": "Field",
							"id": "Identifier",
							"timestamp": "Last modification",
						},
					},
					"IntelligenceModel": {
						"name": "Intelligence model",
						"pluralName": "Intelligence models",
						"tabs": {
							"1": "Basic configuration",
							"2": "Advanced configuration",
							"3": "Training and validation",
						},
						"a": {
							"entity": "Form",
							"EntityViaEntity": "Form",
							"name": "Model name",
							"problemType": "Problem type",
							"trainingPeriodicity": "Training periodicity",
							"label": "Label (attribute to predict)",
							"EntityAttributeViaLabel": "Label (attribute to predict)",
							"maxTrainingMinutes": "Maximum number of different models to try",
							"targetAttribute": "Attribute where predictions are stored",
							"outputTargetAttribute": "Attribute where model numeric outputs are stored",
							"model": "Trained model",
							"objective": "Objective metric to optimize",
							"objectiveDirection": "Objective direction (minimize or maximize)",
							"trainingResult": "Training result",
							"loss": "Loss function result",
							"trainAsap": "Request training",
							"training": "Training",
							"lastTrainedAt": "Last training time",
							"id": "Identifier",
							"timestamp": "Last modification",
						},
					},
					"IntelligenceFeature": {
						"name": "Feature",
						"pluralName": "Features (fields that are used for the analysis)",
						"a": {
							"container": "Intelligence model",
							"IntelligenceModelViaContainer": "Intelligence model",
							"attribute": "Feature (Attribute to use as feature)",
							"EntityAttributeViaAttribute": "Feature (Attribute to use as feature)",
							"id": "Identifier",
							"timestamp": "Last modification",
						},
					},
					"IntelligenceMetric": {
						"name": "Metric",
						"pluralName": "Metrics (functions used to judge the performance of the model)",
						"a": {
							"container": "Intelligence model",
							"IntelligenceModelViaContainer": "Intelligence model",
							"metric": "Metric",
							"value": "Value of the metric",
							"id": "Identifier",
							"timestamp": "Last modification",
						},
					},
					"EnumType": {
						"name": "List of values",
						"pluralName": "Lists of values",
						"a": {
							"schema": "Name of the application",
							"name": "Name of the list of values",
							"values": "Values",
							"colors": "Colors",
							"icons": "Icons",
							"documentation": "Documentation",
							"search": "Search criteria",
							"id": "Identifier",
							"timestamp": "Last modification",
						},
					},
					"EnumTypeLabel": {
						"name": "Translation",
						"pluralName": "Translations",
						"a": {
							"container": "List of values",
							"EnumTypeViaContainer": "List of values",
							"locale": "Language",
							"labels": "Names of values",
							"id": "Identifier",
							"timestamp": "Last modification",
						},
					},
					"EntityTab": {
						"name": "Tab",
						"pluralName": "Tabs",
						"a": {
							"container": "Form",
							"EntityViaContainer": "Form",
							"name": "Name of the tab",
							"order": "Order number",
							"search": "Search criteria",
							"id": "Identifier",
							"timestamp": "Last modification",
						},
					},
					"EntityTabLabel": {
						"name": "Translation",
						"pluralName": "Translations",
						"a": {
							"container": "Tab",
							"EntityAttributeViaContainer": "Tab",
							"locale": "Language",
							"label": "Name of the tab",
							"id": "Identifier",
							"timestamp": "Last modification",
						},
					},
					"EntityGroup": {
						"name": "Group of fields",
						"pluralName": "Groups of fields",
						"a": {
							"container": "Form",
							"EntityViaContainer": "Form",
							"name": "Name of the group of fields",
							"order": "Order number",
							"search": "Search criteria",
							"id": "Identifier",
							"timestamp": "Last modification",
						},
					},
					"EntityGroupLabel": {
						"name": "Translation",
						"pluralName": "Translations",
						"a": {
							"container": "Group of fields",
							"EntityAttributeViaContainer": "Group of fields",
							"locale": "Language",
							"label": "Name of the group of fields",
							"id": "Identifier",
							"timestamp": "Last modification",
						},
					},
					"Role": {
						"name": "Security",
						"pluralName": "Security",
						"tabs": {
							"1": "Main parameters",
							"2": "Data access permissions",
							"3": "Other permissions",
						},
						"a": {
							"name": "Name of the security role",
							"defaultEntity": "Default entity",
							"documentation": "Documentation",
							"disableReferenceWidgetButtons": "Disable references' autocomplete widget buttons",
							"id": "Identifier",
							"timestamp": "Last modification",
							"search": "Search criteria",
						}
					},
					"EntityPermission": {
						"name": "Form access permission",
						"pluralName": "Form access permissions",
						"a": {
							"role": "Security role",
							"RoleViaRole": "Security role",
							"entity": "Form",
							"EntityViaEntity": "Form",
							"types": "Types of access",
							"id": "Identifier",
							"timestamp": "Last modification",
						},
					},
					"EntityAttributePermission": {
						"name": "Field access permission",
						"pluralName": "Field access permissions",
						"a": {
							"role": "Security role",
							"RoleViaRole": "Security role",
							"attribute": "Field",
							"EntityAttributeViaAttribute": "Field",
							"types": "Types of access",
							"id": "Identifier",
							"timestamp": "Last modification",
						},
					},
					"RowLevelPermission": {
						"name": "Row level permission",
						"pluralName": "Row level permissions",
						"a": {
							"entity": "Form",
							"EntityViaEntity": "Form",
							"name": "Name of the row level permission",
							"as": "Policy type",
							"for": "Operation",
							"role": "Security role",
							"RoleViaRole": "Security role",
							"expression": "Filter expression",
							"checkExpression": "Check expression",
							"id": "Identifier",
							"timestamp": "Last modification",
						},
					},
					"ExternalEntityPermission": {
						"name": "Web link access permission",
						"pluralName": "Web link access permissions",
						"a": {
							"role": "Security role",
							"RoleViaRole": "Security role",
							"externalEntity": "Web link",
							"ExternalEntityViaExternalEntity": "Web link",
							"id": "Identifier",
							"timestamp": "Last modification",
						},
					},
					"User": {
						"name": "User",
						"pluralName": "Users",
						"tabs": {
							"1": "Main parameters",
							"2": "Phone calls",
							"3": "System accesses",
							"4": "Two-factor authentication",
						},
						"a": {
							"username": "Username",
							"password": "Password",
							"email": "Email",
							"emailValidationCode": "Email validation code",
							"passwordChangeValidationCode": "Password change validation code",
							"emailValidated": "Email is validated",
							"isAdmin": "User has Administrator privileges",
							"agreementAccepted": "User has accepted the subscription agreement",
							"phoneIpAddress": "Phone IP address",
							"phonePassword": "Phone password",
							"phoneRedirectAttribute": "Phone call redirect attribute",
							"enable2FA": "Enable two-factor authentication",
							"twoFactorAuthenticationKey": "2FA key",
							"id": "Identifier",
							"timestamp": "Last modification",
							"search": "Search criteria",
						},
					},
					"UserRole": {
						"name": "Users / Roles",
						"pluralName": "Users / Roles",
						"a": {
							"role": "Security role",
							"RoleViaRole": "Security role",
							"user": "User",
							"UserViaUser": "User",
							"id": "Identifier",
							"timestamp": "Last modification",
						},
					},
					"Function": {
						"name": "Function",
						"pluralName": "Functions",
						"a": {
							"schema": "Name of the application",
							"name": "Name of the function",
							"language": "Language",
							"contents": "Function code",
							"cronExpression": "CRON expression (planned execution)",
							"isHttpEnabled": "Enable HTTP endpoint",
							"interceptHttpRequests": "Intercept HTTP requests",
							"interceptSelects": "Intercept queries to database",
							"isCustomFunction": "It is a custom function with custom parameters and return type",
							"customParameters": "Custom parameters (i.e. 'name text, age int')",
							"customReturnType": "Custom return type (i.e. 'boolean')",
							"documentation": "Documentation",
							"search": "Search criteria",
							"id": "Identifier",
							"timestamp": "Last modification",
						},
					},
					"EntityTrigger": {
						"name": "Action",
						"pluralName": "Actions",
						"a": {
							"container": "Form",
							"EntityViaContainer": "Form",
							"name": "Name of the action",
							"moment": "Moment",
							"events": "Events",
							"each": "Granularity",
							"function": "Function",
							"FunctionViaFunction": "Function",
							"condition": "Condition",
							"id": "Identifier",
							"timestamp": "Last modification",
						},
					},
					"EntityCustomTrigger": {
						"name": "Custom action",
						"pluralName": "Custom actions",
						"a": {
							"container": "Form",
							"EntityViaContainer": "Form",
							"name": "Name of the custom action",
							"function": "Function",
							"FunctionViaFunction": "Function",
							"id": "Identifier",
							"timestamp": "Last modification",
						},
					},
					"EntityCustomTriggerLabel": {
						"name": "Translation",
						"pluralName": "Translations",
						"a": {
							"container": "Custom action",
							"EntityCustomTriggerViaContainer": "Custom action",
							"locale": "Language",
							"label": "Name of the custom action",
							"id": "Identifier",
							"timestamp": "Last modification",
						},
					},
					"CustomTriggerPermission": {
						"name": "Custom action permission",
						"pluralName": "Custom action permissions",
						"a": {
							"role": "Security role",
							"RoleViaRole": "Security role",
							"customTrigger": "Custom action",
							"EntityCustomTriggerViaCustomTrigger": "Custom action",
							"id": "Identifier",
							"timestamp": "Last modification",
						},
					},
					"ExternalEntity": {
						"name": "Web link",
						"pluralName": "Web links",
						"a": {
							"schema": "Name of the application",
							"name": "Name of the web link",
							"url": "Address (URL) of the web link",
							"addAccessToken": "Add access token to the target URL",
							"order": "Order number",
							"menu": "Appears in the menu",
							"icon": "Menu icon",
							"documentation": "Documentation",
							"search": "Search criteria",
							"id": "Identifier",
							"timestamp": "Last modification",
						},
					},
					"WebRedirection": {
						"name": "Web redirection",
						"pluralName": "Web redirections",
						"a": {
							"sourcePath": "Source path (i.e. \"/s/a-b\")",
							"targetUrl": "Target URL (i.e. \"https://www.acme.com\")",
							"permanent": "Permanent redirection",
							"id": "Identifier",
							"timestamp": "Last modification",
						},
					},
					"ExternalEntityLabel": {
						"name": "Translation",
						"pluralName": "Translations",
						"a": {
							"container": "Web link",
							"EntityViaContainer": "Web link",
							"locale": "Language",
							"label": "Name of the web link",
							"pluralLabel": "Name in plural of the web link",
							"id": "Identifier",
							"timestamp": "Last modification",
						},
					},
					"WebPackage": {
						"name": "Web Package",
						"pluralName": "Web Packages",
						"a": {
							"name": "Name of the web resource package",
							"type": "Type of package",
							"package": "Web resource package (in ZIP format)",
							"id": "Identifier",
							"timestamp": "Last modification",
						},
					},
					"Personalization": {
						"name": "Settings",
						"pluralName": "Settings",
						"tabs": {
							"1": "Main parameters",
							"2": "User interface",
							"3": "Email",
							"4": "User registration",
						},
						"a": {
							"name": "Profile name",
							"primaryColor": "Main color",
							"secondaryColor": "Secondary color",
							"supportedLanguages": "Supported languages",
							"customerLogo": "Custom logo",
							"documentWatermark": "Document watermark",
							"enabled": "Personalization activated",
							"smtpHost": "SMTP host",
							"smtpPort": "SMTP port",
							"smtpAuth": "SMTP authentication required",
							"smtpUsername": "SMTP username",
							"smtpPassword": "SMTP password",
							"enableUserRegistration": "Enable user registration",
							"defaultUserRole": "Default user role",
							"enableCaptcha": "Enable CAPTCHA",
							"showLogoInMenu": "Show logo in menu",
							"showLogoInHeader": "Show logo in header",
							"id": "Identifier",
							"timestamp": "Last modification",
						},
					},
					"PersonalizationTitle": {
						"name": "Application title translation",
						"pluralName": "Application title translations",
						"a": {
							"container": "Personalization",
							"PersonalizationViaContainer": "Personalization",
							"locale": "Language",
							"title": "Application title",
							"id": "Identifier",
							"timestamp": "Last modification",
						},
					},
					"Report": {
						"name": "Report",
						"pluralName": "Reports",
						"a": {
							"name": "Name of the report template",
							"template": "Report template",
							"id": "Identifier",
							"timestamp": "Last modification",
						},
					},
					"ReportTrigger": {
						"name": "Report generator",
						"pluralName": "Report generators",
						"a": {
							"container": "Report template",
							"ReportViaContainer": "Report template",
							"name": "Name of the generator",
							"triggerEntity": "Trigger form",
							"EntityViaTriggerEntity": "Trigger form",
							"graphql": "GraphQL query",
							"targetAttribute": "Target attribute to store the generated report",
							"EntityAttributeViaTargetAttribute": "Target attribute to store the generated report",
							"targetEntityExpression": "Expression for calculating target entity id",
							"id": "Identifier",
							"timestamp": "Last modification",
						},
					},
					"NetworkPermission": {
						"name": "Network permission",
						"pluralName": "Network permissions",
						"a": {
							"role": "Security role",
							"RoleViaRole": "Security role",
							"accessType": "Access type",
							"order": "Rule order",
							"cidr": "Allowed CIDR",
							"ip": "Allowed IP address",
							"mask": "Allowed mask",
							"id": "Identifier",
							"timestamp": "Last modification",
						},
					},
					"SchemaPermission": {
						"name": "Schema permission",
						"pluralName": "Schema permissions",
						"a": {
							"role": "Security role",
							"RoleViaRole": "Security role",
							"schema": "Allowed schema name",
							"id": "Identifier",
							"timestamp": "Last modification",
						},
					},
					"UserAccess": {
						"name": "System access",
						"pluralName": "System accesses",
						"a": {
							"user": "User",
							"UserViaUser": "User",
							"ip": "User IP address",
							"id": "Identifier",
							"timestamp": "Access time",
						},
					},
					"ErrorMessage": {
						"name": "Error message",
						"pluralName": "Error messages",
						"a": {
							"name": "Name of the error message",
							"regularExpression": "Regular expression that matches the error message",
							"documentation": "Documentation",
							"search": "Search criteria",
							"id": "Identifier",
						},
					},
					"ErrorMessageLabel": {
						"name": "Translation",
						"pluralName": "Translations",
						"a": {
							"container": "Error message",
							"ErrorMessageViaContainer": "Error message",
							"locale": "Language",
							"message": "Message template",
							"id": "Identifier",
						},
					},
				},
			},
		},
	},
	es_ES: {
		translation: {
			"title": "Administración de Datos",
			
			"modelingTool": "Herramienta de modelado",
			
			"manage": "Gestionar",
			"open": "Ver seleccionado",
			
			"getMoreData": "Cargar más datos",
			
			"anyValue": "Cualquier valor",
			"notSpecified": "Valor sin especificar",
			
			"logViewer": "Visor de trazas",
			"tail": "Número de líneas a cargar",
			"refreshLog": "Refrescar trazas",
			"clearLog": "Borrar trazas",
			
			"fileRejected": "Fichero rechazado.",
			"fileTypeNotSupported": "El tipo de fichero es incorrecto.",
			"fileTooBig": "El tamaño máximo permitido es ${limit}.",
			
			"filtersApplied": "El resultado está filtrado",
			"accessControl": "Control de acceso",
			"changePassword": "Cambio de contraseña",
			"toChangePassword": "Cambiar",
			"changePasswordDescription": "Por favor, elija una nueva contraseña",
			"passwordChangeSuccess": "La contraseña se ha modificado correctamente",
			"newPassword": "Nueva contraseña",
			"newPasswordRepeat": "Repita nueva contraseña",
			"passwordsDontMatch": "Las contraseñas no coinciden",
			"chooseANewPassword": "Por favor, elija una contraseña nueva",
			"username": "Nombre de usuario",
			"password": "Contraseña",
			"twoFactorAuthenticationVerificationCode": "Código de verificación (2FA)",
			"language": "Idioma",
			"login": "Acceder",
			"forgotPassword": "¿Olvidaste la contraseña?",
			"incorrectUsernameOrPassword": "Acceso denegado",
			"invalidPassword": "Las contraseñas deben tener al menos 8 caracteres, 1 letra minúscula, 1 letra mayúscula, 1 dígito y 1 símbolo",
			"generateCode": "Generar código",
			"logicCategory": "Lógica",
			"loopsCategory": "Bucles",
			"mathCategory": "Matemáticas",
			"textCategory": "Texto",
			"listsCategory": "Listas",
			"variablesCategory": "Variables",
			
			"userInfo": "Información del usuario",
			"user": "Usuario",
			
			"fullscreen": "Pantalla completa",
			
			"prediction": "Predicción",
			
			"error": "Ha ocurrido un error desconocido",
			
			"primaryKeyWithOnlyOneAttributeNeeded": "La clave primaria solamente puede tener un atributo",
			
			"invalidValue": "El valor introducido no es válido",
			
			"selected": "seleccionado(s)",
			
			"deleteConfirmation": "Confirmación",
			"deleteQuestion": "¿Eliminar",
			"document": "documento",
			
			"cancel": "Cancelar",
			"save": "Guardar",
			"scanMrz": "Escanear MRZ (zona legible por máquina) de tarjeta de identificación",

			"mrzError": "Error de lectura o MRZ desconocido",
			
			"clear": "Borrar",

			"zoomToFit": "Ajustar tamaño",
			
			"scanBarcode": "Escanear código de barras",
			
			"with": "con",
			
			"dragOrSelect": "Arrastra hasta aquí o selecciona el documento que quieres añadir",
			"multipleDragOrSelect": "Arrastra hasta aquí o selecciona los documentos que quieres añadir",

			"latitude": "Latitud",
			"longitude": "Longitud",
			
			"tableView": "Vista de tabla",
			"calendarView": "Vista de calendario",
			"mapView": "Vista de mapa",
			"galleryView": "Vista de galería",
			"diagramView": "Vista de diagrama",
			
			"calendarCulture": "es",
			"calendarDate": "Fecha",
			"calendarTime": "Hora",
			"calendarEvent": "Evento",
			"calendarAllDay": "Todo el día",
			"calendarWeek": "Semana",
			"calendarWorkWeek": "Semana laboral",
			"calendarDay": "Día",
			"calendarMonth": "Mes",
			"calendarPrevious": "Anterior",
			"calendarNext": "Siguiente",
			"calendarYesterday": "Ayer",
			"calendarTomorrow": "Mañana",
			"calendarToday": "Hoy",
			"calendarAgenda": "Agenda",
			
			"view": "Ver",
			"edit": "Editar",
			"delete": "Eliminar",
			"copyShortUrlToClipboard": "Copiar URL corta al portapapeles",
			"download": "Descargar",
			"exportToCSV": "Exportar a Excel",
			"new": "Añadir",
			"newRecord": "Nuevo",
			
			"fileContents": "Contenido del fichero",
			
			"noOptions": "No hay opciones",
			"loading": "Cargando...",
			"selectValue": "",
			
			"noData": "No se han encontrado resultados",
			"noDataWithImage": "No se han encontrado resultados con imágenes",
			
			"enableBasicFilters": "Mostrar filtros básicos",
			"disableBasicFilters": "Ocultar filtros básicos",
			
			"back": "Ir a la página anterior",
			"forward": "Ir a la página siguiente",
			"refresh": "Refrescar página",
			"sessionInfo": "Información de la sesión",
			"enableAIAssistant": "Mostrar asistente IA",
			"disableAIAssistant": "Ocultar asistente IA",
			
			"enableIntelligence": "Activar inteligencia",
			"disableIntelligence": "Desactivar inteligencia",

			"search": "Buscar",
			"applyFilter": "Aplicar filtro de búsqueda",
			"searchHowTo": "La expresión de búsqueda debe estar compuesta de palabras separadas por los operadores & (y), | (o) y ! (no). Estos operadores se pueden agrupar utilizando paréntesis. También se puede añadir :* a una palabra para especificar que se trata de un prefijo, por ejemplo 'super:*'. También se pueden utilizar frases entre comillas, por ejemplo '\"mejor precio\"'). Se pueden utilizar asteriscos para buscar texto contenido, por ejemplo '*bla*'.",
			"searchCriteria": "Criterios de búsqueda",
			"clearCriteria": "Borrar criterios de búsqueda",
			
			"deleteSuccess": "elemento(s) eliminado(s) correctamente",
			
			"referenceCreated": "Referencia creada correctamente",
			"referenceDeleted": "Referencia eliminada correctamente",
			"deleteNotImplemented": "El borrado desde el diagrama no está implementado",
			
			"virtualAnalyst": "Indicadores",
			
			"closeSession": "Cerrar sesión",
			"documentation": "Documentación",
			
			"searchFilter": "Filtro de búsqueda",

			"adminGroup": "Admin",
			"dashboardsGroup": "Cuadros de mando",
			"admin": "Ajustes",
			"backupBackup": "Hacer copia de seguridad",
			"downloadBackup": "Realizar y descargar copia de seguridad",

			"systemTab": "Información del Sistema",
			"exportImportTab": "Exportación / Importación",
			"domainTab": "Dominio",
			"iamTab": "IAM",
			"dashboardsTab": "Dashboards",
			"databaseTab": "Base de datos",
			"otherTab": "Otros parámetros",
			
			"excelExport": "Exportar modelo a Excel",
			"excelImport": "Importar datos o modelos desde Excel o foto",
			"downloadExcel": "Descargar modelo", 
			"uploadExcel":  "Arrastra o selecciona los datos que quieres importar",
			"importConfirmation": "Confirmación de importación",
			"importQuestion": "Vas a importar datos, ¿Estás seguro?",
			"import": "Importar",
			"importSuccess": "Los datos se han importado correctamente",
			
			"updateFQDN": "Actualizar FQDN (Nombre de dominio completo)",
			"fqdn": "FQDN (Nombre de dominio completo)",
			"changeFQDN": "Aplicar cambio",
			"confirmFQDNChange": "Se generará un certificado SSL para el nuevo FQDN y se reiniciará la plataforma, ¿Estás seguro?",
			"newFQDNDoesNotPointToIP": "Por favor, configura la nueva FQDN para que apunte a la dirección IP correcta",
			"newFQDNTryAgain": "Entonces, vuelve a intentarlo.",
			
			"updateEmbeddingSecretKey": "Actualizar la clave secreta de Metabase",
			"embeddingSecretKey": "Clave secreta",
			"changeEmbeddingSecretKey": "Aplicar cambio",
			"confirmEmbeddingSecretKeyChange": "La clave secreta de Metabase se actualizará, ¿Estás seguro?",
			"updateEmbeddingSecretKeySuccess": "La clave secreta de Metabase se ha actualizado correctamente",
			
			"updateDocumentMaxFileSizeMB": "Actualizar tamaño máximo de documentos",
			"documentMaxFileSizeMB": "Tamaño máximo de documentos (MB)",
			"changeDocumentMaxFileSizeMB": "Aplicar cambio",
			"confirmDocumentMaxFileSizeMBChange": "El tamaño máximo de documentos se actualizará, ¿Estás seguro?",
			"updateDocumentMaxFileSizeMBSuccess": "El tamaño máximo de documentos se ha actualizado correctamente",
			"updateDocumentMaxFileSizeMBError": "El valor debe estar comprendido entre 1 y 2000",
			
			"updateIAM": "Actualizar la configuración de IAM",
			"publicKey": "Clave pública",
			"defaultRole": "Rol predeterminado",
			"externalUrl": "URL de IAM externo",
			"changeIAM": "Aplicar cambio",
			"confirmIAMChange": "La configuración de IAM se actualizará, ¿Estás seguro?",
			"updateIAMSuccess": "La configuración de IAM se ha actualizado correctamente",
			
			"version": "Versión de la plataforma",
			"edition": "Edición",
			
			"dataVolumeSizes": "Tamaño del volumen de datos",
			"dataVolumeSize": "Total",
			"dataVolumeUsed": "Ocupado",
			"dataVolumeAvailable": "Disponible",
			"unknown": "Desconocido",
			
			"pitrBackup": "Copia de seguridad base",
			"pitrBackup": "Solicitar copia de seguridad base",
			"pitrDeletePrevious": "Eliminar copias de seguridad base anteriores",
			"pitrBackupConfirmation": "Confirmación de copia de seguridad",
			"pitrDeletePreviousConfirmation": "Confirmación de eliminación de backups anteriores",
			"pitrBackupQuestion": "Vas a solicitar una nueva copia de seguridad base, ¿Estás seguro?",
			"pitrDeletePreviousQuestion": "Vas a borrar los backups base anteriores, ¿Estás seguro?",
			"pitrBackupSuccess": "La copia de seguridad se ha llevado a cabo correctamente",
			"pitrDeletePreviousSuccess": "Se han eliminado correctamente todas las copias de seguridad base anteriores",
			
			"pitrExport": "Exportar la copia de seguridad base",
			"pitrDownload": "Descargar la copia de seguridad base", 
			
			"pitrRestore": "Restaurar copia de seguridad",
			"pitrRestoreTargetTime": "Fecha y hora objetivo (UTC)",
			"pitrRestoreConfirmation": "Confirmación de restauración",
			"pitrRestoreQuestion": "Vas a restaurar una copia de seguridad de la base de datos, ¿Estás seguro?",
			"pitrRestoreSuccess": "La copia de seguridad de la base de datos se ha restaurado correctamente",
			
			"deleteDatabase": "Eliminar base de datos",
			"deleteRestoreToDefault": "Eliminar base de datos y restaurar a valores de fábrica",
			"deleteDatabaseConfirmation": "Confirmación de borrado",
			"deleteDatabaseQuestion": "Vas a borrar todos los datos, ¿Estás seguro?",
			"deleteDatabaseSuccess": "El sistema se ha restaurado correctamente a los valores de fábrica",
			
			"restartPlatform": "Reiniciar plataforma",
			"restartPlatformConfirmation": "Reiniciar plataforma",
			"restartPlatformQuestion": "Vas a reiniciar la plataforma, ¿Estás seguro?",
			"restartPlatformSuccess": "La plataforma se ha reiniciado correctamente",
			
			"backupRestore": "Restaurar copia de seguridad",
			"uploadBackup": "Arrastra o selecciona el archivo de copia de seguridad que quieres restaurar",
			"restoreConfirmation": "Confirmación de restauración",
			"restoreQuestion": "Vas a restaurar una copia de seguridad del sistema, se borrarán todos los datos, ¿Estás seguro?",
			"restore": "Restaurar",
			"restoreSuccess": "La copia de seguridad se ha restaurado correctamente",
			"restoreOnlyOneFile": "Por favor, seleccione solamente un archivo",
			
			// enums = EnumTypes
			"enums": {
				// Schemas
				"Models": {
					"problemType": {
						"v": {
							"CLASSIFICATION": "Clasificación (Clasifica/Predice un valor discreto tal como Hombre or Mujer, Verdadero o Falso, ...)",
							"REGRESSION": "Regresión (Predice un valor continuo tal como precio, salario, edad, ...)",
						},
					},
					"trainingPeriodicityType": {
						"v": {
							"DAILY": "Diaria",
							"WEEKLY": "Semanal",
							"MONTHLY": "Mensual",
						},
					},
					"entityPermissionType": {
						"v": {
							"SELECT": "Permiso de consulta",
							"INSERT": "Permiso de creación",
							"UPDATE": "Permiso de modificación",
							"DELETE": "Permiso de borrado",
							"MENU": "Permiso de menú"
						}
					},
					"policyAsType": {
						"v": {
							"PERMISSIVE": "Política permisiva",
							"RESTRICTIVE": "Política restrictiva",
						}
					},
					"policyForType": {
						"v": {
							"ALL": "Aplicar a todas las operaciones",
							"SELECT": "Operaciones de consulta",
							"INSERT": "Operaciones de inserción",
							"UPDATE": "Operaciones de modificación",
							"DELETE": "Operaciones de borrado",
						}
					},
					"accessType": {
						"v": {
							"ACCEPT": "Aceptar acceso",
							"REJECT": "Rechazar acceso",
						}
					},
					"entityAttributePermissionType": {
						"v": {
							"SELECT": "Permiso de consulta",
							"INSERT": "Permiso de creación",
							"UPDATE": "Permiso de modificación"
						}
					},
					"entityAttributeType": {
						"v": {
							"TEXT": "Cadena de texto",
							"BOOLEAN": "Sí o no",
							"INTEGER": "Número entero",
							"DECIMAL": "Número con decimales",
							"DATE": "Fecha",
							"TIMESTAMP": "Fecha y hora",
							"SERIAL": "Número secuencial",
							"TIME": "Hora",
							"POINT": "Punto en un plano o mapa",
							"DOCUMENT": "Documento (imagen, audio, PDF, Word, Excel, ...)",
							"SIGNATURE": "Firma manuscrita",
							"BARCODE": "Código de barras",
							"VECTOR": "Vector",
						}
					},
					"momentType": {
						"v": {
							"BEFORE": "BEFORE",
							"AFTER": "AFTER",
							"INSTEAD_OF": "INSTEAD OF",
						}
					},
					"eventType": {
						"v": {
							"INSERT": "INSERT",
							"UPDATE": "UPDATE",
							"DELETE": "DELETE",
						}
					},
					"eachType": {
						"v": {
							"ROW": "ROW",
							"STATEMENT": "STATEMENT",
						}
					},
					"mrzFieldType": {
						"v": {
							"administrativeCode": "Código administrativo",
							"administrativeCode2": "Código administrativo 2",
							"birthDate": "Fecha de nacimiento",
							"documentCode": "Código de documento",
							"documentNumber": "Número de documento",
							"expirationDate": "Fecha de caducidad",
							"firstName": "Nombre",
							"issueDate": "Fecha de expedición",
							"issuingState": "Lugar de expedición",
							"languageCode": "Código de idioma",
							"lastName": "Apellidos",
							"nationality": "Nacionalidad",
							"optional": "Opcional",
							"optional1": "Opcional 1",
							"optional2": "Opcional 2",
							"personalNumber": "Número personal",
							"pinCode": "Código PIN",
							"sex": "Sexo",
							"versionNumber": "Número de versión",
						}
					},
					"webPackageType": {
						"v": {
							"STATIC": "Web estática",
							"NODE": "Paquete Node.js (Next.js, ...)",
						}
					},
				},
			},
			
			// e = Entities
			"e": {
				// Schemas
				"Models": {
					"CustomType": {
						"name": "Tipo compuesto personalizado",
						"pluralName": "Tipos compuestos",
						"a": {
							"schema": "Nombre de la aplicación",
							"name": "Nombre del tipo compuesto",
							"documentation": "Documentación",
							"search": "Criterio de búsqueda",
							"id": "Identificador",
							"timestamp": "Última modificación",
						},
					},
					"CustomTypeAttribute": {
						"name": "Campo",
						"pluralName": "Campos",
						"a": {
							"container": "Tipo compuesto personalizado",
							"CustomTypeViaContainer": "Tipo compuesto personalizado",
							"name": "Nombre del campo",
							"type": "Tipo de contenido",
							"isArray": "Soporta múltiples valores",
							"customType": "Tipo compuesto personalizado",
							"CustomTypeViaCustomType": "Tipo compuesto personalizado",
							"enumType": "Lista de valores",
							"EnumTypeViaEnumType": "Lista de valores",
							"length": "Longitud máxima",
							"precision": "Precisión (número total de dígitos)",
							"scale": "Escala (número de dígitos decimales)",
							"documentation": "Documentación",
							"search": "Criterio de búsqueda",
							"id": "Identificador",
							"timestamp": "Última modificación",
						},
					},
					"CustomTypeAttributeLabel": {
						"name": "Traducción",
						"pluralName": "Traducciones",
						"a": {
							"container": "Campo",
							"CustomTypeAttributeViaContainer": "Campo",
							"locale": "Idioma",
							"label": "Nombre del campo traducido",
							"id": "Identificador",
							"timestamp": "Última modificación",
						},
					},
					"CustomTypeLabel": {
						"name": "Traducción",
						"pluralName": "Traducciones",
						"a": {
							"container": "Tipo compuesto personalizado",
							"CustomTypeViaContainer": "Tipo compuesto personalizado",
							"locale": "Idioma",
							"label": "Nombre del tipo compuesto traducido",
							"id": "Identificador",
							"timestamp": "Última modificación",
						},
					},
					"Variable": {
						"name": "Variable",
						"pluralName": "Variables",
						"a": {
							"name": "Nombre de la variable",
							"value": "Valor de la variable",
							"documentation": "Documentación",
							"search": "Criterio de búsqueda",
							"id": "Identificador",
						},
					},
					"Application": {
						"name": "Aplicación",
						"pluralName": "Aplicaciones",
						"a": {
							"name": "Nombre de la aplicación",
							"order": "Número de orden",
							"color": "Color del tema",
							"documentation": "Documentación",
							"search": "Criterio de búsqueda",
							"id": "Identificador",
						},
					},
					"ApplicationLabel": {
						"name": "Traducción",
						"pluralName": "Traducciones",
						"a": {
							"container": "Aplicación",
							"ApplicationViaContainer": "Aplicación",
							"locale": "Idioma",
							"label": "Nombre de la aplicación",
							"id": "Identificador",
						},
					},
					"Entity": {
						"name": "Formulario",
						"pluralName": "Formularios",
						"tabs": {
							"1": "Parámetros principales",
							"2": "Campos",
							"3": "Índices y referencias",
							"4": "Acciones",
							"5": "Inteligencia",
							"6": "Interfaz de usuario",
							"7": "Preguntas",
						},
						"a": {
							"schema": "Nombre de la aplicación",
							"name": "Nombre del formulario",
							"language": "Idioma de los contenidos (utilizado para búsquedas de texto)",
							"order": "Número de orden",
							"menu": "Aparece en el menú",
							"icon": "Icono del menú",
							"isExportToCSVEnabled": "Exportación a Excel habilitada",
							"documentation": "Documentación",
							"defaultOrderAttribute": "Atributo utilizado para la ordenación predeterminada",
							"EntityAttributeViaDefaultOrderAttribute": "Atributo utilizado para la ordenación predeterminada",
							"defaultOrderDesc": "Ordenar en sentido descendente",
							"defaultOrderNullsLast": "Mostrar valores nulos al final",
							"search": "Criterio de búsqueda",
							"id": "Identificador",
							"timestamp": "Última modificación",
						},
					},
					"EntityAttribute": {
						"name": "Campo",
						"pluralName": "Campos",
						"tabs": {
							"1": "Parámetros principales",
							"2": "Interfaz de usuario",
							"3": "Parámetros específicos por tipo",
							"4": "Selector externo",
						},
						"groups": {
							"1": "Cadena de texto",
							"3": "Número",
							"5": "Fecha y hora",
							"9": "Punto en un plano",
							"10": "Documento",
							"12": "Código de barras",
							"13": "MRZ (zona legible por máquina)",
						},
						"a": {
							"container": "Formulario",
							"EntityViaContainer": "Formulario",
							"name": "Nombre del campo",
							"type": "Tipo de contenido",
							"isArray": "Soporta múltiples valores",
							"allowDuplicates": "Soporta valores repetidos",
							"defaultValue": "Valor predeterminado",
							"isRequired": "Es obligatorio",
							"customType": "Tipo compuesto personalizado",
							"CustomTypeViaCustomType": "Tipo compuesto personalizado",
							"enumType": "Lista de valores",
							"EnumTypeViaEnumType": "Lista de valores",
							"length": "Longitud máxima",
							"precision": "Precisión (número total de dígitos)",
							"scale": "Escala (número de dígitos decimales)",
							"isLabel": "Se utiliza como etiqueta para representar al formulario",
							"labelLanguage": "Se utiliza como etiqueta para un idioma específico",
							"isList": "Aparece en los listados",
							"isVisible": "Aparece en los formularios",
							"isBasicFilter": "Se utiliza como filtro de búsqueda básico",
							"isTextFilter": "Se utiliza como filtro de búsqueda de texto",
							"isTextSearch": "Acumula el contenido de los filtros de búsqueda de texto y habilita la funcionalidad de búsqueda",
							"isLastInRow": "Es el último campo de la fila",
							"isHidden": "El campo está oculto, pero el valor se envía",
							"isEventStart": "El campo es el inicio de un evento en el calendario",
							"isEventEnd": "El campo es el final de un evento en el calendario",
							"isPointInMap": "El campo es un punto en el mapa",
							"isImageInGallery": "El campo es una imagen en la vista de galería",
							"indexDocuments": "NO-SE-UTILIZA",
							"browseZip": "Navegar por ficheros Zip",
							"isEncrypted": "El campo se almacena cifrado",
							"isComputed": "El campo se calcula (no es modificable)",
							"isIcon": "El campo es un icono",
							"isColor": "El campo es un color",
							"isHighlighter": "El color se utiliza como resaltador",
							"order": "Número de orden predeterminado",
							"orderInList": "Número de orden en los listados (sobreescribe el orden predeterminado)",
							"tab": "Pestaña en la que se muestra el campo",
							"group": "Grupo de campos en el que se muestra el campo",
							"variants": "Variantes en las que el campo es visible",
							"isVariantSelector": "El campo se utiliza para seleccionar las variantes",
							"xs": "Número de columnas en pantallas pequeñas (en un grid de 12)",
							"sm": "Número de columnas en pantallas medianas o grandes",
							"min": "Valor mínimo",
							"max": "Valor máximo",
							"step": "Resolución",
							"prefix": "Prefijo",
							"suffix": "Sufijo",
							"disableThousandsSeparator": "Deshabilitar separador de millares",
							"pattern": "Expresión regular de validación",
							"isPassword": "Es un campo de contraseña",
							"isMultiline": "Es multi-línea",
							"isRich": "Es texto enriquecido",
							"isAction": "Es enlace o función",
							"isActionVisibleInForm": "El botón de acción también es visible en el formulario",
							"isActionVisibleInToolbar": "El botón de acción también es visible en la barra de herramientas",
							"actionIcon": "Icono del botón de acción",
							"maxFiles": "Número máximo de ficheros",
							"acceptedFileTypes": "Tipos de ficheros aceptados",
							"barcodeType": "Tipo de código de barras",
							"mrzField": "Campo MRZ (zona legible por máquina)",
							"documentation": "Documentación",
							"search": "Criterio de búsqueda",
							"id": "Identificador",
							"timestamp": "Última modificación",
							"externalSelectorUrl": "Dirección (URL) del selector externo",
							"externalSelectorTarget": "Destino (Nombre del contexto de navegación o destino especial (por ejemplo: _self, _blank, _parent, _top)",
							"externalSelectorWindowFeatures": "Lista de características de la ventana separadas por coma (por ejemplo: popup,width=400,height=300)",
							"addAccessToken": "Añadir token de acceso a la URL del selector externo",
						},
					},
					"EntityAttributeLabel": {
						"name": "Traducción",
						"pluralName": "Traducciones",
						"a": {
							"container": "Campo",
							"EntityAttributeViaContainer": "Campo",
							"locale": "Idioma",
							"label": "Nombre del campo traducido",
							"id": "Identificador",
							"timestamp": "Última modificación",
						},
					},
					"Question": {
						"name": "Pregunta",
						"pluralName": "Preguntas",
						"a": {
							"container": "Formulario",
							"EntityViaContainer": "Formulario",
							"name": "Nombre de la pregunta",
							"questionId": "Identificador de la pregunta en Metabase",
							"isList": "Aparece en los listados",
							"isVisible": "Aparece en los formularios",
							"useIds": "Utilizar los ids de los elementos seleccionados como parámetros",
							"order": "Número de orden",
							"search": "Criterio de búsqueda",
							"id": "Identificador",
							"timestamp": "Última modificación",
						},
					},
					"QuestionLabel": {
						"name": "Traducción",
						"pluralName": "Traducciones",
						"a": {
							"container": "Pregunta",
							"QuestionViaContainer": "Pregunta",
							"locale": "Idioma",
							"label": "Nombre de la pregunta",
							"id": "Identificador",
							"timestamp": "Última modificación",
						},
					},
					"Dashboard": {
						"name": "Cuadro de mando",
						"pluralName": "Cuadros de mando",
						"a": {
							"schema": "Nombre de la aplicación",
							"name": "Nombre del cuadro de mando",
							"dashboardId": "Identificador del cuadro de mando en Metabase",
							"order": "Número de orden",
							"search": "Criterio de búsqueda",
							"id": "Identificador",
							"timestamp": "Última modificación",
						},
					},
					"DashboardLabel": {
						"name": "Traducción",
						"pluralName": "Traducciones",
						"a": {
							"container": "Cuadro de mando",
							"DashboardViaContainer": "Cuadro de mando",
							"locale": "Idioma",
							"label": "Nombre del cuadro de mando",
							"id": "Identificador",
							"timestamp": "Última modificación",
						},
					},
					"DashboardPermission": {
						"name": "Permiso de cuadro de mando",
						"pluralName": "Permisos de cuadros de mando",
						"a": {
							"role": "Rol de seguridad",
							"RoleViaRole": "Rol de seguridad",
							"dashboard": "Cuadro de mando",
							"DashboardViaDashboard": "Cuadro de mando",
							"id": "Identificador",
							"timestamp": "Última modificación",
						},
					},
					"EntityKey": {
						"name": "Índice",
						"pluralName": "Índices",
						"a": {
							"container": "Formulario",
							"EntityViaContainer": "Formulario",
							"schema": "Nombre de la aplicación",
							"name": "Nombre del índice",
							"isUnique": "No se puede repetir (único)",
							"isTextSearch": "Se utiliza para buscar en textos",
							"isPrimaryKey": "Es clave primaria",
							"isNullsNotDistinct": "Los valores nulos son iguales",
							"documentation": "Documentación",
							"search": "Criterio de búsqueda",
							"id": "Identificador",
							"timestamp": "Última modificación",
						},
					},
					"EntityKeyAttribute": {
						"name": "Campo",
						"pluralName": "Campos",
						"a": {
							"container": "Índice",
							"EntityKeyViaContainer": "Índice",
							"attribute": "Campo",
							"EntityAttributeViaAttribute": "Campo",
							"id": "Identificador",
							"timestamp": "Última modificación",
						},
					},
					"EntityLabel": {
						"name": "Traducción",
						"pluralName": "Traducciones",
						"a": {
							"container": "Formulario",
							"EntityViaContainer": "Formulario",
							"locale": "Idioma",
							"label": "Nombre del formulario",
							"pluralLabel": "Nombre en plural del formulario",
							"id": "Identificador",
							"timestamp": "Última modificación",
						},
					},
					"EntityReference": {
						"name": "Referencia",
						"pluralName": "Referencias",
						"a": {
							"container": "Formulario",
							"EntityViaContainer": "Formulario",
							"name": "Nombre de la referencia",
							"referencedKey": "Índice referenciado",
							"EntityKeyViaReferencedKey": "Índice referenciado",
							"additionalFilter": "Filtro adicional",
							"additionalAttributes": "Atributos adicionales a devolver para usar en filtros adicionales",
							"documentation": "Documentación",
							"isList": "Aparece en los listados",
							"isVisible": "Aparece en los formularios",
							"isLabel": "Se utiliza como etiqueta para representar al ítem",
							"isBasicFilter": "Se utiliza como filtro de búsqueda básico",
							"order": "Número de orden predeterminado",
							"orderInList": "Número de orden en los listados (sobreescribe el orden predeterminado)",
							"tab": "Pestaña en la que se muestra el campo",
							"group": "Grupo de campos en el que se muestra el campo",
							"xs": "Número de columnas en pantallas pequeñas (en un grid de 12)",
							"sm": "Número de columnas en pantallas medianas o grandes",
							"variants": "Variantes en las que es visible el campo",
							"listIsVisible": "Aparece como tabla en los formularios",
							"listIsFilteredWhenEmpty": "Opción para filtrar cuando la lista esté vacía",
							"isCascadeDelete": "La información relacionada se eliminará en cascada",
							"isCascadeSetNull": "Poner referencias a nulo cuando se borra el dato maestro",
							"listOrder": "Número de orden de la tabla",
							"listTab": "Pestaña en la que se muestra la tabla",
							"listGroup": "Grupo de campos en el que se muestra la tabla",
							"listXs": "Número de columnas de la tabla en pantallas pequeñas",
							"listSm": "Número de columnas de la tabla en pantallas medianas o grandes",
							"listVariants": "Variantes en las que es visible la tabla",
							"isLinkDisabled": "El enlace en los listados está deshabilitado",
							"search": "Criterio de búsqueda",
							"id": "Identificador",
							"timestamp": "Última modificación",
						},
					},
					"EntityReferenceAttribute": {
						"name": "Campo",
						"pluralName": "Campos",
						"a": {
							"container": "Referencia",
							"EntityReferenceViaContainer": "Referencia",
							"attribute": "Campo",
							"EntityAttributeViaAttribute": "Campo",
							"id": "Identificador",
							"timestamp": "Última modificación",
						},
					},
					"IntelligenceModel": {
						"name": "Modelo de inteligencia",
						"pluralName": "Modelos de inteligencia",
						"tabs": {
							"1": "Configuración básica",
							"2": "Configuración avanzada",
							"3": "Entrenamiento y validación",
						},
						"a": {
							"entity": "Formulario",
							"EntityViaEntity": "Formulario",
							"name": "Nombre del modelo",
							"problemType": "Tipo de problema",
							"trainingPeriodicity": "Periodicidad del entrenamiento",
							"label": "Etiqueta (Campo a predecir)",
							"EntityAttributeViaLabel": "Etiqueta (Campo a predecir)",
							"maxTrainingMinutes": "Número máximo de modelos diferentes a probar",
							"targetAttribute": "Atributo donde se guardan las predicciones",
							"outputTargetAttribute": "Atributo donde se guarda la salida numérica del modelo",
							"model": "Modelo entrenado",
							"objective": "Objetivo o métrica a optimizar",
							"objectiveDirection": "Dirección de optimización (minimizar o maximizar)",
							"trainingResult": "Resultado del entrenamiento",
							"loss": "Resultado de la función de pérdida",
							"trainAsap": "Solicitar entrenamiento",
							"training": "Entrenando",
							"lastTrainedAt": "Hora de último entrenamiento",
							"id": "Identificador",
							"timestamp": "Última modificación",
						},
					},
					"IntelligenceFeature": {
						"name": "Feature (Campo de entrenamiento)",
						"pluralName": "Features (Campos de entrenamiento)",
						"a": {
							"container": "Modelo de inteligencia",
							"IntelligenceModelViaContainer": "Modelo de inteligencia",
							"attribute": "Feature (Campo de entrenamiento)",
							"EntityAttributeViaAttribute": "Feature (Campo de entrenamiento)",
							"id": "Identificador",
							"timestamp": "Última modificación",
						},
					},
					"IntelligenceMetric": {
						"name": "Métrica",
						"pluralName": "Métricas (funciones utilizadas para juzgar el rendimiento del modelo)",
						"a": {
							"container": "Modelo de inteligencia",
							"IntelligenceModelViaContainer": "Modelo de inteligencia",
							"metric": "Métrica",
							"value": "Valor de la métrica",
							"id": "Identificador",
							"timestamp": "Última modificación",
						},
					},
					"EnumType": {
						"name": "Lista de valores",
						"pluralName": "Listas de valores",
						"a": {
							"schema": "Nombre de la aplicación",
							"name": "Nombre de la lista de valores",
							"values": "Valores",
							"colors": "Colores",
							"icons": "Iconos",
							"documentation": "Documentación",
							"search": "Criterio de búsqueda",
							"id": "Identificador",
							"timestamp": "Última modificación",
						},
					},
					"EnumTypeLabel": {
						"name": "Traducción",
						"pluralName": "Traducciones",
						"a": {
							"container": "Lista de valores",
							"EnumTypeViaContainer": "Lista de valores",
							"locale": "Idioma",
							"labels": "Nombres de los valores traducidos",
							"id": "Identificador",
							"timestamp": "Última modificación",
						},
					},
					"EntityTab": {
						"name": "Pestaña",
						"pluralName": "Pestañas",
						"a": {
							"container": "Formulario",
							"EntityViaContainer": "Formulario",
							"name": "Nombre de la pestaña",
							"order": "Número de orden",
							"search": "Criterio de búsqueda",
							"id": "Identificador",
							"timestamp": "Última modificación",
						},
					},
					"EntityTabLabel": {
						"name": "Traducción",
						"pluralName": "Traducciones",
						"a": {
							"container": "Pestaña",
							"EntityAttributeViaContainer": "Pestaña",
							"locale": "Idioma",
							"label": "Nombre traducido de la pestaña",
							"id": "Identificador",
							"timestamp": "Última modificación",
						},
					},
					"EntityGroup": {
						"name": "Grupo de campos",
						"pluralName": "Grupos de campos",
						"a": {
							"container": "Formulario",
							"EntityViaContainer": "Formulario",
							"name": "Nombre del grupo de campos",
							"order": "Número de orden",
							"search": "Criterio de búsqueda",
							"id": "Identificador",
							"timestamp": "Última modificación",
						},
					},
					"EntityGroupLabel": {
						"name": "Traducción",
						"pluralName": "Traducciones",
						"a": {
							"container": "Grupo de campos",
							"EntityAttributeViaContainer": "Grupo de campos",
							"locale": "Idioma",
							"label": "Nombre traducido del grupo de campos",
							"id": "Identificador",
							"timestamp": "Última modificación",
						},
					},
					"Role": {
						"name": "Seguridad",
						"pluralName": "Seguridad",
						"tabs": {
							"1": "Parámetros principales",
							"2": "Permisos de acceso a datos",
							"3": "Otros permisos",
						},
						"a": {
							"name": "Nombre del rol de seguridad",
							"defaultEntity": "Formulario predeterminado",
							"documentation": "Documentación",
							"disableReferenceWidgetButtons": "Deshabilitar botones del componente de selección de entidades referenciadas",
							"id": "Identificador",
							"timestamp": "Última modificación",
							"search": "Criterio de búsqueda",
						}
					},
					"EntityPermission": {
						"name": "Permiso de acceso a formularios",
						"pluralName": "Permisos de acceso a formularios",
						"a": {
							"role": "Rol de seguridad",
							"RoleViaRole": "Rol de seguridad",
							"entity": "Formulario",
							"EntityViaEntity": "Formulario",
							"types": "Tipos de acceso",
							"id": "Identificador",
							"timestamp": "Última modificación",
						},
					},
					"EntityAttributePermission": {
						"name": "Permiso de acceso a campo",
						"pluralName": "Permisos de acceso a campos",
						"a": {
							"role": "Rol de seguridad",
							"RoleViaRole": "Rol de seguridad",
							"attribute": "Campo",
							"EntityAttributeViaAttribute": "Campo",
							"types": "Tipos de acceso",
							"id": "Identificador",
							"timestamp": "Última modificación",
						},
					},
					"RowLevelPermission": {
						"name": "Permiso a nivel de fila",
						"pluralName": "Permisos a nivel de filas",
						"a": {
							"entity": "Formulario",
							"EntityViaEntity": "Formulario",
							"name": "Nombre del permiso",
							"as": "Tipo de política",
							"for": "Operación",
							"role": "Rol de seguridad",
							"RoleViaRole": "Rol de seguridad",
							"expression": "Expresión de filtrado",
							"checkExpression": "Expresión de comprobación",
							"id": "Identificador",
							"timestamp": "Última modificación",
						},
					},
					"ExternalEntityPermission": {
						"name": "Permiso de enlaces web",
						"pluralName": "Permisos de enlaces web",
						"a": {
							"role": "Rol de seguridad",
							"RoleViaRole": "Rol de seguridad",
							"externalEntity": "Enlace web",
							"ExternalEntityViaExternalEntity": "Enlace web",
							"id": "Identificador",
							"timestamp": "Última modificación",
						},
					},
					"User": {
						"name": "Usuario",
						"pluralName": "Usuarios",
						"tabs": {
							"1": "Parámetros principales",
							"2": "Llamadas telefónicas",
							"3": "Accesos al sistema",
							"4": "Autenticación en dos pasos",
						},
						"a": {
							"username": "Nombre de usuario",
							"password": "Contraseña",
							"email": "Email",
							"emailValidationCode": "Código de validación de Email",
							"passwordChangeValidationCode": "Código de validación de cambio de contraseña",
							"emailValidated": "El email está validado",
							"isAdmin": "El usuario tiene privilegios de Administrador",
							"agreementAccepted": "El usuario ha aceptado el acuerdo de suscripción",
							"phoneIpAddress": "Dirección IP del teléfono",
							"phonePassword": "Contraseña del teléfono",
							"phoneRedirectAttribute": "Atributo para la redirección de llamada",
							"enable2FA": "Habilitar autenticación en dos pasos",
							"twoFactorAuthenticationKey": "Clave de autenticación en dos pasos",
							"id": "Identificador",
							"timestamp": "Última modificación",
							"search": "Criterio de búsqueda",
						},
					},
					"UserRole": {
						"name": "Usuarios / Roles",
						"pluralName": "Usuarios / Roles",
						"a": {
							"role": "Rol de seguridad",
							"RoleViaRole": "Rol de seguridad",
							"user": "Usuario",
							"UserViaUser": "Usuario",
							"id": "Identificador",
							"timestamp": "Última modificación",
						},
					},
					"Function": {
						"name": "Función",
						"pluralName": "Funciones",
						"a": {
							"schema": "Nombre de la aplicación",
							"name": "Nombre de la función",
							"language": "Lenguaje",
							"contents": "Código de la función",
							"cronExpression": "Expresión CRON (ejecución planificada)",
							"isHttpEnabled": "Habilitar endpoint HTTP",
							"interceptHttpRequests": "Interceptar peticiones HTTP",
							"interceptSelects": "Interceptar consultas a la base de datos",
							"isCustomFunction": "Es una función personalizada con parámetros y tipo de respuesta personalizados",
							"customParameters": "Parametros personalizados (por ejemplo 'name text, age int')",
							"customReturnType": "Tipo de respuesta personalizada (por ejemplo 'boolean')",
							"documentation": "Documentación",
							"search": "Criterio de búsqueda",
							"id": "Identificador",
							"timestamp": "Última modificación",
						},
					},
					"EntityTrigger": {
						"name": "Acción",
						"pluralName": "Acciones",
						"a": {
							"container": "Formulario",
							"EntityViaContainer": "Formulario",
							"name": "Nombre de la acción",
							"moment": "Momento",
							"events": "Eventos",
							"each": "Granularidad",
							"function": "Función",
							"FunctionViaFunction": "Función",
							"condition": "Condición",
							"id": "Identificador",
							"timestamp": "Última modificación",
						},
					},
					"EntityCustomTrigger": {
						"name": "Acción personalizada",
						"pluralName": "Acciones personalizadas",
						"a": {
							"container": "Formulario",
							"EntityViaContainer": "Formulario",
							"name": "Nombre de la acción",
							"function": "Función",
							"FunctionViaFunction": "Función",
							"id": "Identificador",
							"timestamp": "Última modificación",
						},
					},
					"EntityCustomTriggerLabel": {
						"name": "Traducción",
						"pluralName": "Traducciones",
						"a": {
							"container": "Acción personalizadan",
							"EntityCustomTriggerViaContainer": "Acción personalizada",
							"locale": "Idioma",
							"label": "Nombre de la acción personalizada",
							"id": "Identificador",
							"timestamp": "Última modificación",
						},
					},
					"CustomTriggerPermission": {
						"name": "Permiso de acciones personalizadas",
						"pluralName": "Permisos de acciones personalizadas",
						"a": {
							"role": "Rol de seguridad",
							"RoleViaRole": "Rol de seguridad",
							"customTrigger": "Acción personalizada",
							"EntityCustomTriggerViaCustomTrigger": "Acción personalizada",
							"id": "Identificador",
							"timestamp": "Última modificación",
						},
					},
					"ExternalEntity": {
						"name": "Enlace web",
						"pluralName": "Enlaces web",
						"a": {
							"schema": "Nombre de la aplicación",
							"name": "Nombre del enlace web",
							"url": "Dirección (URL) del enlace web",
							"addAccessToken": "Añadir token de acceso a la dirección de destino",
							"order": "Número de orden",
							"menu": "Aparece en el menú",
							"icon": "Icono del menú",
							"documentation": "Documentación",
							"search": "Criterio de búsqueda",
							"id": "Identificador",
							"timestamp": "Última modificación",
						},
					},
					"WebRedirection": {
						"name": "Redirección web",
						"pluralName": "Redirecciones web",
						"a": {
							"sourcePath": "Ruta de origen (por ejemplo \"/s/a-b\")",
							"targetUrl": "Dirección de destino (por ejemplo \"https://www.acme.com\")",
							"permanent": "Redirección permanente",
							"id": "Identificador",
							"timestamp": "Última modificación",
						},
					},
					"ExternalEntityLabel": {
						"name": "Traducción",
						"pluralName": "Traducciones",
						"a": {
							"container": "Enlace web",
							"EntityViaContainer": "Enlace web",
							"locale": "Idioma",
							"label": "Nombre del enlace web",
							"pluralLabel": "Nombre en plural del enlace web",
							"id": "Identificador",
							"timestamp": "Última modificación",
						},
					},
					"WebPackage": {
						"name": "Paquete Web",
						"pluralName": "Paquetes web",
						"a": {
							"name": "Nombre del paquete de recursos web",
							"type": "Tipo de paquete",
							"package": "Paquete de recursos web (en formato ZIP)",
							"id": "Identificador",
							"timestamp": "Última modificación",
						},
					},
					"Personalization": {
						"name": "Configuración",
						"pluralName": "Configuración",
						"tabs": {
							"1": "Parámetros principales",
							"2": "Interfaz de usuario",
							"3": "Correo electrónico",
							"4": "Registro de usuarios",
						},
						"a": {
							"name": "Nombre del perfil",
							"primaryColor": "Color principal",
							"secondaryColor": "Color secundario",
							"supportedLanguages": "Idiomas soportados",
							"customerLogo": "Logotipo personalizado",
							"documentWatermark": "Marca de agua para documentos",
							"enabled": "Personalización activada",
							"smtpHost": "Dirección del servidor de correo (SMTP)",
							"smtpPort": "Puerto del servidor de correo (SMTP)",
							"smtpAuth": "Autenticación requerida (SMTP)",
							"smtpUsername": "Nombre de usuario (SMTP)",
							"smtpPassword": "Contraseña (SMTP)",
							"enableUserRegistration": "Habilitar registro de usuarios",
							"defaultUserRole": "Rol predeterminado",
							"enableCaptcha": "Habilitar CAPTCHA",
							"showLogoInMenu": "Mostrar logo en el menú",
							"showLogoInHeader": "Mostrar logo en la cabecera",
							"id": "Identificador",
							"timestamp": "Última modificación",
						},
					},
					"PersonalizationTitle": {
						"name": "Traducción del título de la aplicación",
						"pluralName": "Traducciones del título de la aplicación",
						"a": {
							"container": "Personalización",
							"PersonalizationViaContainer": "Personalización",
							"locale": "Idioma",
							"title": "Título de la aplicación",
							"id": "Identificador",
							"timestamp": "Última modificación",
						},
					},
					"Report": {
						"name": "Informe",
						"pluralName": "Informes",
						"a": {
							"name": "Nombre de la plantilla",
							"template": "Plantilla",
							"id": "Identificador",
							"timestamp": "Última modificación",
						},
					},
					"ReportTrigger": {
						"name": "Generador de informes",
						"pluralName": "Generadores de informes",
						"a": {
							"container": "Plantilla de informe",
							"ReportViaContainer": "Plantilla de informe",
							"name": "Nombre del generador",
							"triggerEntity": "Formulario que acciona el generador",
							"EntityViaTriggerEntity": "Formulario que acciona el generador",
							"graphql": "Consulta GraphQL",
							"targetAttribute": "Atributo de destino donde se almacenará el informe generado",
							"EntityAttributeViaTargetAttribute": "Atributo de destino donde se almacenará el informe generado",
							"targetEntityExpression": "Expresión para calcular el identificador del formulario de destino",
							"id": "Identificador",
							"timestamp": "Última modificación",
						},
					},
					"NetworkPermission": {
						"name": "Permiso de red",
						"pluralName": "Permisos de red",
						"a": {
							"role": "Rol de seguridad",
							"RoleViaRole": "Rol de seguridad",
							"accessType": "Tipo de acceso",
							"order": "Orden de la regla",
							"cidr": "CIDR permitido",
							"ip": "Dirección IP permitida",
							"mask": "Máscara de subred",
							"id": "Identificador",
							"timestamp": "Última modificación",
						},
					},
					"SchemaPermission": {
						"name": "Permiso de aplicaciones",
						"pluralName": "Permisos de aplicaciones",
						"a": {
							"role": "Rol de seguridad",
							"RoleViaRole": "Rol de seguridad",
							"schema": "Aplicación a la que se permite el acceso",
							"id": "Identificador",
							"timestamp": "Última modificación",
						},
					},
					"UserAccess": {
						"name": "Acceso al sistema",
						"pluralName": "Accesos al sistema",
						"a": {
							"user": "Usuario",
							"UserViaUser": "Usuario",
							"ip": "Dirección IP del usuario",
							"id": "Identificador",
							"timestamp": "Fecha y hora del acceso",
						},
					},
					"ErrorMessage": {
						"name": "Mensaje de error",
						"pluralName": "Mensajes de error",
						"a": {
							"name": "Nombre del mensaje de error",
							"regularExpression": "Expresión regular que encaja con el mensaje de error",
							"documentation": "Documentación",
							"search": "Criterio de búsqueda",
							"id": "Identificador",
						},
					},
					"ErrorMessageLabel": {
						"name": "Traducción",
						"pluralName": "Traducciones",
						"a": {
							"container": "Mensaje de error",
							"ErrorMessageViaContainer": "Mensaje de error",
							"locale": "Idioma",
							"message": "Plantilla del mensaje",
							"id": "Identificador",
						},
					},
				},
			},
		},
	},
	af: { // Afrikaans
		translation: {
			"title": "Data Bestuur",

			"accessControl": "Toegangsbeheer",
			"username": "Gebruikersnaam",
			"password": "Wagwoord",
			"language": "Taal",
			"login": "Toegang",
			"incorrectUsernameOrPassword": "Onjuiste gebruikersnaam en / of wagwoord",

			"primaryKeyWithOnlyOneAttributeNeeded": "Die primêre sleutel kan net een kenmerk hê",

			"invalidValue": "Die ingevoerde waarde is nie geldig nie",

			"selected": "geselekteerde",

			"deleteConfirmation": "Bevestiging",
			"deleteQuestion": "Delete",

			"cancel": "Kanselleer",
			"save": "Save",

			"clear": "Delete",

			"with": "met",

			"latitude": "Latitude",
			"longitude": "Lengte",

			"tableView": "Table view",
			"calendarView": "Calendar view",
			"mapView": "Kaartbeskouing",

			"calendarCulture": "is",
			"calendarDate": "Date",
			"calendarTime": "Time",
			"calendarEvent": "Event",
			"calendarAllDay": "Die hele dag",
			"calendarWeek": "Week",
			"calendarWorkWeek": "Werkweek",
			"calendarDay": "Dag",
			"calendarMonth": "Maand",
			"calendarPrevious": "Vorige",
			"calendarNext": "Next",
			"calendarYesterday": "Gister",
			"calendarTomorrow": "Tomorrow",
			"calendarToday": "Vandag",
			"calendarAgenda": "Agenda",

			"view": "Sien",
			"edit": "Edit",
			"delete": "Delete",
			"new": "Voeg by",
			"newRecord": "New",

			"noOptions": "Geen opsies nie",
			"loading": "Loading ...",
			"selectValue": "",

			"noData": "Geen resultate gevind nie",

			"enableBasicFilters": "Wys basiese filters",
			"disableBasicFilters": "Versteek basiese filters",

			"search": "Soek",
			"soekkriteria": "Soek kriteria",

			"deleteSuccess": "item (s) suksesvol verwyder",

			"virtualAnalyst": "Indicators",

			"closeSession": "Teken uit",

			"searchFilter": "Soek filter",

			"adminGroup": "Admin",
			"admin": "Administratiewe take",
			"backupBackup": "Back-up",
			"downloadBackup": "Voer en laai rugsteun af",

			"deleteDatabase": "Verwyder databasis",
			"deleteRestoreToDefault": "Verwyder databasis en herstel na fabriekswaardes",
			"deleteDatabaseConfirmation": "Deletion confirmation",
			"deleteDatabaseQuestion": "Jy gaan al die data uitvee, is jy seker?",
			"deleteDatabaseSuccess": "Die stelsel is suksesvol herstel na die fabrieksinstellings",

			"backupRestore": "Restore Friends",
			"uploadBackup": "Sleep of kies die rugsteunlêer wat jy wil herstel",
			"restoreConfirmation": "herstel bevestiging",
			"restoreQuestion": "Jy gaan 'n rugsteun van die stelsel herstel, al die data sal uitgevee word, is jy seker?",
			"restore": "Herstel",
			"restoreSuccess": "Die rugsteun is suksesvol herstel",
			"restoreOnlyOneFile": "Kies asseblief net een lêer"
		},
	},
	am: { // Amharic
		translation: {
			"title": "የውሂብ አስተዳደር",

			"accessControl": "መዳረሻ ቁጥጥር",
			"username": "የተጠቃሚ ስም",
			"password": "የይለፍ ቃል",
			"language": "ቋንቋ",
			"login": "መግቢያ",
			"incorrectUsernameOrPassword": "የተጠቃሚ መታወቂያ እና / ወይም የይለፍ ቃል ትክክል አይደለም",

			"primaryKeyWithOnlyOneAttributeNeeded": "ዋነኛው ቁልፍ ብቻ አንድ መገለጫ ባህሪ ሊኖረው አይችልም",

			"invalidValue": "ያስገቡት ዋጋ ልክ ያልሆነ ነው",

			"selected": "ዎች",

			"deleteConfirmation": "ማረጋገጫ",
			"deleteQuestion": "ሰርዝ",

			"cancel": "ይቅር",
			"save": "አስቀምጥ",

			"clear": "ሰርዝ",

			"with": "ጋር",

			"latitude": "ኬክሮስ",
			"longitude": "ርዝመት",

			"tableView": "ሠንጠረዥ ይመልከቱ",
			"calendarView": "የቀን መቁጠሪያ ይመልከቱ",
			"mapView": "ካርታ እይታ",

			"calendarCulture": "ነው",
			"calendarDate": "ቀን",
			"calendarTime": "ሰዓት",
			"calendarEvent": "ክስተት",
			"calendarAllDay": "ሁሉም ቀን",
			"calendarWeek": "ሳምንት",
			"calendarWorkWeek": "የሥራ ሳምንት",
			"calendarDay": "ቀን",
			"calendarMonth": "ወር",
			"calendarPrevious": "ቀዳሚ",
			"calendarNext": "ቀጥሎ",
			"calendarYesterday": "ትላንትና",
			"calendarTomorrow": "ነገ",
			"calendarToday": "ዛሬ",
			"calendarAgenda": "አጀንዳ",

			"view": "ዕይታ",
			"edit": "አርትዕ",
			"delete": "ሰርዝ",
			"new": "አክል",
			"newRecord": "አዲስ",

			"noOptions": "ምንም አማራጮች",
			"loading": "በመጫን ላይ ...",
			"selectValue": "",

			"noData": "ምንም ውጤቶች አልተገኙም",

			"enableBasicFilters": "አሳይ መሠረታዊ ማጣሪያዎች",
			"disableBasicFilters": "ደብቅ መሠረታዊ ማጣሪያዎች",

			"search": "ፈልግ",
			"searchCriteria": "የፍለጋ መስፈርት",

			"deleteSuccess": "በትክክል አባል (ዎች) ተወግዷል (ዎች)",

			"virtualAnalyst": "መላካቾች",

			"closeSession": "ውጣ",

			"searchFilter": "ማጣሪያ ፈልግ",

			"adminGroup": "የአስተዳዳሪ",
			"admin": "አስተዳደራዊ ተግባሮች",
			"backupBackup": "መጠባበቂያ",
			"downloadBackup": "ማከናወን ምትኬ እና ማውረድ",

			"deleteDatabase": "ጎታ ሰርዝ",
			"deleteRestoreToDefault": "የውሂብ ጎታ ሰርዝ እና የፋብሪካ ቅንብሮችን እነበረበት መልስ",
			"deleteDatabaseConfirmation": "ስረዛን አረጋግጥ",
			"deleteDatabaseQuestion": "አንተ እርግጠኛ ነህ, ሁሉንም ውሂብ ይሰርዛል?",
			"deleteDatabaseSuccess": "ስርዓቱ በተሳካ ሁኔታ ወደ ፋብሪካ ቅንብሮች, ተመልሷል",

			"backupRestore": "ምትኬ እነበረበት መልስ",
			"uploadBackup": "ይጎትቱ ወይም እነበረበት የምንፈልጋቸውን የመጠባበቂያ ቅጂ ፋይል ምረጥ",
			"restoreConfirmation": "የማረጋገጫ እነበረበት መልስ",
			"restoreQuestion": "አንተ መጠባበቂያ ሥርዓት ለመመለስ ይሄዳሉ, ሁሉንም ውሂብ እርግጠኛ ነዎት ይደመሰሳል?",
			"restore": "እነበረበት መልስ",
			"restoreSuccess": "የመጠባበቂያ በተሳካ ሁኔታ ተመልሷል",
			"restoreOnlyOneFile": "ብቻ አንድ ፋይል ይምረጡ",
		},
	},
	bg: { // Bulgarian
		translation: {
			"title": "Управление на данни",

			"accessControl": "Контрол на достъпа",
			"username": "Потребителско име",
			"password": "Парола",
			"language": "Език",
			"login": "Достъп",
			"incorrectUsernameOrPassword": "Неправилно потребителско име и / или парола",

			"primaryKeyWithOnlyOneAttributeNeeded": "Първичният ключ може да има само един атрибут",

			"invalidValue": "Въведената стойност не е валидна",

			"selected": "избрано",

			"deleteConfirmation": "Потвърждение",
			"deleteQuestion": "Изтриване",

			"cancel": "Отказ",
			"save": "Запазване",

			"clear": "Изтриване",

			"with": "с",

			"latitude": "Latitude",
			"longitude": "Дължина",

			"tableView": "Табличен изглед",
			"calendarView": "Календарен изглед",
			"mapView": "Изглед на картата",

			"calendarCulture": "е",
			"calendarDate": "Дата",
			"calendarTime": "Време",
			"calendarEvent": "Събитие",
			"calendarAllDay": "Целият ден",
			"calendarWeek": "Седмица",
			"calendarWorkWeek": "Работна седмица",
			"calendarDay": "Ден",
			"calendarMonth": "Месец",
			"calendarPrevious": "Предишен",
			"calendarNext": "Следващ",
			"calendarYesterday": "Вчера",
			"calendarTomorrow": "Утре",
			"calendarToday": "Днес",
			"calendarAgenda": "Дневен ред",

			"view": "Вижте",
			"edit": "Редактиране",
			"delete": "Изтриване",
			"new": "Добавяне",
			"newRecord": "Ново",

			"noOptions": "Няма опции",
			" loading": "Зарежда се ...",
			"selectValue": "",

			"noData": "Няма намерени резултати",

			"enableBasicFilters": "Показване на основните филтри",
			"disableBasicFilters": "Скриване на основните филтри",

			"search": "Търсене",
			"searchCriteria": "Критерии за търсене",

			"deleteSuccess": "елементът (ите) е премахнат успешно",
			
			"VirtualAnalyst": "Индикатори",

			"closeSession": "Изход",

			"searchFilter": "Филтър за търсене",

			"adminGroup": "Администратор",
			"admin": "Административни задачи",
			"backupBackup": "Архивиране",
			"downloadBackup": "Извършване и изтегляне на резервно копие",

			"deleteDatabase": "Изтриване на база данни",
			"deleteRestoreToDefault": "Изтриване на база данни и възстановяване на фабричните стойности",
			"deleteDatabaseConfirmation": "Потвърждение за изтриване",
			"deleteDatabaseQuestion": "Ще изтриете всички данни, сигурни ли сте?",
			"deleteDatabaseSuccess": "Системата е възстановена успешно до фабричните настройки",

			"backupRestore": "Възстановяване на архив",
			"uploadBackup": "Плъзнете или изберете файла за архивиране, който искате да възстановите",
			"restoreConfirmation": "Потвърждение за възстановяване",
			"restoreQuestion": "Ще възстановите резервно копие на системата, всички данни ще бъдат изтрити, сигурни ли сте?",
			"restore": "Възстановяване",
			"restoreSuccess": "Архивирането е възстановено успешно",
			"restoreOnlyOneFile": "Моля, изберете само един файл"
		},
	},
	ca: { // Catalan
		translation: {
			"title": "Administració de Dades",

			"accessControl": "Control d'accés",
			"username": "Nom d'usuari",
			"password": "Contrasenya",
			"language": "Idioma",
			"login": "Accedir",
			"incorrectUsernameOrPassword": "Usuari i / o contrasenya incorrectes",

			"primaryKeyWithOnlyOneAttributeNeeded": "La clau primària només pot tenir un atribut",

			"invalidValue": "El valor introduït no és vàlid",

			"selected": "seleccionat",

			"deleteConfirmation": "Confirmació",
			"deleteQuestion": "Vols suprimir",

			"cancel": "Cancel",
			"save": "Desa",

			"clear": "Esborrar",

			"with": "amb",

			"latitude": "Latitud",
			"longitude": "Longitud",

			"tableView": "Vista de taula",
			"calendarView": "Vista de calendari",
			"mapView": "Vista del mapa",

			"calendarCulture": "és",
			"calendarDate": "Data",
			"calendarTime": "Hora",
			"calendarEvent": "Esdeveniment",
			"calendarAllDay": "Tot el dia",
			"calendarWeek": "Setmana",
			"calendarWorkWeek": "Setmana laboral",
			"calendarDay": "Dia",
			"calendarMonth": "Mes",
			"calendarPrevious": "Anterior",
			"calendarNext": "Següent",
			"calendarYesterday": "Ahir",
			"calendarTomorrow": "Demà",
			"calendarToday": "Avui",
			"calendarAgenda": "Agenda",

			"view": "Veure",
			"edit": "Edita",
			"delete": "Eliminar",
			"new": "Afegeix",
			"newRecord": "Nou",

			"noOptions": "No hi ha opcions",
			"loading": "S'està carregant ...",
			"selectValue": "",

			"noData": "No s'han trobat resultats",

			"enableBasicFilters": "Mostra filtres bàsics",
			"disableBasicFilters": "Amaga filtres bàsics",

			"search": "Cerca",
			"searchCriteria": "Criteris de cerca",

			"deleteSuccess": "element (s) eliminat (s) correctament",

			"virtualAnalyst": "Indicadors",

			"closeSession": "Tancar sessió",

			"searchFilter": "Filtre de cerca",

			"adminGroup": "Admin",
			"admin": "Tasques administratives",
			"backupBackup": "Fer còpia de seguretat",
			"downloadBackup": "Realitzar i descarregar còpia de seguretat",

			"deleteDatabase": "Eliminar base de dades",
			"deleteRestoreToDefault": "Eliminar base de dades i restaurar a valors de fàbrica",
			"deleteDatabaseConfirmation": "Confirmació d'esborrat",
			"deleteDatabaseQuestion": "aneu a esborrar totes les dades, Estàs segur?",
			"deleteDatabaseSuccess": "El sistema s'ha restaurat correctament als valors de fàbrica",

			"backupRestore": "Restaurar còpia de seguretat",
			"uploadBackup": "Arrossega o selecciona l'arxiu de còpia de seguretat que vols restaurar",
			"restoreConfirmation": "Confirmació de restauració",
			"restoreQuestion": "aneu a restaurar una còpia de seguretat del sistema, s'esborraran totes les dades, Estàs segur?",
			"restore": "Restaurar",
			"restoreSuccess": "La còpia de seguretat s'ha restaurat correctament",
			"restoreOnlyOneFile": "Si us plau, seleccioni només un arxiu"
		},
	},
	zh_HK: { // Chinese (Hong Kong)
		translation: {
			"title": "數據管理",

			"accessControl": "訪問控制",
			"username": "用戶名",
			"password": "密碼",
			"language": "語言",
			"login": "訪問",
			"incorrectUsernameOrPassword": "用戶名和/或密碼不正確",

			"primaryKeyWithOnlyOneAttributeNeeded": "主鍵只能有一個屬性",

			"invalidValue": "輸入的值無效",

			"selected": "已選中",

			"deleteConfirmation": "確認",
			"deleteQuestion": "刪除",

			"cancel": "取消",
			"save": "保存",

			"clear": "刪除",

			"with": "with",

			"latitude": "緯度",
			"longitude": "長度",

			"tableView": "表視圖",
			"calendarView": "日曆視圖",
			"mapView": "地圖視圖",

			"calendarCulture": "是",
			"calendarDate": "日期",
			"calendarTime": "時間",
			"calendarEvent": "事件",
			"calendarAllDay": "整天",
			"calendarWeek": "週",
			"calendarWorkWeek": "工作週",
			"calendarDay": "日",
			"calendarMonth": "月",
			"calendarPrevious": "上一個",
			"calendarNext": "下一步",
			"calendarY yesterday": "昨天",
			"calendarTomorrow": "明天",
			"calendarToday": "今天",
			"calendarAgenda": "議程",

			"view": "看",
			"edit": "編輯",
			"delete": "刪除",
			"new": "添加",
			"newRecord": "新",

			"noOptions": "沒有選項",
			"loading": "正在加載...",
			"selectValue": "",

			"noData": "找不到結果",

			"enableBasicFilters": "顯示基本過濾器",
			"disableBasicFilters": "隱藏基本過濾器",

			"search": "搜索",
			"searchCriteria": "搜索條件",

			"deleteSuccess": "項目已成功刪除",

			"virtualAnalyst": "指標",

			"closeSession": "退出",

			"searchFilter": "搜索過濾器",

			"adminGroup": "管理員",
			"admin": "管理任務",
			"backupBackup": "備份",
			"downloadBackup": "執行和下載備份",

			"deleteDatabase": "刪除數據庫",
			"deleteRestoreToDefault": "刪除數據庫並恢復為出廠值",
			"deleteDatabaseConfirmation": "刪除確認",
			"deleteDatabaseQuestion": "你會刪除所有數據,你確定嗎?",
			"deleteDatabaseSuccess": "系統已成功恢復為出廠設置",

			"backupRestore": "還原備份",
			"uploadBackup": "拖動或選擇要恢復的備份文件",
			"restoreConfirmation": "恢復確認",
			"restoreQuestion": "你要恢復一個備份系統,所有數據將被刪除,確定嗎?",
			"restore": "恢復",
			"restoreSuccess": "備份已成功恢復",
			"restoreOnlyOneFile": "請只選擇一個文件",
		},
	},
	zh_CN: { // Chinese (PRC)
		translation: {
			"title": "數據管理",

			"accessControl": "訪問控制",
			"username": "用戶名",
			"password": "密碼",
			"language": "語言",
			"login": "訪問",
			"incorrectUsernameOrPassword": "用戶名和/或密碼不正確",

			"primaryKeyWithOnlyOneAttributeNeeded": "主鍵只能有一個屬性",

			"invalidValue": "輸入的值無效",

			"selected": "已選中",

			"deleteConfirmation": "確認",
			"deleteQuestion": "刪除",

			"cancel": "取消",
			"save": "保存",

			"clear": "刪除",

			"with": "with",

			"latitude": "緯度",
			"longitude": "長度",

			"tableView": "表視圖",
			"calendarView": "日曆視圖",
			"mapView": "地圖視圖",

			"calendarCulture": "是",
			"calendarDate": "日期",
			"calendarTime": "時間",
			"calendarEvent": "事件",
			"calendarAllDay": "整天",
			"calendarWeek": "週",
			"calendarWorkWeek": "工作週",
			"calendarDay": "日",
			"calendarMonth": "月",
			"calendarPrevious": "上一個",
			"calendarNext": "下一步",
			"calendarY yesterday": "昨天",
			"calendarTomorrow": "明天",
			"calendarToday": "今天",
			"calendarAgenda": "議程",

			"view": "看",
			"edit": "編輯",
			"delete": "刪除",
			"new": "添加",
			"newRecord": "新",

			"noOptions": "沒有選項",
			"loading": "正在加載...",
			"selectValue": "",

			"noData": "找不到結果",

			"enableBasicFilters": "顯示基本過濾器",
			"disableBasicFilters": "隱藏基本過濾器",

			"search": "搜索",
			"searchCriteria": "搜索條件",

			"deleteSuccess": "項目已成功刪除",

			"virtualAnalyst": "指標",

			"closeSession": "退出",

			"searchFilter": "搜索過濾器",

			"adminGroup": "管理員",
			"admin": "管理任務",
			"backupBackup": "備份",
			"downloadBackup": "執行和下載備份",

			"deleteDatabase": "刪除數據庫",
			"deleteRestoreToDefault": "刪除數據庫並恢復為出廠值",
			"deleteDatabaseConfirmation": "刪除確認",
			"deleteDatabaseQuestion": "你會刪除所有數據,你確定嗎?",
			"deleteDatabaseSuccess": "系統已成功恢復為出廠設置",

			"backupRestore": "還原備份",
			"uploadBackup": "拖動或選擇要恢復的備份文件",
			"restoreConfirmation": "恢復確認",
			"restoreQuestion": "你要恢復一個備份系統,所有數據將被刪除,確定嗎?",
			"restore": "恢復",
			"restoreSuccess": "備份已成功恢復",
			"restoreOnlyOneFile": "請只選擇一個文件",
		},
	},
	zh_TW: { // Chinese (Taiwan)
		translation: {
			"title": "數據管理",

			"accessControl": "訪問控制",
			"username": "用戶名",
			"password": "密碼",
			"language": "語言",
			"login": "訪問",
			"incorrectUsernameOrPassword": "用戶名和/或密碼不正確",

			"primaryKeyWithOnlyOneAttributeNeeded": "主鍵只能有一個屬性",

			"invalidValue": "輸入的值無效",

			"selected": "已選中",

			"deleteConfirmation": "確認",
			"deleteQuestion": "刪除",

			"cancel": "取消",
			"save": "保存",

			"clear": "刪除",

			"with": "with",

			"latitude": "緯度",
			"longitude": "長度",

			"tableView": "表視圖",
			"calendarView": "日曆視圖",
			"mapView": "地圖視圖",

			"calendarCulture": "是",
			"calendarDate": "日期",
			"calendarTime": "時間",
			"calendarEvent": "事件",
			"calendarAllDay": "整天",
			"calendarWeek": "週",
			"calendarWorkWeek": "工作週",
			"calendarDay": "日",
			"calendarMonth": "月",
			"calendarPrevious": "上一個",
			"calendarNext": "下一步",
			"calendarY yesterday": "昨天",
			"calendarTomorrow": "明天",
			"calendarToday": "今天",
			"calendarAgenda": "議程",

			"view": "看",
			"edit": "編輯",
			"delete": "刪除",
			"new": "添加",
			"newRecord": "新",

			"noOptions": "沒有選項",
			"loading": "正在加載...",
			"selectValue": "",

			"noData": "找不到結果",

			"enableBasicFilters": "顯示基本過濾器",
			"disableBasicFilters": "隱藏基本過濾器",

			"search": "搜索",
			"searchCriteria": "搜索條件",

			"deleteSuccess": "項目已成功刪除",

			"virtualAnalyst": "指標",

			"closeSession": "退出",

			"searchFilter": "搜索過濾器",

			"adminGroup": "管理員",
			"admin": "管理任務",
			"backupBackup": "備份",
			"downloadBackup": "執行和下載備份",

			"deleteDatabase": "刪除數據庫",
			"deleteRestoreToDefault": "刪除數據庫並恢復為出廠值",
			"deleteDatabaseConfirmation": "刪除確認",
			"deleteDatabaseQuestion": "你會刪除所有數據,你確定嗎?",
			"deleteDatabaseSuccess": "系統已成功恢復為出廠設置",

			"backupRestore": "還原備份",
			"uploadBackup": "拖動或選擇要恢復的備份文件",
			"restoreConfirmation": "恢復確認",
			"restoreQuestion": "你要恢復一個備份系統,所有數據將被刪除,確定嗎?",
			"restore": "恢復",
			"restoreSuccess": "備份已成功恢復",
			"restoreOnlyOneFile": "請只選擇一個文件",
		},
	},
	hr: { // Croatian
		translation: {
			"title": "Upravljanje podacima",

			"accessControl": "Kontrola pristupa",
			"username": "korisničko ime",
			"password": "Zaporka",
			"language": "Jezik",
			"login": "Pristup",
			"incorrectUsernameOrPassword": "Netočno korisničko ime i / ili zaporka",

			"primaryKeyWithOnlyOneAttributeNeeded": "Primarni ključ može imati samo jedan atribut",

			"invalidValue": "Unesena vrijednost nije važeća",

			"selected": "odabrano",

			"deleteConfirmation": "Potvrda",
			"deleteQuestion": "Izbriši",

			"cancel": "Odustani",
			"save": "Spremi",

			"clear": "Izbriši",

			"with": "s",

			"latitude": "Latitude",
			"longitude": "Duljina",

			"tableView": "Prikaz tablice",
			"calendarView": "Prikaz kalendara",
			"mapView": "Prikaz karte",

			"calendarCulture": "je",
			"calendarDate": "Datum",
			"calendarTime": "Vrijeme",
			"calendarEvent": "Događaj",
			"calendarAllDay": "Cijeli dan",
			"calendarWeek": "Tjedan",
			"calendarWorkWeek": "Radni tjedan",
			"calendarDay": "Dan",
			"calendarMonth": "mjesec",
			"calendarPrevious": "Prethodno",
			"calendarNext": "Dalje",
			"calendarJučer": "Jučer",
			"calendarTomorrow": "Sutra",
			"calendarToday": "Danas",
			"calendarAgenda": "Dnevni red",

			"view": "Pogledajte",
			"edit": "Uredi",
			"delete": "Izbriši",
			"new": "Dodaj",
			"newRecord": "Novo",

			"noOptions": "Nema opcija",
			"loading": "Učitavanje ...",
			"selectValue": "",

			"noData": "Nema rezultata",

			"enableBasicFilters": "Prikaži osnovne filtre",
			"disableBasicFilters": "Sakrij osnovne filtre",

			"search": "Traži",
			"searchCriteria": "Kriteriji pretraživanja",

			"deleteSuccess": "stavka je uspješno uklonjena",

			"virtualAnalyst": "Pokazatelji",

			"closeSession": "Odjava",

			"searchFilter": "Filter pretraživanja",

			"adminGroup": "Administrator",
			"admin": "Administrativni zadaci",
			"backupBackup": "Sigurnosno kopiranje",
			"downloadBackup": "Izvršite i preuzmite sigurnosnu kopiju",

			"deleteDatabase": "Izbriši bazu podataka",
			"deleteRestoreToDefault": "Brisanje baze podataka i vraćanje na tvorničke vrijednosti",
			"deleteDatabaseConfirmation": "Potvrda brisanja",
			"deleteDatabaseQuestion": "Vi ćete izbrisati sve podatke, jeste li sigurni?",
			"deleteDatabaseSuccess": "Sustav je uspješno vraćen na tvorničke postavke",

			"backupRestore": "Vraćanje sigurnosne kopije",
			"uploadBackup": "Povucite ili odaberite datoteku sigurnosne kopije koju želite vratiti",
			"restorePotvrda": "Potvrda obnove",
			"restoreQuestion": "Vratit ćete sigurnosnu kopiju sustava, svi podaci će biti izbrisani, jeste li sigurni?",
			"restore": "Vraćanje",
			"restoreSuccess": "Sigurnosna kopija uspješno je vraćena",
			"restoreOnlyOneFile": "Odaberite samo jednu datoteku"		
		},
	},
	cs: { // Czech
		translation: {
			"title": "Správa dat",

			"accessControl": "Řízení přístupu",
			"username": "Uživatelské jméno",
			"password": "Heslo",
			"language": "Jazyk",
			"login": "Přístup",
			"incorrectUsernameOrPassword": "Nesprávné uživatelské jméno a / nebo heslo",

			"primaryKeyWithOnlyOneAttributeNeeded": "Primární klíč může mít pouze jeden atribut",

			"invalidValue": "Zadaná hodnota není platná",

			"selected": "selected",

			"deleteConfirmation": "Potvrzení",
			"deleteQuestion": "Smazat",

			"cancel": "Zrušit",
			"save": "Uložit",

			"clear": "Smazat",

			"with": "s",

			"latitude": "Latitude",
			"longitude": "Délka",

			"tableView": "Zobrazení tabulky",
			"calendarView": "Zobrazení kalendáře",
			"mapView": "Zobrazení mapy",

			"calendarCulture": "is",
			"calendarDate": "Datum",
			"calendarTime": "Čas",
			"calendarEvent": "Událost",
			"calendarAllDay": "Celý den",
			"calendarWeek": "Týden",
			"calendarWorkWeek": "Pracovní týden",
			"calendarDay": "Den",
			"calendarMonth": "Měsíc",
			"calendarPrevious": "Předchozí",
			"calendarNext": "Další",
			"calendarYesterday": "Včera",
			"calendarTomorrow": "Zítra",
			"calendarToday": "Dnes",
			"calendarAgenda": "Agenda",

			"view": "See",
			"edit": "Upravit",
			"delete": "Smazat",
			"new": "Přidat",
			"newRecord": "Nový",

			"noOptions": "Žádné možnosti",
			"loading": "Načítání ...",
			"selectValue": "",

			"noData": "Nebyly nalezeny žádné výsledky",

			"enableBasicFilters": "Zobrazit základní filtry",
			"disableBasicFilters": "Skrýt základní filtry",

			"search": "Hledat",
			"searchCriteria": "Kritéria vyhledávání",

			"deleteSuccess": "položky byly úspěšně odebrány",

			"virtualAnalyst": "Indikátory",

			"closeSession": "Odhlásit se",

			"searchFilter": "Vyhledávací filtr",

			"adminGroup": "Admin",
			"admin": "Administrativní úkoly",
			"backupBackup": "Zálohovat",
			"downloadBackup": "Provést a stáhnout zálohu",

			"deleteDatabase": "Smazat databázi",
			"deleteRestoreToDefault": "Smazat databázi a obnovit tovární hodnoty",
			"deleteDatabaseConfirmation": "Potvrzení výmazu",
			"deleteDatabaseQuestion": "Chystáte se smazat všechna data, že jo?",
			"deleteDatabaseSuccess": "Systém byl úspěšně obnoven na tovární nastavení",

			"backupRestore": "Obnovit zálohu",
			"uploadBackup": "Přetáhněte nebo vyberte záložní soubor, který chcete obnovit",
			"restoreConfirmation": "Potvrzení obnovení",
			"restoreQuestion": "Chystáte se obnovit zálohu systému, všechna data budou smazána, jste si jisti?",
			"restore": "Obnovit",
			"restoreSuccess": "Záloha byla úspěšně obnovena",
			"restoreOnlyOneFile": "Vyberte pouze jeden soubor"		
		},
	},
	da: { // Danish
		translation: {
			"title": "Data Management",

			"accessControl": "Adgangskontrol",
			"username": "Brugernavn",
			"password": "Password",
			"language": "Language",
			"login": "Adgang",
			"incorrectUsernameOrPassword": "Forkert brugernavn og / eller adgangskode",

			"primaryKeyWithOnlyOneAttributeNeeded": "Den primære nøgle kan kun have en attribut",

			"invalidValue": "Den indtastede værdi er ikke gyldig",

			"selected": "valgt",

			"deleteConfirmation": "Bekræftelse",
			"deleteQuestion": "Slet",

			"cancel": "Cancel",
			"save": "Gem",

			"clear": "Slet",

			"with": "med",

			"latitude": "Latitude",
			"longitude": "Length",

			"tableView": "Table view",
			"calendarView": "Kalendervisning",
			"mapView": "Kortvisning",

			"calendarCulture": "er",
			"calendarDate": "Date",
			"calendarTime": "Time",
			"calendarEvent": "Event",
			"calendarAllDay": "hele dagen",
			"calendarWeek": "Week",
			"calendarWorkWeek": "Arbejdsuge",
			"calendarDay": "Day",
			"calendarMonth": "Month",
			"calendarPrevious": "Previous",
			"calendarNext": "Next",
			"calendarYesterday": "I går",
			"calendarTomorrow": "Tomorrow",
			"calendarToday": "I dag",
			"calendarAgenda": "Agenda",

			"view": "Se",
			"edit": "Rediger",
			"delete": "Slet",
			"new": "Tilføj",
			"newRecord": "Ny",

			"noOptions": "Ingen valgmuligheder",
			"loading": "Indlæser ...",
			"selectValue": "",

			"noData": "Ingen resultater fundet",

			"enableBasicFilters": "Vis grundlæggende filtre",
			"disableBasicFilters": "Skjul grundlæggende filtre",

			"search": "Søg",
			"searchCriteria": "Søgekriterier",

			"deleteSuccess": "element (er) med succes fjernet",

			"virtualAnalyst": "Indikatorer",

			"closeSession": "Log ud",

			"searchFilter": "Søg filter",

			"adminGroup": "Admin",
			"admin": "Administrative opgaver",
			"backupBackup": "Back up",
			"downloadBackup": "Udfør og download backup",

			"deleteDatabase": "Slet database",
			"deleteRestoreToDefault": "Slet database og gendan til fabriksværdier",
			"deleteDatabaseConfirmation": "Sletning bekræftelse",
			"deleteDatabaseQuestion": "Du skal slette alle dataene, er du sikker?",
			"deleteDatabaseSuccess": "Systemet er blevet genoprettet til fabriksindstillingerne",

			"backupRestore": "Gendan backup",
			"uploadBackup": "Træk eller vælg den sikkerhedskopieringsfil, du vil gendanne",
			"restoreConfirmation": "Restorationsbekræftelse",
			"restoreQuestion": "Du vil gendanne en sikkerhedskopi af systemet, alle data vil blive slettet, er du sikker?",
			"restore": "Gendan",
			"restoreSuccess": "Sikkerheden blev genoprettet med succes",
			"restoreOnlyOneFile": "Vælg kun en fil"		
		},
	},
	nl: { // Dutch
		translation: {
		},
	},
	et: { // Estonian
		translation: {
			"title": "andmehaldus",

			"accessControl": "Juurdepääsu kontroll",
			"username": "kasutajanimi",
			"password": "parool",
			"language": "keel",
			"login": "juurdepääs",
			"incorrectUsernameOrPassword": "Vale kasutajanimi ja / või parool",

			"primaryKeyWithOnlyOneAttributeNeeded": "Esmane võti võib omada ainult ühte atribuuti",

			"invalidValue": "Sisestatud väärtus ei kehti",

			"selected": "valitud",

			"deleteConfirmation": "Kinnitus",
			"deleteQuestion": "Kustuta",

			"cancel": "Tühista",
			"save": "Salvesta",

			"clear": "Kustuta",

			"with": "koos",

			"latitude": "Latitude",
			"longitude": "pikkus",

			"tableView": "Tabelivaade",
			"calendarView": "Kalendri vaade",
			"mapView": "Kaardivaade",

			"calendarCulture": "on",
			"calendarDate": "Kuupäev",
			"calendarTime": "aeg",
			"calendarEvent": "sündmus",
			"calendarAllDay": "kogu päev",
			"calendarWeek": "nädal",
			"calendarWorkWeek": "Töö nädal",
			"calendarDay": "päev",
			"calendarMonth": "kuu",
			"calendarPrevious": "Eelmine",
			"calendarNext": "Järgmine",
			"calendarYesterday": "Eile",
			"calendarTomorrow": "Homme",
			"calendarToday": "Täna",
			"calendarAgenda": "Agenda",

			"view": "Vaata",
			"edit": "Muuda",
			"delete": "Kustuta",
			"new": "Lisa",
			"newRecord": "Uus",

			"noValikud": "Valikud puuduvad",
			"loading": "Laadimine ...",
			"selectValue": "",

			"noData": "Tulemusi ei leitud",

			"enableBasicFilters": "Näita põhifiltrid",
			"disableBasicFilters": "Peida põhifiltrid",

			"search": "Otsi",
			"searchCriteria": "otsingukriteeriumid",

			"deleteSuccess": "element (id) edukalt eemaldatud",

			"virtualAnalyst": "indikaatorid",

			"closeSession": "Logi välja",

			"searchFilter": "Otsingufilter",

			"adminGroup": "Admin",
			"admin": "Haldusülesanded",
			"backupBackup": "Varunda",
			"downloadBackup": "Varunduse tegemine ja allalaadimine",

			"deleteDatabase": "Andmebaasi kustutamine",
			"deleteRestoreToDefault": "Andmebaasi kustutamine ja tehase väärtuste taastamine",
			"deleteDatabaseConfirmation": "kustutamise kinnitus",
			"deleteDatabaseQuestion": "Te kustutate kõik andmed, kas olete kindel?",
			"deleteDatabaseSuccess": "Süsteem on edukalt taastatud tehaseseadetele",

			"backupRestore": "Taastage varukoopia",
			"uploadBackup": "Lohistage või valige varukoopia, mida soovite taastada",
			"restoreConfirmation": "Restaureerimise kinnitus",
			"restoreQuestion": "Te taastate süsteemi varukoopia, kõik andmed kustutatakse, kas olete kindel?",
			"restore": "Taasta",
			"restoreSuccess": "Varundus taastati edukalt",
			"restoreOnlyOneFile": "Palun valige ainult üks fail",
		},
	},
	fil: { // Filipino
		translation: {
		},
	},
	fi: { // Finnish
		translation: {
			"title": "Tietojen hallinta",

			"accessControl": "Access control",
			"username": "Käyttäjätunnus",
			"password": "Salasana",
			"language": "kieli",
			"login": "Access",
			"incorrectUsernameOrPassword": "Virheellinen käyttäjätunnus ja / tai salasana",

			"primaryKeyWithOnlyOneAttributeNeeded": "Ensisijaisella avaimella voi olla vain yksi määrite",

			"invalidValue": "Syötetty arvo ei ole kelvollinen",

			"selected": "valittu",

			"deleteConfirmation": "Vahvistus",
			"deleteQuestion": "Poista",

			"cancel": "Peruuta",
			"save": "Tallenna",

			"clear": "Poista",

			"with": "kanssa",

			"latitude": "Latitude",
			"longitude": "Pituus",

			"tableView": "Taulukonäkymä",
			"calendarView": "Kalenterinäkymä",
			"mapView": "Karttanäkymä",

			"calendarCulture": "on",
			"calendarDate": "Päivämäärä",
			"calendarTime": "Aika",
			"calendarEvent": "Tapahtuma",
			"calendarAllDay": "Koko päivä",
			"calendarWeek": "Viikko",
			"calendarWorkWeek": "Työviikko",
			"calendarDay": "Päivä",
			"calendarMonth": "Kuukausi",
			"calendarPrevious": "Edellinen",
			"calendarNext": "Seuraava",
			"calendarYesterday": "Eilen",
			"calendarTomorrow": "Huomenna",
			"calendarToday": "Tänään",
			"calendarAgenda": "Agenda",

			"view": "Katso",
			"edit": "Muokkaa",
			"delete": "Poista",
			"new": "Lisää",
			"newRecord": "Uusi",

			"noVaihtoehdot": "Ei valintoja",
			"loading": "Ladataan ...",
			"selectValue": "",

			"noData": "Tuloksia ei löytynyt",

			"enableBasicFilters": "Näytä perussuodattimet",
			"disableBasicFilters": "Piilota perussuodattimet",

			"search": "Haku",
			"searchCriteria": "Hakukriteerit",

			"deleteSuccess": "kohde (t) poistettu onnistuneesti",

			"virtualAnalyst": "Indikaattorit",

			"closeSession": "Kirjaudu ulos",

			"searchFilter": "Etsi suodatin",

			"adminGroup": "Admin",
			"admin": "Hallinnolliset tehtävät",
			"backupBackup": "Varmuuskopioi",
			"downloadBackup": "Suorita ja lataa varmuuskopio",

			"deleteDatabase": "Poista tietokanta",
			"deleteRestoreToDefault": "Poista tietokanta ja palauttaa tehdasarvot",
			"deleteDatabaseConfirmation": "Poistamisvahvistus",
			"deleteDatabaseQuestion": "Haluatko poistaa kaikki tiedot, oletko varma?",
			"deleteDatabaseSuccess": "Järjestelmä on palautettu onnistuneesti tehdasasetuksiin",

			"backupRestore": "Palauta varmuuskopio",
			"uploadBackup": "Vedä tai valitse varmuuskopiotiedosto, jonka haluat palauttaa",
			"restoreConfirmation": "Palautuksen vahvistus",
			"restoreQuestion": "Aiot palauttaa järjestelmän varmuuskopion, kaikki tiedot poistetaan, olet varma?",
			"restore": "Palauta",
			"restoreSuccess": "Varmuuskopiointi palautettiin onnistuneesti",
			"restoreOnlyOneFile": "Valitse vain yksi tiedosto"
		},
	},
	fr_CA: { // French (Canada)
		translation: {
			"title": "Data Management",

			"accessControl": "Contrôle d'accès",
			"username": "Nom d'utilisateur",
			"password": "Mot de passe",
			"language": "Langue",
			"login": "Accès",
			"incorrectUsernameOrPassword": "Nom d'utilisateur et / ou mot de passe incorrect",

			"primaryKeyWithOnlyOneAttributeNeeded": "La clé primaire ne peut avoir qu'un seul attribut",

			"invalidValue": "La valeur entrée n'est pas valide",

			"selected": "selected",

			"deleteConfirmation": "Confirmation",
			"deleteQuestion": "Supprimer",

			"cancel": "Annuler",
			"save": "Enregistrer",

			"clear": "Supprimer",

			"with": "avec",

			"latitude": "Latitude",
			"longitude": "Longueur",

			"tableView": "Vue de la table",
			"calendarView": "vue du calendrier",
			"mapView": "Affichage de la carte",

			"calendarCulture": "est",
			"calendarDate": "Date",
			"calendarTime": "Time",
			"calendarEvent": "événement",
			"calendarAllDay": "Toute la journée",
			"calendarWeek": "Semaine",
			"calendarWorkWeek": "Semaine de travail",
			"calendarDay": "Day",
			"calendarMonth": "Mois",
			"calendarPrevious": "Précédent",
			"calendarNext": "Suivant",
			"calendarYesterday": "Yesterday",
			"calendarTomorrow": "Demain",
			"calendarToday": "Aujourd'hui",
			"calendarAgenda": "Agenda",

			"view": "Voir",
			"edit": "Modifier",
			"delete": "Supprimer",
			"new": "Ajouter",
			"newRecord": "Nouveau",

			"noOptions": "Aucune option",
			"loading": "Chargement en cours ...",
			"selectValue": "",

			"noData": "Aucun résultat trouvé",

			"enableBasicFilters": "Afficher les filtres de base",
			"disableBasicFilters": "Masquer les filtres de base",

			"search": "Recherche",
			"searchCriteria": "Critères de recherche",

			"deleteSuccess": "élément (s) supprimé (s)",

			"virtualAnalyst": "Indicators",

			"closeSession": "Déconnexion",

			"searchFilter": "Filtre de recherche",

			"adminGroup": "Admin",
			"admin": "Tâches administratives",
			"backupBackup": "Sauvegarder",
			"downloadBackup": "Effectuer et télécharger une sauvegarde",

			"deleteDatabase": "Supprimer la base de données",
			"deleteRestoreToDefault": "Supprimer la base de données et restaurer les valeurs d'usine",
			"deleteDatabaseConfirmation": "Confirmation de suppression",
			"deleteDatabaseQuestion": "Vous allez effacer toutes les données, êtes-vous sûr?",
			"deleteDatabaseSuccess": "Le système a été restauré avec succès aux paramètres d'usine",

			"backupRestore": "Restaurer la sauvegarde",
			"uploadBackup": "Faites glisser ou sélectionnez le fichier de sauvegarde que vous souhaitez restaurer",
			"restoreConfirmation": "Confirmation de restauration",
			"restoreQuestion": "Vous allez restaurer une sauvegarde du système, toutes les données seront supprimées, êtes-vous sûr?",
			"restore": "Restaurer",
			"restoreSuccess": "La sauvegarde a été restaurée avec succès",
			"restoreOnlyOneFile": "Veuillez sélectionner un seul fichier"
		},
	},
	fr_FR: { // French (France)
		translation: {
			"title": "Data Management",

			"accessControl": "Contrôle d'accès",
			"username": "Nom d'utilisateur",
			"password": "Mot de passe",
			"language": "Langue",
			"login": "Accès",
			"incorrectUsernameOrPassword": "Nom d'utilisateur et / ou mot de passe incorrect",

			"primaryKeyWithOnlyOneAttributeNeeded": "La clé primaire ne peut avoir qu'un seul attribut",

			"invalidValue": "La valeur entrée n'est pas valide",

			"selected": "selected",

			"deleteConfirmation": "Confirmation",
			"deleteQuestion": "Supprimer",

			"cancel": "Annuler",
			"save": "Enregistrer",

			"clear": "Supprimer",

			"with": "avec",

			"latitude": "Latitude",
			"longitude": "Longueur",

			"tableView": "Vue de la table",
			"calendarView": "vue du calendrier",
			"mapView": "Affichage de la carte",

			"calendarCulture": "est",
			"calendarDate": "Date",
			"calendarTime": "Time",
			"calendarEvent": "événement",
			"calendarAllDay": "Toute la journée",
			"calendarWeek": "Semaine",
			"calendarWorkWeek": "Semaine de travail",
			"calendarDay": "Day",
			"calendarMonth": "Mois",
			"calendarPrevious": "Précédent",
			"calendarNext": "Suivant",
			"calendarYesterday": "Yesterday",
			"calendarTomorrow": "Demain",
			"calendarToday": "Aujourd'hui",
			"calendarAgenda": "Agenda",

			"view": "Voir",
			"edit": "Modifier",
			"delete": "Supprimer",
			"new": "Ajouter",
			"newRecord": "Nouveau",

			"noOptions": "Aucune option",
			"loading": "Chargement en cours ...",
			"selectValue": "",

			"noData": "Aucun résultat trouvé",

			"enableBasicFilters": "Afficher les filtres de base",
			"disableBasicFilters": "Masquer les filtres de base",

			"search": "Recherche",
			"searchCriteria": "Critères de recherche",

			"deleteSuccess": "élément (s) supprimé (s)",

			"virtualAnalyst": "Indicators",

			"closeSession": "Déconnexion",

			"searchFilter": "Filtre de recherche",

			"adminGroup": "Admin",
			"admin": "Tâches administratives",
			"backupBackup": "Sauvegarder",
			"downloadBackup": "Effectuer et télécharger une sauvegarde",

			"deleteDatabase": "Supprimer la base de données",
			"deleteRestoreToDefault": "Supprimer la base de données et restaurer les valeurs d'usine",
			"deleteDatabaseConfirmation": "Confirmation de suppression",
			"deleteDatabaseQuestion": "Vous allez effacer toutes les données, êtes-vous sûr?",
			"deleteDatabaseSuccess": "Le système a été restauré avec succès aux paramètres d'usine",

			"backupRestore": "Restaurer la sauvegarde",
			"uploadBackup": "Faites glisser ou sélectionnez le fichier de sauvegarde que vous souhaitez restaurer",
			"restoreConfirmation": "Confirmation de restauration",
			"restoreQuestion": "Vous allez restaurer une sauvegarde du système, toutes les données seront supprimées, êtes-vous sûr?",
			"restore": "Restaurer",
			"restoreSuccess": "La sauvegarde a été restaurée avec succès",
			"restoreOnlyOneFile": "Veuillez sélectionner un seul fichier"
		},
	},
	de: { // German
		translation: {
			"title": "Datenverwaltung",

			"accessControl": "Zugriffskontrolle",
			"username": "Benutzername",
			"password": "Passwort",
			"language": "Sprache",
			"login": "Access",
			"incorrectUsernameOrPassword": "Falscher Benutzername und / oder Passwort",

			"primaryKeyWithOnlyOneAttributeNeeded": "Der Primärschlüssel kann nur ein Attribut haben",

			"invalidValue": "Der eingegebene Wert ist ungültig",

			"selected": "selected",

			"deleteConfirmation": "Confirmation",
			"deleteQuestion": "Löschen",

			"cancel": "Cancel",
			"save": "Speichern",

			"clear": "Löschen",

			"with": "with",

			"latitude": "Latitude",
			"longitude": "Length",

			"tableView": "Tabellenansicht",
			"calendarView": "Kalenderansicht",
			"mapView": "Kartenansicht",

			"calendarCulture": "is",
			"calendarDate": "Date",
			"calendarTime": "Time",
			"calendarEvent": "Ereignis",
			"calendarAllDay": "Den ganzen Tag",
			"calendarWeek": "Week",
			"calendarWorkWeek": "Arbeitswoche",
			"calendarDay": "Day",
			"calendarMonth": "Month",
			"calendarPrevious": "Previous",
			"calendarNext": "Next",
			"calendarYesterday": "Yesterday",
			"calendarTomorrow": "Tomorrow",
			"calendarToday": "Today",
			"calendarAgenda": "Agenda",

			"view": "See",
			"edit": "Bearbeiten",
			"delete": "Delete",
			"new": "Add",
			"newRecord": "New",

			"noOptions": "Keine Optionen",
			"loading": "Laden ...",
			"selectValue": "",

			"noData": "Keine Ergebnisse gefunden",

			"enableBasicFilters": "Basisfilter anzeigen",
			"disableBasicFilters": "Basisfilter ausblenden",

			"search": "Suche",
			"searchCriteria": "Suchkriterien",

			"deleteSuccess": "Artikel erfolgreich entfernt",

			"virtualAnalyst": "Indikatoren",

			"closeSession": "Abmelden",

			"searchFilter": "Suchfilter",

			"adminGroup": "Admin",
			"admin": "Administrative Aufgaben",
			"backupBackup": "Sichern",
			"downloadBackup": "Backup durchführen und herunterladen",

			"deleteDatabase": "Datenbank löschen",
			"deleteRestoreToDefault": "Datenbank löschen und auf Werkseinstellungen zurücksetzen",
			"deleteDatabaseConfirmation": "Löschbestätigung",
			"deleteDatabaseQuestion": "Sie werden alle Daten löschen, sind Sie sicher?",
			"deleteDatabaseSuccess": "Das System wurde erfolgreich auf die Werkseinstellungen zurückgesetzt",

			"backupRestore": "Backup wiederherstellen",
			"uploadBackup": "Ziehen Sie die wiederherzustellende Sicherungsdatei oder wählen Sie sie aus",
			"restoreConfirmation": "Bestätigung der Wiederherstellung",
			"restoreQuestion": "Sie werden ein Backup des Systems wiederherstellen, alle Daten werden gelöscht, sind Sie sicher?",
			"restore": "Wiederherstellen",
			"restoreSuccess": "Die Sicherung wurde erfolgreich wiederhergestellt",
			"restoreOnlyOneFile": "Bitte nur eine Datei auswählen"
		},
	},
	el: { // Greek
		translation: {
			"title": "Διαχείριση δεδομένων",

			"accessControl": "Έλεγχος πρόσβασης",
			"username": "Όνομα χρήστη",
			"password": "Κωδικός πρόσβασης",
			"language": "Γλώσσα",
			"login": "Πρόσβαση",
			"incorrectUsernameOrPassword": "Λάθος όνομα χρήστη και / ή κωδικός πρόσβασης",

			"primaryKeyWithOnlyOneAttributeNeeded": "Το πρωτεύον κλειδί μπορεί να έχει μόνο ένα χαρακτηριστικό",

			"invalidValue": "Η τιμή εισαγωγής δεν είναι έγκυρη",

			"selected": "επιλεγμένο",

			"deleteConfirmation": "Επιβεβαίωση",
			"deleteQuestion": "Διαγραφή",

			"cancel": "Ακύρωση",
			"save": "Αποθήκευση",

			"clear": "Διαγραφή",

			"with": "με",

			"latitude": "Latitude",
			"longitude": "Μήκος",

			"tableView": "Προβολή πίνακα",
			"calendarView": "Προβολή ημερολογίου",
			"mapView": "Προβολή χάρτη",

			"calendarCulture": "είναι",
			"calendarDate": "Ημερομηνία",
			"calendarTime": "Ώρα",
			"calendarEvent": "Συμβάν",
			"calendarAllDay": "Όλη την ημέρα",
			"calendarWeek": "Εβδομάδα",
			"calendarWorkWeek": "Εβδομάδα εργασίας",
			"calendarDay": "Ημέρα",
			"calendarMonth": "Μήνας",
			"calendarPrevious": "Προηγούμενο",
			"calendarNext": "Επόμενο",
			"calendarYearday": "Χθες",
			"calendarTomorrow": "Αύριο",
			"calendarToday": "Σήμερα",
			"calendarAgenda": "Ατζέντα",

			"view": "Δείτε",
			"edit": "Επεξεργασία",
			"delete": "Διαγραφή",
			"new": "Προσθήκη",
			"newRecord": "Νέο",

			"noOptions": "Δεν υπάρχουν επιλογές",
			"loading": "Φόρτωση ...",
			"selectValue": "",

			"noData": "Δεν βρέθηκαν αποτελέσματα",

			"enableBasicFilters": "Εμφάνιση βασικών φίλτρων",
			"disableBasicFilters": "Απόκρυψη βασικών φίλτρων",

			"search": "Αναζήτηση",
			"searchCriteria": "Κριτήρια αναζήτησης",

			"deleteSuccess": "τα αντικείμενα έχουν καταργηθεί με επιτυχία",

			"virtualAnalyst": "Δείκτες",

			"closeSession": "Αποσύνδεση",

			"searchFilter": "Φιλτράρισμα αναζήτησης",

			"adminGroup": "Διαχειριστής",
			"admin": "Καθήκοντα διαχείρισης",
			"backupBackup": "Δημιουργία αντιγράφων ασφαλείας",
			"downloadBackup": "Εκτέλεση και λήψη αντιγράφων ασφαλείας",

			"deleteDatabase": "Διαγραφή βάσης δεδομένων",
			"deleteRestoreToDefault": "Διαγραφή βάσης δεδομένων και επαναφορά στις εργοστασιακές τιμές",
			"deleteDatabaseConfirmation": "Επιβεβαίωση διαγραφής",
			"deleteDatabaseQuestion": "Θα σβήσετε όλα τα δεδομένα, είστε βέβαιοι;",
			"deleteDatabaseSuccess": "Το σύστημα έχει αποκατασταθεί με επιτυχία στις εργοστασιακές ρυθμίσεις",

			"backupRestore": "Επαναφορά αντιγράφων ασφαλείας",
			"uploadBackup": "Σύρετε ή επιλέξτε το αρχείο αντιγράφου ασφαλείας που θέλετε να επαναφέρετε",
			"restoreConfirmation": "επιβεβαίωση αποκατάστασης",
			"restoreQuestion": "Πρόκειται να επαναφέρετε ένα αντίγραφο ασφαλείας του συστήματος, όλα τα δεδομένα θα διαγραφούν, είστε σίγουροι;",
			"restore": "Επαναφορά",
			"restoreSuccess": "Το αντίγραφο ασφαλείας δημιουργήθηκε με επιτυχία",
			"restoreOnlyOneFile": "Παρακαλώ επιλέξτε μόνο ένα αρχείο"
		},
	},
	he: { // Hebrew
		translation: {
		},
	},
	hi: { // Hindi
		translation: {
			"title": "डेटा प्रबंधन",

			"accessControl": "एक्सेस कंट्रोल",
			"username": "उपयोगकर्ता नाम",
			"password": "पासवर्ड",
			"language": "भाषा",
			"login": "एक्सेस",
			"incorrectUsernameOrPassword": "गलत उपयोगकर्ता नाम और / या पासवर्ड",

			"primaryKeyWithOnlyOneAttributeNeeded": "प्राथमिक कुंजी में केवल एक विशेषता हो सकती है",

			"invalidValue": "दर्ज किया गया मान मान्य नहीं है",

			"selected": "चयनित",

			"deleteConfirmation": "पुष्टि",
			"deleteQuestion": "हटाएं",

			"cancel": "रद्द करें",
			"save": "सहेजें",

			"clear": "हटाएं",

			"with": "साथ",

			"latitude": "अक्षांश",
			"longitude": "लंबाई",

			"tableView": "टेबल व्यू",
			"calendarView": "कैलेंडर दृश्य",
			"mapView": "मैप व्यू",

			"calendarCulture": "है",
			"calendarDate": "दिनांक",
			"calendarTime": "समय",
			"calendarEvent": "इवेंट",
			"calendarAllDay": "पूरा दिन",
			"calendarWeek": "सप्ताह",
			"calendarWorkWeek": "कार्य सप्ताह",
			"calendarDay": "दिन",
			"calendarMonth": "महीना",
			"calendarPrepret": "पिछला",
			"calendarNext": "अगला",
			"calendarYday": "कल",
			"calendarTomorrow": "कल",
			"calendarToday": "आज",
			"calendarAgenda": "एजेंडा",

			"view": "देखें",
			"edit": "संपादित करें",
			"delete": "हटाएं",
			"new": "जोड़ें",
			"newRecord": "नया",

			"noOptions": "कोई विकल्प नहीं",
			" लोडिंग": "लोड हो रहा है ...",
			"selectValue": "",

			"noData": "कोई परिणाम नहीं मिला",

			"enableBasicFilters": "बुनियादी फ़िल्टर दिखाएं",
			"disableBasicFilters": "मूल फ़िल्टर छुपाएं",

			"search": "खोज",
			"searchCriteria": "खोज मानदंड",

			"deleteSuccess": "आइटम (ओं) को सफलतापूर्वक हटा दिया गया",

			"virtualAnalyst": "संकेतक",

			"closeSession": "लॉग आउट करें",

			"searchFilter": "खोज फ़िल्टर",

			"adminGroup": "व्यवस्थापक",
			"admin": "प्रशासनिक कार्य",
			"backupBackup": "बैक अप",
			"downloadBackup": "बैकअप करें और डाउनलोड करें",

			"deleteDatabase": "डेटाबेस हटाएं",
			"deleteRestoreToDefault": "डेटाबेस हटाएं और कारखाने के मूल्यों को पुनर्स्थापित करें",
			"deleteDatabaseConfirmation": "हटाए जाने की पुष्टि",
			"deleteDatabaseQuestion": "आप सभी डेटा को मिटाने जा रहे हैं, क्या आप सुनिश्चित हैं?",
			"deleteDatabaseSuccess": "सिस्टम को फ़ैक्टरी सेटिंग्स में सफलतापूर्वक बहाल कर दिया गया है",

			"backupRestore": "बैकअप पुनर्स्थापित करें",
			"uploadBackup": "जिस बैकअप फ़ाइल को आप पुनर्स्थापित करना चाहते हैं उसे खींचें या चुनें",
			"restoreConfirmation": "बहाली की पुष्टि",
			"restoreQuestion": "आप सिस्टम के एक बैकअप को पुनर्स्थापित करने जा रहे हैं, सभी डेटा हटा दिए जाएंगे, क्या आप सुनिश्चित हैं?",
			"restore": "रिस्टोर",
			"restoreSuccess": "बैकअप सफलतापूर्वक बहाल किया गया था",
			"restoreOnlyOneFile": "कृपया केवल एक फ़ाइल का चयन करें",
		},
	},
	hu: { // Hungarian
		translation: {
			"title": "Adatkezelés",

			"accessControl": "Beléptetés",
			"username": "Felhasználónév",
			"password": "Jelszó",
			"language": "Nyelv",
			"login": "Hozzáférés",
			"incorrectUsernameOrPassword": "Helytelen felhasználónév és / vagy jelszó",

			"primaryKeyWithOnlyOneAttributeNeeded": "Az elsődleges kulcs csak egy attribútummal rendelkezik",

			"invalidValue": "A megadott érték nem érvényes",

			"selected": "kiválasztott",

			"deleteConfirmation": "Megerősítés",
			"deleteQuestion": "Törlés",

			"cancel": "Mégse",
			"save": "Mentés",

			"clear": "Törlés",

			"with": "a",

			"latitude": "Latitude",
			"longitude": "Hossz",

			"tableView": "Táblázatnézet",
			"calendarView": "Naptár nézet",
			"mapView": "Térképnézet",

			"calendarCulture": "van",
			"calendarDate": "Dátum",
			"calendarTime": "Idő",
			"calendarEvent": "Esemény",
			"calendarAllDay": "Egész nap",
			"calendarWeek": "Hét",
			"calendarWorkWeek": "Munkahét",
			"calendarDay": "Nap",
			"calendarMonth": "hónap",
			"calendarPrevious": "Előző",
			"calendarNext": "Következő",
			"calendarYesterday": "Tegnap",
			"calendarTomorrow": "Holnap",
			"calendarToday": "Ma",
			"calendarAgenda": "Napirend",

			"view": "Lásd",
			"edit": "Szerkesztés",
			"delete": "Törlés",
			"new": "Hozzáadás",
			"newRecord": "Új",

			"noOptions": "Nincs opció",
			"loading": "Betöltés ...",
			"selectValue": "",

			"noData": "Nincs találat",

			"enableBasicFilters": "Alapszűrők megjelenítése",
			"disableBasicFilters": "Alapszűrők elrejtése",

			"search": "Keresés",
			"searchCriteria": "Keresési feltételek",

			"deleteSuccess": "az elem (ek) sikeresen eltávolítva",

			"virtualAnalyst": "Mutatók",

			"closeSession": "Kijelentkezés",

			"searchFilter": "Keresési szűrő",

			"adminGroup": "Admin",
			"admin": "Adminisztratív feladatok",
			"backupBackup": "Biztonsági mentés",
			"downloadBackup": "Biztonsági mentés és letöltés",

			"deleteDatabase": "Adatbázis törlése",
			"deleteRestoreToDefault": "Adatbázis törlése és gyári értékek visszaállítása",
			"deleteDatabaseConfirmation": "Törlés megerősítése",
			"deleteDatabaseQuestion": "Biztosan törli az összes adatot?",
			"deleteDatabaseSuccess": "A rendszer sikeresen visszaállt a gyári beállításokra",

			"backupRestore": "Visszaállítás visszaállítása",
			"uploadBackup": "Húzza vagy válassza ki a visszaállítani kívánt biztonsági másolatot",
			"restoreConfirmation": "Visszaállítás megerősítése",
			"restoreQuestion": "Visszaállít egy biztonsági másolatot a rendszerről, minden adat törlődik, biztos benne?",
			"restore": "Visszaállítás",
			"restoreSuccess": "A biztonsági mentés sikeresen vissza lett állítva",
			"restoreOnlyOneFile": "Kérjük, válasszon csak egy fájlt"
		},
	},
	is: { // Icelandic
		translation: {
			"title": "Gögn stjórnun",

			"accessControl": "Aðgangsstýring",
			"username": "Notandanafn",
			"password": "Lykilorð",
			"language": "Tungumál",
			"login": "Aðgangur",
			"incorrectUsernameOrPassword": "Rangt notandanafn og / eða lykilorð",

			"primaryKeyWithOnlyOneAttributeNeeded": "Aðallykillinn getur aðeins haft eina eiginleika",

			"invalidValue": "Innslátturinn gildir ekki",

			"selected": "valinn",

			"deleteConfirmation": "Staðfesting",
			"deleteQuestion": "Eyða",

			"cancel": "Cancel",
			"save": "Vista",

			"clear": "Eyða",

			"with": "með",

			"latitude": "Breidd",
			"longitude": "Lengd",

			"tableView": "Tafla skoða",
			"calendarView": "Dagbókarskjár",
			"mapView": "Kortaskjár",

			"calendarCulture": "er",
			"calendarDate": "Date",
			"calendarTime": "Tími",
			"calendarEvent": "Event",
			"calendarAllDay": "All day",
			"calendarWeek": "Vika",
			"calendarWorkWeek": "Vinna viku",
			"calendarDay": "Dagur",
			"calendarMonth": "Mánuður",
			"calendarPrevious": "Fyrri",
			"calendarNext": "Next",
			"calendarYesterday": "Í gær",
			"calendarTomorrow": "Á morgun",
			"calendarToday": "Í dag",
			"calendarAgenda": "Dagskrá",

			"view": "Sjá",
			"edit": "Breyta",
			"delete": "Eyða",
			"new": "Bæta við",
			"newRecord": "Nýtt",

			"noOptions": "Engin valkostur",
			"loading": "Loading ...",
			"selectValue": "",

			"noData": "Engar niðurstöður fundust",

			"enableBasicFilters": "Sýna undirstöðu síur",
			"disableBasicFilters": "Fela undirstöðu síur",

			"search": "Leita",
			"searchCriteria": "Leita viðmiðanir",

			"deleteSuccess": "hlutur (s) tókst að fjarlægja",

			"virtualAnalyst": "Vísar",

			"closeSession": "Skrá út",

			"searchFilter": "Leita sía",

			"adminGroup": "Admin",
			"admin": "Stjórnandi verkefni",
			"backupBackup": "Til baka",
			"downloadBackup": "Framkvæma og hlaða niður öryggisafriti",

			"deleteDatabase": "Eyða gagnagrunni",
			"deleteRestoreToDefault": "Eyða gagnagrunni og endurheimta í verksmiðju gildi",
			"deleteDatabaseConfirmation": "Eyðing staðfesting",
			"deleteDatabaseQuestion": "Þú ert að fara að eyða öllum gögnum, ertu viss?",
			"deleteDatabaseSuccess": "Kerfið hefur verið endurheimt í upphafsstillingar",

			"backupRestore": "Restore öryggisafrit",
			"uploadBackup": "Dragðu eða veldu varabúnaðurinn sem þú vilt endurheimta",
			"restoreConfirmation": "Endurbætt staðfesting",
			"restoreQuestion": "Þú ert að fara að endurheimta öryggisafrit af kerfinu, öll gögn verða eytt, ertu viss?",
			"restore": "Endurheimta",
			"restoreSuccess": "Varan var endurheimt með góðum árangri",
			"restoreOnlyOneFile": "Vinsamlegast veldu aðeins eina skrá"
		},
	},
	id: { // Indonesian
		translation: {
			"title": "Manajemen Data",

			"accessControl": "Kontrol akses",
			"username": "Username",
			"password": "Kata Sandi",
			"language": "Bahasa",
			"login": "Access",
			"incorrectUsernameOrPassword": "Nama pengguna dan / atau kata sandi salah",

			"primaryKeyWithOnlyOneAttributeNeeded": "Kunci utama hanya dapat memiliki satu atribut",

			"invalidValue": "Nilai yang dimasukkan tidak valid",

			"selected": "selected",

			"deleteConfirmation": "Konfirmasi",
			"deleteQuestion": "Hapus",

			"cancel": "Cancel",
			"save": "Simpan",

			"clear": "Hapus",

			"with": "dengan",

			"latitude": "Latitude",
			"longitude": "Panjang",

			"tableView": "Tampilan tabel",
			"calendarView": "Tampilan kalender",
			"mapView": "Tampilan peta",

			"calendarCulture": "is",
			"calendarDate": "Date",
			"calendarTime": "Time",
			"calendarEvent": "Event",
			"calendarAllDay": "All day",
			"calendarWeek": "Week",
			"calendarWorkWeek": "Minggu kerja",
			"calendarDay": "Day",
			"calendarMonth": "Month",
			"calendarPrevious": "Sebelumnya",
			"calendarNext": "Next",
			"calendarYesterday": "Yesterday",
			"calendarTomorrow": "Tomorrow",
			"calendarToday": "Today",
			"calendarAgenda": "Agenda",

			"view": "Lihat",
			"edit": "Edit",
			"delete": "Hapus",
			"new": "Add",
			"newRecord": "Baru",

			"noOptions": "Tidak ada opsi",
			"loading": "Memuat ...",
			"selectValue": "",

			"noData": "Tidak ada hasil ditemukan",

			"enableBasicFilters": "Tampilkan filter dasar",
			"disableBasicFilters": "Sembunyikan filter dasar",

			"search": "Cari",
			"searchCriteria": "Kriteria pencarian",

			"deleteSuccess": "item berhasil dihapus",

			"virtualAnalyst": "Indikator",

			"closeSession": "Logout",

			"searchFilter": "Filter pencarian",

			"adminGroup": "Admin",
			"admin": "Tugas administratif",
			"backupBackup": "Cadangkan",
			"downloadBackup": "Lakukan dan unduh cadangan",

			"deleteDatabase": "Hapus basis data",
			"deleteRestoreToDefault": "Hapus database dan kembalikan ke nilai pabrik",
			"deleteDatabaseConfirmation": "Konfirmasi penghapusan",
			"deleteDatabaseQuestion": "Anda akan menghapus semua data, Anda yakin?",
			"deleteDatabaseSuccess": "Sistem telah berhasil dikembalikan ke pengaturan pabrik",

			"backupRestore": "Kembalikan cadangan",
			"uploadBackup": "Seret atau pilih file cadangan yang ingin Anda pulihkan",
			"restoreConfirmation": "Konfirmasi restorasi",
			"restoreQuestion": "Anda akan mengembalikan cadangan sistem, semua data akan dihapus, apakah Anda yakin?",
			"restore": "Restore",
			"restoreSuccess": "Cadangan berhasil dipulihkan",
			"restoreOnlyOneFile": "Silakan pilih hanya satu file"
		},
	},
	it: { // Italian
		translation: {
			"title": "Gestione dati",

			"accessControl": "Controllo accessi",
			"username": "Username",
			"password": "Password",
			"language": "Lingua",
			"login": "Accesso",
			"incorrectUsernameOrPassword": "User ID e / o password non corretta",

			"primaryKeyWithOnlyOneAttributeNeeded": "La chiave primaria può avere un solo attributo",

			"invalidValue": "Il valore inserito non è valido",

			"selected": "selected",

			"deleteConfirmation": "Conferma",
			"deleteQuestion": "Elimina",

			"cancel": "Annulla",
			"save": "Salva",

			"clear": "Elimina",

			"with": "con",

			"latitude": "Latitude",
			"longitude": "Length",

			"tableView": "Vista tabella",
			"calendarView": "Vista calendario",
			"mapView": "Vista mappa",

			"calendarCulture": "is",
			"calendarDate": "Date",
			"calendarTime": "Time",
			"calendarEvent": "Evento",
			"calendarAllDay": "Tutto il giorno",
			"calendarWeek": "Settimana",
			"calendarWorkWeek": "Settimana lavorativa",
			"calendarDay": "Giorno",
			"calendarMonth": "Month",
			"calendarPrevious": "Precedente",
			"calendarNext": "Avanti",
			"calendarYesterday": "Ieri",
			"calendarTomorrow": "Domani",
			"calendarToday": "Oggi",
			"calendarAgenda": "Agenda",

			"view": "Vedi",
			"edit": "Modifica",
			"delete": "Elimina",
			"new": "Aggiungi",
			"newRecord": "Nuovo",

			"noOptions": "Nessuna opzione",
			"loading": "Caricamento in corso ...",
			"selectValue": "",

			"noData": "Nessun risultato trovato",

			"enableBasicFilters": "Mostra filtri di base",
			"disableBasicFilters": "Nascondi filtri di base",

			"search": "Cerca",
			"searchCriteria": "Criteri di ricerca",

			"deleteSuccess": "item (s) rimosso con successo",

			"virtualAnalyst": "Indicatori",

			"closeSession": "Esci",

			"searchFilter": "Filtro di ricerca",

			"adminGroup": "Admin",
			"admin": "Attività amministrative",
			"backupBackup": "Backup",
			"downloadBackup": "Esegui e scarica il backup",

			"deleteDatabase": "Elimina database",
			"deleteRestoreToDefault": "Elimina database e ripristina valori di fabbrica",
			"deleteDatabaseConfirmation": "Confermare l'eliminazione",
			"deleteDatabaseQuestion": "Si elimina tutti i dati, sei sicuro?",
			"deleteDatabaseSuccess": "Il sistema è stato ripristinato con successo alle impostazioni di fabbrica",

			"backupRestore": "Ripristina backup",
			"uploadBackup": "Trascina o seleziona il file di backup che desideri ripristinare",
			"restoreConfirmation": "Conferma del restauro",
			"restoreQuestion": "Hai intenzione di ripristinare un sistema di backup, tutti i dati verranno cancellati Sei sicuro?",
			"restore": "Ripristina",
			"restoreSuccess": "Il backup è stato ripristinato correttamente",
			"restoreOnlyOneFile": "Seleziona solo un file"
		},
	},
	ja: { // Japanese
		translation: {
			"title": "データ管理",

			"accessControl": "アクセス制御",
			"username": "ユーザー名",
			"password": "パスワード",
			"language": "言語",
			"login": "アクセス",
			"incorrectUsernameOrPassword": "ユーザー名やパスワードが正しくありません",

			"primaryKeyWithOnlyOneAttributeNeeded": "主キーは1つの属性しか持てません",

			"invalidValue": "入力した値が無効です",

			"selected": "選択",

			"deleteConfirmation": "確認",
			"deleteQuestion": "削除",

			"cancel": "キャンセル",
			"save": "保存",

			"clear": "削除",

			"with": "with",

			"latitude": "緯度",
			"longitude": "長さ",

			"tableView": "テーブルビュー",
			"calendarView": "カレンダー表示",
			"mapView": "マップビュー",

			"calendarCulture": "is",
			"calendarDate": "日付",
			"calendarTime": "時間",
			"calendarEvent": "イベント",
			"calendarAllDay": "終日",
			"calendarWeek": "週",
			"calendarWorkWeek": "就業週",
			"calendarDay": "日",
			"calendarMonth": "月",
			"calendarPrevious": "前",
			"calendarNext": "次へ",
			"calendarYesterday": "昨日",
			"calendarTomorrow": "明日",
			"calendarToday": "今日",
			"calendarAgenda": "議題",

			"view": "参照",
			"edit": "編集",
			"delete": "削除",
			"new": "追加",
			"newRecord": "新規",

			"noOptions": "オプションなし",
			"loading": "ロード中...",
			"selectValue": "",

			"noData": "結果が見つかりません",

			"enableBasicFilters": "基本フィルタを表示",
			"disableBasicFilters": "基本フィルタを隠す",

			"search": "検索",
			"searchCriteria": "検索条件",

			"deleteSuccess": "アイテムは正常に削除されました",

			"virtualAnalyst": "インジケータ",

			"closeSession": "ログアウト",

			"searchFilter": "検索フィルタ",

			"adminGroup": "管理者",
			"admin": "管理タスク",
			"backupBackup": "バックアップ",
			"downloadBackup": "バックアップの実行とダウンロード",

			"deleteDatabase": "データベースの削除",
			"deleteRestoreToDefault": "データベースを削除して出荷時の値に復元する",
			"deleteDatabaseConfirmation": "削除確認",
			"deleteDatabaseQuestion": "あなたはすべてのデータを消去しようとしています,よろしいですか？",
			"deleteDatabaseSuccess": "システムは工場出荷時の設定に正常に復元されました",

			"backupRestore": "バックアップの復元",
			"uploadBackup": "復元したいバックアップファイルをドラッグするか選択してください",
			"restoreConfirmation": "修復確認",
			"restoreQuestion": "あなたはシステムのバックアップを復元しようとしています,すべてのデータが削除されます,よろしいですか？",
			"restore": "復元",
			"restoreSuccess": "バックアップは正常に復元されました",
			"restoreOnlyOneFile": "ファイルを一つだけ選択してください"
		},
	},
	ko: { // Korean
		translation: {
			"title": "데이터 관리",

			"accessControl": "액세스 제어",
			"username": "사용자 이름",
			"password": "비밀번호",
			"language": "언어",
			"login": "액세스",
			"incorrectUsernameOrPassword": "잘못된 사용자 이름 및 / 또는 암호",

			"primaryKeyWithOnlyOneAttributeNeeded": "기본 키는 하나의 속성 만 가질 수 있습니다",

			"invalidValue": "입력 한 값이 유효하지 않습니다",

			"selected": "선택됨",

			"deleteConfirmation": "확인",
			"deleteQuestion": "삭제",

			"cancel": "취소",
			"save": "저장",

			"clear": "삭제",

			"with": "함께",

			"latitude": "Latitude",
			"longitude": "길이",

			"tableView": "표보기",
			"calendarView": "캘린더보기",
			"mapView": "지도보기",

			"calendarCulture": "is",
			"calendarDate": "날짜",
			"calendarTime": "시간",
			"calendarEvent": "이벤트",
			"calendarAllDay": "하루 종일",
			"calendarWeek": "주",
			"calendarWorkWeek": "근무 주",
			"calendarDay": "Day",
			"calendarMonth": "월",
			"calendarPrevious": "이전",
			"calendarNext": "다음",
			"calendarYesterday": "어제",
			"calendarTomorrow": "내일",
			"calendarToday": "오늘",
			"calendarAgenda": "Agenda",

			"view": "보기",
			"edit": "편집",
			"delete": "삭제",
			"new": "추가",
			"newRecord": "New",

			"noOptions": "옵션 없음",
			"loading": "로드 중 ...",
			"selectValue": "",

			"noData": "결과를 찾을 수 없습니다",

			"enableBasicFilters": "기본 필터 표시",
			"disableBasicFilters": "기본 필터 숨기기",

			"search": "검색",
			"searchCriteria": "검색 기준",

			"deleteSuccess": "항목이 성공적으로 제거되었습니다",

			"virtualAnalyst": "표시기",

			"closeSession": "로그 아웃",

			"searchFilter": "검색 필터",

			"adminGroup": "Admin",
			"admin": "관리 작업",
			"backupBackup": "백업",
			"downloadBackup": "백업 수행 및 다운로드",

			"deleteDatabase": "데이터베이스 삭제",
			"deleteRestoreToDefault": "데이터베이스 삭제 및 초기 값으로 복원",
			"deleteDatabaseConfirmation": "삭제 확인",
			"deleteDatabaseQuestion": "모든 데이터를 지우겠습니까?",
			"deleteDatabaseSuccess": "시스템이 초기화되었습니다.",

			"backupRestore": "백업 복원",
			"uploadBackup": "복원 할 백업 파일을 드래그하거나 선택하십시오",
			"restoreConfirmation": "복원 확인",
			"restoreQuestion": "시스템 백업을 복원하면 모든 데이터가 삭제됩니다. 확실합니까?",
			"restore": "복원",
			"restoreSuccess": "백업이 성공적으로 복원되었습니다",
			"restoreOnlyOneFile": "하나의 파일 만 선택하십시오"
		},
	},
	lv: { // Latvian
		translation: {
			"title": "datu pārvaldība",

			"accessControl": "Piekļuves kontrole",
			"username": "Lietotājvārds",
			"password": "parole",
			"language": "valoda",
			"login": "Piekļuve",
			"incorrectUsernameOrPassword": "Nepareizs lietotājvārds un / vai parole",

			"primaryKeyWithOnlyOneAttributeNeeded": "Primārajai atslēgai var būt tikai viens atribūts",

			"invalidValue": "Ievadītā vērtība nav derīga",

			"selected": "atlasīts",

			"deleteConfirmation": "Apstiprinājums",
			"deleteQuestion": "Dzēst",

			"cancel": "Atcelt",
			"save": "Saglabāt",

			"clear": "Dzēst",

			"with": "ar",

			"latitude": "Latitude",
			"longitude": "garums",

			"tableView": "tabulas skats",
			"calendarView": "Kalendāra skats",
			"mapView": "Kartes skats",

			"calendarCulture": "ir",
			"calendarDate": "datums",
			"calendarTime": "laiks",
			"calendarEvent": "Notikums",
			"calendarAllDay": "Visu dienu",
			"calendarWeek": "nedēļa",
			"calendarWorkWeek": "Darba nedēļa",
			"calendarDay": "Diena",
			"calendarMonth": "mēnesis",
			"calendarPrevious": "Iepriekšējais",
			"calendarNext": "Tālāk",
			"calendarYesterday": "Vakar",
			"calendarTomorrow": "Rīt",
			"calendarToday": "Šodien",
			"calendarAgenda": "Darba kārtība",

			"view": "Skatīt",
			"edit": "Rediģēt",
			"delete": "Dzēst",
			"new": "Pievienot",
			"newRecord": "Jauns",

			"no iespējas": "Nav iespēju",
			"loading": "Loading ...",
			"selectValue": "",

			"noData": "Rezultāti nav atrasti",

			"enableBasicFilters": "Rādīt pamata filtrus",
			"disableBasicFilters": "Paslēpt pamata filtrus",

			"search": "Meklēt",
			"searchCriteria": "Meklēšanas kritēriji",

			"deleteSuccess": "vienums (-i) veiksmīgi noņemts",

			"virtualAnalyst": "Indikatori",

			"closeSession": "Iziet",

			"searchFilter": "Meklēšanas filtrs",

			"adminGroup": "Admin",
			"admin": "Administratīvie uzdevumi",
			"backupBackup": "dublēt",
			"downloadBackup": "Veikt un lejupielādēt dublējumu",

			"deleteDatabase": "Dzēst datubāzi",
			"deleteRestoreToDefault": "Dzēst datubāzi un atjaunot rūpnīcas vērtības",
			"deleteDatabaseConfirmation": "Dzēšanas apstiprinājums",
			"deleteDatabaseQuestion": "Jūs gatavojaties dzēst visus datus, vai esat pārliecināts?",
			"deleteDatabaseSuccess": "Sistēma ir veiksmīgi atjaunota uz rūpnīcas iestatījumiem",

			"backupRestore": "Atjaunot dublējumu",
			"uploadBackup": "Velciet vai atlasiet dublējuma failu, kuru vēlaties atjaunot",
			"restoreConfirmation": "Atjaunošanas apstiprinājums",
			"restoreQuestion": "Jūs atjaunosiet sistēmas dublējumu, visi dati tiks dzēsti, vai esat pārliecināti?",
			"restore": "Atjaunot",
			"restoreSuccess": "Dublējums tika veiksmīgi atjaunots",
			"restoreOnlyOneFile": "Lūdzu, atlasiet tikai vienu failu"
		},
	},
	lt: { // Lithuanian
		translation: {
			"title": "duomenų valdymas",

			"accessControl": "Prieigos kontrolė",
			"username": "naudotojo vardas",
			"password": "Slaptažodis",
			"language": "kalba",
			"login": "prieiga",
			"incorrectUsernameOrPassword": "Neteisingas naudotojo vardas ir (arba) slaptažodis",

			"primaryKeyWithOnlyOneAttributeNeeded": "Pirminis raktas gali turėti tik vieną atributą",

			"invalidValue": "Įvesta vertė negalioja",

			"selected": "pasirinktas",

			"deleteConfirmation": "Patvirtinimas",
			"deleteQuestion": "Ištrinti",

			"cancel": "Atšaukti",
			"save": "Išsaugoti",

			"clear": "Ištrinti",

			"with": "su",

			"latitude": "Platuma",
			"longitude": "ilgis",

			"tableView": "lentelės vaizdas",
			"calendarView": "Kalendoriaus vaizdas",
			"mapView": "Žemėlapio vaizdas",

			"calendarCulture": "yra",
			"calendarDate": "Data",
			"calendarTime": "Laikas",
			"calendarEvent": "Įvykis",
			"calendarAllDay": "Visą dieną",
			"calendarWeek": "Savaitė",
			"calendarWorkWeek": "Darbo savaitė",
			"calendarDay": "Diena",
			"calendarMonth": "Mėnuo",
			"calendarPrevious": "Ankstesnis",
			"calendarNext": "Kitas",
			"calendarYesterday": "Vakar",
			"calendarTomorrow": "Rytoj",
			"calendarToday": "Šiandien",
			"calendarAgenda": "Darbotvarkė",

			"view": "Žiūrėti",
			"edit": "Redaguoti",
			"delete": "Ištrinti",
			"new": "Pridėti",
			"newRecord": "Naujas",

			"noOptions": "Nėra parinkčių",
			"loading": "Loading ...",
			"selectValue": "",

			"noData": "Nerasta rezultatų",

			"enableBasicFilters": "Rodyti pagrindinius filtrus",
			"disableBasicFilters": "Slėpti pagrindinius filtrus",

			"search": "Paieška",
			"searchCriteria": "Paieškos kriterijai",

			"deleteSuccess": "elementas (-ai) sėkmingai pašalintas",

			"virtualAnalyst": "Rodikliai",

			"closeSession": "Atsijungti",

			"searchFilter": "Paieškos filtras",

			"adminGroup": "Administratorius",
			"admin": "Administracinės užduotys",
			"backupBackup": "Atsarginė kopija",
			"downloadBackup": "Atlikti ir atsisiųsti atsarginę kopiją",

			"deleteDatabase": "Ištrinti duomenų bazę",
			"deleteRestoreToDefault": "Ištrinti duomenų bazę ir atkurti gamyklos vertes",
			"deleteDatabaseConfirmation": "Panaikinimo patvirtinimas",
			"deleteDatabaseQuestion": "Jūs ištrinsite visus duomenis, ar esate tikri?",
			"deleteDatabaseSuccess": "Sistema sėkmingai atkurta į gamyklinius nustatymus",

			"backupRestore": "Atkurti atsarginę kopiją",
			"uploadBackup": "vilkite arba pasirinkite atsarginę failą, kurį norite atkurti",
			"restoreConfirmation": "Restauravimo patvirtinimas",
			"restoreQuestion": "Jūs atkursite sistemos atsarginę kopiją, visi duomenys bus ištrinti, ar tikrai esate?",
			"restore": "Atkurti",
			"restoreSuccess": "Atsarginė kopija buvo sėkmingai atkurta",
			"restoreOnlyOneFile": "Prašome pasirinkti tik vieną failą"
		},
	},
	ms: { // Malay
		translation: {
			"title": "Pengurusan Data",

			"accessControl": "Kawalan akses",
			"username": "Username",
			"password": "Password",
			"language": "Language",
			"login": "Akses",
			"incorrectUsernameOrPassword": "Nama pengguna dan / atau kata laluan salah",

			"primaryKeyWithOnlyOneAttributeNeeded": "Kunci utama hanya boleh mempunyai satu atribut",

			"invalidValue": "Nilai yang dimasukkan tidak sah",

			"selected": "dipilih",

			"deleteCirmation": "Confirmation",
			"deleteQuestion": "Padam",

			"cancel": "Batal",
			"save": "Simpan",

			"clear": "Padam",

			"with": "with",

			"latitude": "Latitud",
			"longitude": "Panjang",

			"tableView": "Paparan jadual",
			"calendarView": "Paparan kalendar",
			"mapView": "Paparan peta",

			"calendarCulture": "is",
			"calendarDate": "Date",
			"calendarTime": "Time",
			"calendarEvent": "Event",
			"calendarAllDay": "Sepanjang hari",
			"calendarWeek": "Minggu",
			"calendarWorkWeek": "Minggu kerja",
			"calendarDay": "Hari",
			"calendarMonth": "Bulan",
			"calendarSebelum": "Sebelum",
			"calendarNext": "Next",
			"calendarKemudian": "Semalam",
			"calendarTomorrow": "Besok",
			"calendarToday": "Hari ini",
			"calendarAgenda": "Agenda",

			"view": "Lihat",
			"edit": "Edit",
			"delete": "Padam",
			"new": "Tambah",
			"newRecord": "Baru",

			"noOptions": "Tiada pilihan",
			"loading": "Memuatkan ...",
			"selectValue": "",

			"noData": "Tiada keputusan ditemui",

			"enableBasicFilters": "Tunjukkan penapis asas",
			"disableBasicFilters": "Sembunyikan penapis asas",

			"search": "Carian",
			"searchCriteria": "Kriteria carian",

			"deleteSuccess": "item telah berjaya dialih keluar",

			"virtualAnalyst": "Petunjuk",

			"closeSession": "Keluar keluar",

			"searchFilter": "Penapis carian",

			"adminGroup": "Admin",
			"admin": "Tugas pentadbiran",
			"backupBackup": "Back up",
			"downloadBackup": "Lakukan dan muat turun sandaran",

			"deleteDatabase": "Padam pangkalan data",
			"deleteRestoreToDefault": "Padam pangkalan data dan pulihkan ke nilai kilang",
			"deleteDatabaseConfirmation": "Pengesahan penghapusan",
			"deleteDatabaseQuestion": "Anda akan memadamkan semua data, anda pasti?",
			"deleteDatabaseSuccess": "Sistem telah berjaya dipulihkan ke tetapan kilang",

			"backupRestore": "Pulihkan sandaran",
			"uploadBackup": "Seret atau pilih fail sandaran yang anda mahu pulihkan",
			"restoreConfirmation": "Pengesahan pemulihan",
			"restoreQuestion": "Anda akan memulihkan sandaran sistem, semua data akan dihapuskan, adakah anda pasti?",
			"restore": "Pulihkan",
			"restoreSuccess": "Sandaran telah berjaya dipulihkan",
			"restoreOnlyOneFile": "Sila pilih hanya satu fail"
		},
	},
	no: { // Norwegian
		translation: {
			"title": "Data Management",

			"accessControl": "Tilgangskontroll",
			"username": "Brukernavn",
			"password": "Passord",
			"language": "Language",
			"login": "Tilgang",
			"incorrectUsernameOrPassword": "Feil brukernavn og / eller passord",

			"primaryKeyWithOnlyOneAttributeNeeded": "Den primære nøkkelen kan bare ha ett attributt",

			"invalidValue": "Den oppgitte verdien er ikke gyldig",

			"selected": "valgt",

			"deleteConfirmation": "Bekreftelse",
			"deleteQuestion": "Delete",

			"cancel": "Cancel",
			"save": "Lagre",

			"clear": "Delete",

			"with": "med",

			"latitude": "Latitude",
			"longitude": "Lengde",

			"tableView": "Tabellvisning",
			"calendarView": "Kalendervisning",
			"mapView": "Kartvisning",

			"calendarCulture": "er",
			"calendarDate": "Date",
			"calendarTime": "Time",
			"calendarEvent": "Event",
			"calendarAllDay": "hele dagen",
			"calendarWeek": "Week",
			"calendarWorkWeek": "Arbeidsuge",
			"calendarDay": "Day",
			"calendarMonth": "Month",
			"calendarPrevious": "Previous",
			"calendarNext": "Next",
			"calendarYesterday": "I går",
			"calendarTomorrow": "Tomorrow",
			"calendarToday": "Today",
			"calendarAgenda": "Agenda",

			"view": "Se",
			"edit": "Rediger",
			"delete": "Slett",
			"new": "Legg til",
			"newRecord": "Ny",

			"noOptions": "Ingen alternativer",
			"loading": "Loading ...",
			"selectValue": "",

			"noData": "Ingen resultater funnet",

			"enableBasicFilters": "Vis grunnleggende filtre",
			"disableBasicFilters": "Skjul grunnleggende filtre",

			"search": "Søk",
			"searchCriteria": "Søkekriterier",

			"deleteSuccess": "element (er) som ble fjernet",

			"virtualAnalyst": "Indikatorer",

			"closeSession": "Logg ut",

			"searchFilter": "Søkefilter",

			"adminGroup": "Admin",
			"admin": "Administrative oppgaver",
			"backupBackup": "Back up",
			"downloadBackup": "Utfør og last ned backup",

			"deleteDatabase": "Slett database",
			"deleteRestoreToDefault": "Slett database og gjenopprett til fabrikkverdier",
			"deleteDatabaseConfirmation": "Sletting av bekreftelse",
			"deleteDatabaseQuestion": "Du skal slette alle dataene, er du sikker?",
			"deleteDatabaseSuccess": "Systemet har blitt gjenopprettet til fabrikkinnstillingene",

			"backupRestore": "Gjenopprett backup",
			"uploadBackup": "Dra eller velg backupfilen du vil gjenopprette",
			"restoreConfirmation": "Restorasjonsbekreftelse",
			"restoreQuestion": "Du skal gjenopprette en sikkerhetskopi av systemet, alle data vil bli slettet, er du sikker?",
			"restore": "Gjenopprett",
			"restoreSuccess": "Sikkerheten ble gjenopprettet med hell",
			"restoreOnlyOneFile": "Vennligst velg bare en fil"
		},
	},
	pl: { // Polish
		translation: {
			"title": "Zarządzanie danymi",

			"accessControl": "Kontrola dostępu",
			"username": "Username",
			"password": "Hasło",
			"language": "Język",
			"login": "Dostęp",
			"incorrectUsernameOrPassword": "Niepoprawna nazwa użytkownika i / lub hasło",

			"primaryKeyWithOnlyOneAttributeNeeded": "Klucz podstawowy może mieć tylko jeden atrybut",

			"invalidValue": "Wprowadzona wartość jest nieprawidłowa",

			"selected": "selected",

			"deleteConfirmation": "Potwierdzenie",
			"deleteQuestion": "Usuń",

			"cancel": "Anuluj",
			"save": "Zapisz",

			"clear": "Usuń",

			"with": "with",

			"latitude": "Latitude",
			"longitude": "Length",

			"tableView": "Widok tabeli",
			"calendarView": "Widok kalendarza",
			"mapView": "Widok mapy",

			"calendarCulture": "jest",
			"calendarDate": "Data",
			"calendarTime": "Time",
			"calendarEvent": "Event",
			"calendarAllDay": "Cały dzień",
			"calendarWeek": "Tydzień",
			"calendarWorkWeek": "Tydzień pracy",
			"calendarDay": "Dzień",
			"calendarMonth": "Miesiąc",
			"calendarPrevious": "Poprzedni",
			"calendarNext": "Dalej",
			"calendarYesterday": "Yesterday",
			"calendarTomorrow": "Tomorrow",
			"calendarToday": "Today",
			"calendarAgenda": "Agenda",

			"view": "Zobacz",
			"edit": "Edytuj",
			"delete": "Usuń",
			"new": "Dodaj",
			"newRecord": "Nowy",

			"noOptions": "Brak opcji",
			"loading": "Ładowanie ...",
			"selectValue": "",

			"noData": "Nie znaleziono wyników",

			"enableBasicFilters": "Pokaż podstawowe filtry",
			"disableBasicFilters": "Ukryj podstawowe filtry",

			"search": "Search",
			"searchCriteria": "Kryteria wyszukiwania",

			"deleteSuccess": "elementy zostały pomyślnie usunięte",

			"virtualAnalyst": "Wskaźniki",

			"closeSession": "Wyloguj się",

			"searchFilter": "Filtr wyszukiwania",

			"adminGroup": "Administrator",
			"admin": "Zadania administracyjne",
			"backupBackup": "Utwórz kopię zapasową",
			"downloadBackup": "Wykonaj i pobierz kopię zapasową",

			"deleteDatabase": "Usuń bazę danych",
			"deleteRestoreToDefault": "Usuń bazę danych i przywróć do wartości fabrycznych",
			"deleteDatabaseConfirmation": "Potwierdzenie usunięcia",
			"deleteDatabaseQuestion": "Czy na pewno usuniesz wszystkie dane?",
			"deleteDatabaseSuccess": "System został pomyślnie przywrócony do ustawień fabrycznych",

			"backupRestore": "Przywróć kopię zapasową",
			"uploadBackup": "Przeciągnij lub wybierz plik kopii zapasowej, który chcesz przywrócić",
			"restoreConfirmation": "Potwierdzenie przywrócenia",
			"restoreQuestion": "Zamierzasz przywrócić kopię zapasową systemu, wszystkie dane zostaną usunięte, czy na pewno?",
			"restore": "Przywróć",
			"restoreSuccess": "Kopia zapasowa została pomyślnie przywrócona",
			"restoreOnlyOneFile": "Proszę wybrać tylko jeden plik"
		},
	},
	pt_BR: { // Portuguese (Brazil)
		translation: {
			"title": "Gerenciamento de dados",

			"accessControl": "Controle de acesso",
			"username": "Username",
			"password": "Senha",
			"language": "Idioma",
			"login": "Acesso",
			"incorrectUsernameOrPassword": "Nome de usuário e / ou senha incorretos",

			"primaryKeyWithOnlyOneAttributeNeeded": "A chave primária só pode ter um atributo",

			"invalidValue": "O valor introduzido não é válido",

			"selected": "selecionado",

			"deleteConfirmation": "Confirmação",
			"deleteQuestion": "Excluir",

			"cancel": "Cancelar",
			"save": "Salvar",

			"clear": "Excluir",

			"with": "com",

			"latitude": "Latitude",
			"longitude": "Comprimento",

			"tableView": "Visualização de tabela",
			"calendarView": "Visualização do calendário",
			"mapView": "Visualização do mapa",

			"calendarCulture": "é",
			"calendarDate": "Data",
			"calendarTime": "Tempo",
			"calendarEvent": "Evento",
			"calendarAllDay": "Todo o dia",
			"calendarWeek": "Semana",
			"calendarWorkWeek": "Semana de trabalho",
			"calendarDay": "Dia",
			"calendarMonth": "Mês",
			"calendarPrevious": "Anterior",
			"calendarNext": "Próximo",
			"calendarYesterday": "Ontem",
			"calendarTomorrow": "Amanhã",
			"calendarToday": "Today",
			"calendarAgenda": "Agenda",

			"view": "Ver",
			"edit": "Editar",
			"delete": "Excluir",
			"new": "Adicionar",
			"newRecord": "Novo",

			"noOptions": "Nenhuma opção",
			"loading": "Carregando ...",
			"selectValue": "",

			"noData": "Nenhum resultado encontrado",

			"enableBasicFilters": "Mostrar filtros básicos",
			"disableBasicFilters": "Ocultar filtros básicos",

			"search": "Pesquisar",
			"searchCriteria": "Critérios de pesquisa",

			"deleteSuccess": "item removido com sucesso",

			"virtualAnalyst": "Indicadores",

			"closeSession": "Sair",

			"searchFilter": "Filtro de pesquisa",

			"adminGroup": "Admin",
			"admin": "Tarefas Administrativas",
			"backupBackup": "Backup",
			"downloadBackup": "Executar e fazer o download do backup",

			"deleteDatabase": "Apagar base de dados",
			"deleteRestoreToDefault": "Apagar banco de dados e restaurar para valores de fábrica",
			"deleteDatabaseConfirmation": "Confirmação de eliminação",
			"deleteDatabaseQuestion": "Você vai apagar todos os dados, tem certeza?",
			"deleteDatabaseSuccess": "O sistema foi restaurado com sucesso para as configurações de fábrica",

			"backupRestore": "Restaurar backup",
			"uploadBackup": "Arraste ou selecione o arquivo de backup que você deseja restaurar",
			"restoreConfirmation": "Confirmação de restauração",
			"restoreQuestion": "Você vai restaurar um backup do sistema, todos os dados serão apagados, tem certeza?",
			"restore": "Restaurar",
			"restoreSuccess": "O backup foi restaurado com sucesso",
			"restoreOnlyOneFile": "Por favor selecione apenas um arquivo"		},
	},
	pt_PT: { // Portuguese (Portugal)
		translation: {
			"title": "Gerenciamento de dados",

			"accessControl": "Controle de acesso",
			"username": "Username",
			"password": "Senha",
			"language": "Idioma",
			"login": "Acesso",
			"incorrectUsernameOrPassword": "Nome de usuário e / ou senha incorretos",

			"primaryKeyWithOnlyOneAttributeNeeded": "A chave primária só pode ter um atributo",

			"invalidValue": "O valor introduzido não é válido",

			"selected": "selecionado",

			"deleteConfirmation": "Confirmação",
			"deleteQuestion": "Excluir",

			"cancel": "Cancelar",
			"save": "Salvar",

			"clear": "Excluir",

			"with": "com",

			"latitude": "Latitude",
			"longitude": "Comprimento",

			"tableView": "Visualização de tabela",
			"calendarView": "Visualização do calendário",
			"mapView": "Visualização do mapa",

			"calendarCulture": "é",
			"calendarDate": "Data",
			"calendarTime": "Tempo",
			"calendarEvent": "Evento",
			"calendarAllDay": "Todo o dia",
			"calendarWeek": "Semana",
			"calendarWorkWeek": "Semana de trabalho",
			"calendarDay": "Dia",
			"calendarMonth": "Mês",
			"calendarPrevious": "Anterior",
			"calendarNext": "Próximo",
			"calendarYesterday": "Ontem",
			"calendarTomorrow": "Amanhã",
			"calendarToday": "Today",
			"calendarAgenda": "Agenda",

			"view": "Ver",
			"edit": "Editar",
			"delete": "Excluir",
			"new": "Adicionar",
			"newRecord": "Novo",

			"noOptions": "Nenhuma opção",
			"loading": "Carregando ...",
			"selectValue": "",

			"noData": "Nenhum resultado encontrado",

			"enableBasicFilters": "Mostrar filtros básicos",
			"disableBasicFilters": "Ocultar filtros básicos",

			"search": "Pesquisar",
			"searchCriteria": "Critérios de pesquisa",

			"deleteSuccess": "item removido com sucesso",

			"virtualAnalyst": "Indicadores",

			"closeSession": "Sair",

			"searchFilter": "Filtro de pesquisa",

			"adminGroup": "Admin",
			"admin": "Tarefas Administrativas",
			"backupBackup": "Backup",
			"downloadBackup": "Executar e fazer o download do backup",

			"deleteDatabase": "Apagar base de dados",
			"deleteRestoreToDefault": "Apagar banco de dados e restaurar para valores de fábrica",
			"deleteDatabaseConfirmation": "Confirmação de eliminação",
			"deleteDatabaseQuestion": "Você vai apagar todos os dados, tem certeza?",
			"deleteDatabaseSuccess": "O sistema foi restaurado com sucesso para as configurações de fábrica",

			"backupRestore": "Restaurar backup",
			"uploadBackup": "Arraste ou selecione o arquivo de backup que você deseja restaurar",
			"restoreConfirmation": "Confirmação de restauração",
			"restoreQuestion": "Você vai restaurar um backup do sistema, todos os dados serão apagados, tem certeza?",
			"restore": "Restaurar",
			"restoreSuccess": "O backup foi restaurado com sucesso",
			"restoreOnlyOneFile": "Por favor selecione apenas um arquivo"		},
	},
	ro: { // Romanian
		translation: {
			"title": "Managementul datelor",

			"accessControl": "Control acces",
			"username": "Nume de utilizator",
			"password": "Parola",
			"language": "Limba",
			"login": "Acces",
			"incorrectUsernameOrPassword": "Nume și / sau parolă incorecte",

			"primaryKeyWithOnlyOneAttributeNeeded": "Cheia primară nu poate avea decât un atribut",

			"invalidValue": "Valoarea introdusă nu este validă",

			"selected": "selectat",

			"deleteconfirmare": "Confirmare",
			"deleteQuestion": "Șterge",

			"cancel": "Anulare",
			"save": "Salvare",

			"clear": "Șterge",

			"with": "cu",

			"latitude": "Latitude",
			"longitudine": "lungime",

			"tableView": "Vizualizare tabel",
			"calendarView": "Vizualizare calendar",
			"mapView": "Vizualizare hartă",

			"calendarCulture": "este",
			"calendarDate": "Data",
			"calendarTime": "Timp",
			"calendarEvent": "Eveniment",
			"calendarAllDay": "Toată ziua",
			"calendarWeek": "Săptămâna",
			"calendarWorkWeek": "Săptămâna de lucru",
			"calendarDay": "Ziua",
			"calendarMonth": "Luna",
			"calendarPrevious": "Anterior",
			"calendarNext": "Următorul",
			"calendarYearday": "Ieri",
			"calendarTomine": "Mâine",
			"calendarToday": "Astăzi",
			"calendarAgenda": "Agenda",

			"view": "Vezi",
			"edit": "Editare",
			"delete": "Șterge",
			"new": "Adăugați",
			"newRecord": "Nou",

			"noOpțiuni": "Fără opțiuni",
			"loading": "Loading ...",
			"selectValue": "",

			"noData": "Nu s-au găsit rezultate",

			"enableBasicFilters": "Afișați filtre de bază",
			"disableBasicFilters": "Ascunde filtrele de bază",

			"search": "Căutare",
			"searchCriteria": "Criterii de căutare",

			"deleteSuccess": "articolele au fost eliminate cu succes",

			"virtualAnalyst": "Indicatori",

			"closeSession": "Deconectați-vă",

			"searchFilter": "Filtru de căutare",

			"adminGroup": "Admin",
			"admin": "Sarcinile administrative",
			"backupBackup": "Copie de rezervă",
			"downloadBackup": "Efectuați și descărcați backup",

			"deleteDatabase": "Șterge baza de date",
			"deleteRestoreToDefault": "Ștergeți baza de date și restaurați valorile din fabrică",
			"deleteDatabaseConfirmation": "Confirmarea ștergerii",
			"deleteDatabaseQuestion": "Veți șterge toate datele, sunteți sigur?",
			"deleteDatabaseSuccess": "Sistemul a fost restaurat cu succes la setările din fabrică",

			"backupRestore": "Restore backup",
			"uploadBackup": "Glisați sau selectați fișierul de backup pe care doriți să îl restaurați",
			"restoreConfirmation": "Confirmarea restaurării",
			"restoreQuestion": "Veți restabili o copie de rezervă a sistemului, toate datele vor fi șterse, sunteți sigur?",
			"restore": "Restaurare",
			"restoreSuccess": "Backup-ul a fost restaurat cu succes",
			"restoreOnlyOneFile": "Selectați un singur fișier"
		},
	},
	ru: { // Russian
		translation: {
			"title": "Управление данными",

			"accessControl": "Контроль доступа",
			"username": "Имя пользователя",
			"password": "Пароль",
			"language": "Язык",
			"login": "Доступ",
			"incorrectUsernameOrPassword": "Неверное имя пользователя и / или пароль",

			"primaryKeyWithOnlyOneAttributeNeeded": "Первичный ключ может иметь только один атрибут",

			"invalidValue": "Введенное значение недопустимо",

			"selected": "selected",

			"deleteConfirmation": "Подтверждение",
			"deleteQuestion": "Удалить",

			"cancel": "Отмена",
			"save": "Сохранить",

			"clear": "Удалить",

			"with": "с",

			"latitude": "широта",
			"longitude": "Длина",

			"tableView": "Представление таблицы",
			"calendarView": "просмотр календаря",
			"mapView": "Просмотр карты",

			"calendarCulture": "is",
			"calendarDate": "Date",
			"calendarTime": "Время",
			"calendarEvent": "Событие",
			"calendarAllDay": "Весь день",
			"calendarWeek": "Неделя",
			"calendarWorkWeek": "Рабочая неделя",
			"calendarDay": "День",
			"calendarMonth": "Месяц",
			"calendarPrevious": "Предыдущий",
			"calendarNext": "Next",
			"calendarYesterday": "вчера",
			"calendarTothing": "Завтра",
			"calendarToday": "Сегодня",
			"calendarAgenda": "Повестка дня",

			"view": "Смотрите",
			"edit": "Изменить",
			"delete": "Удалить",
			"new": "Добавить",
			"newRecord": "Новый",

			"noOptions": "Нет параметров",
			"loading": "Загрузка ...",
			"selectValue": "",

			"noData": "Результаты не найдены",

			"enableBasicFilters": "Показать основные фильтры",
			"disableBasicFilters": "Скрыть основные фильтры",

			"search": "Поиск",
			"searchCriteria": "Критерии поиска",

			"deleteSuccess": "элемент (ы) успешно удален",

			"virtualAnalyst": "Индикаторы",

			"closeSession": "Выйти",

			"searchFilter": "Фильтр поиска",

			"adminGroup": "Admin",
			"admin": "Административные задачи",
			"backupBackup": "Резервное копирование",
			"downloadBackup": "Выполнить и загрузить резервную копию",

			"deleteDatabase": "Удалить базу данных",
			"deleteRestoreToDefault": "Удалить базу данных и восстановить заводские значения",
			"deleteDatabaseConfirmation": "Подтверждение удаления",
			"deleteDatabaseQuestion": "Вы собираетесь стереть все данные, вы уверены?",
			"deleteDatabaseSuccess": "Система успешно восстановлена ​​до заводских настроек",

			"backupRestore": "Восстановить резервную копию",
			"uploadBackup": "Перетащите или выберите файл резервной копии, который вы хотите восстановить",
			"restoreConfirmation": "Подтверждение восстановления",
			"restoreQuestion": "Вы собираетесь восстановить резервную копию системы, все данные будут удалены, вы уверены?",
			"restore": "Restore",
			"restoreSuccess": "Резервная копия была успешно восстановлена",
			"restoreOnlyOneFile": "Пожалуйста, выберите только один файл"
		},
	},
	sk: { // Slovak
		translation: {
			"title": "Správa údajov",

			"accessControl": "Kontrola prístupu",
			"username": "Meno používateľa",
			"password": "Heslo",
			"language": "Jazyk",
			"login": "Prístup",
			"incorrectUsernameOrPassword": "Nesprávne meno používateľa a / alebo heslo",

			"primaryKeyWithOnlyOneAttributeNeeded": "Primárny kľúč môže mať iba jeden atribút",

			"invalidValue": "Zadaná hodnota nie je platná",

			"selected": "selected",

			"deleteConfirmation": "Potvrdenie",
			"deleteQuestion": "Odstrániť",

			"cancel": "Zrušiť",
			"save": "Uložiť",

			"clear": "Odstrániť",

			"with": "s",

			"latitude": "Latitude",
			"longitude": "Dĺžka",

			"tableView": "Zobrazenie tabuľky",
			"calendarView": "Zobrazenie kalendára",
			"mapView": "Zobrazenie mapy",

			"calendarCulture": "is",
			"calendarDate": "Dátum",
			"calendarTime": "Čas",
			"calendarEvent": "Udalosť",
			"calendarAllDay": "Celý deň",
			"calendarWeek": "Týždeň",
			"calendarWorkWeek": "Pracovný týždeň",
			"calendarDay": "Deň",
			"calendarMonth": "Mesiac",
			"calendarPrevious": "Previous",
			"calendarNext": "Ďalej",
			"calendarYesterday": "Včera",
			"calendarTomorrow": "Zajtra",
			"calendarToday": "Dnes",
			"calendarAgenda": "Agenda",

			"view": "See",
			"edit": "Upraviť",
			"delete": "Odstrániť",
			"new": "Pridať",
			"newRecord": "Nový",

			"noOptions": "Žiadne možnosti",
			"loading": "Načítava sa ...",
			"selectValue": "",

			"noData": "Neboli nájdené žiadne výsledky",

			"enableBasicFilters": "Zobraziť základné filtre",
			"disableBasicFilters": "Skryť základné filtre",

			"search": "Hľadať",
			"searchCriteria": "Kritériá vyhľadávania",

			"deleteSuccess": "položky boli úspešne odstránené",

			"virtualAnalyst": "Indikátory",

			"closeSession": "Odhlásiť sa",

			"searchFilter": "Vyhľadávací filter",

			"adminGroup": "Admin",
			"admin": "Administratívne úlohy",
			"backupBackup": "Zálohovať",
			"downloadBackup": "Vykonať a prevziať zálohu",

			"deleteDatabase": "Odstrániť databázu",
			"deleteRestoreToDefault": "Vymazať databázu a obnoviť hodnoty z výroby",
			"deleteDatabaseConfirmation": "Potvrdenie odstránenia",
			"deleteDatabaseQuestion": "Chystáte sa vymazať všetky údaje, však?",
			"deleteDatabaseSuccess": "Systém bol úspešne obnovený na nastavenia z výroby",

			"backupRestore": "Obnoviť zálohu",
			"uploadBackup": "Pretiahnite alebo vyberte záložný súbor, ktorý chcete obnoviť",
			"restoreConfirmation": "Potvrdenie obnovenia",
			"restoreQuestion": "Chystáte sa obnoviť zálohu systému, všetky údaje sa vymažú, ste si istí?",
			"restore": "Obnoviť",
			"restoreSuccess": "Záloha bola úspešne obnovená",
			"restoreOnlyOneFile": "Vyberte len jeden súbor"
		},
	},
	sl: { // Slovenian
		translation: {
			"title": "Upravljanje podatkov",

			"accessControl": "Nadzor dostopa",
			"username": "Uporabniško ime",
			"password": "Geslo",
			"language": "Jezik",
			"login": "Dostop",
			"incorrectUsernameOrPassword": "Napačno uporabniško ime in / ali geslo",

			"primaryKeyWithOnlyOneAttributeNeeded": "Primarni ključ ima lahko samo en atribut",

			"invalidValue": "Vnesena vrednost ni veljavna",

			"selected": "izbrano",

			"deleteConfirmation": "Potrditev",
			"deleteQuestion": "Izbriši",

			"cancel": "Prekliči",
			"save": "Shrani",

			"clear": "Izbriši",

			"with": "s",

			"latitude": "Latitude",
			"longitude": "Dolžina",

			"tableView": "Pogled tabele",
			"calendarView": "Pogled koledarja",
			"mapView": "Pogled zemljevida",

			"calendarCulture": "je",
			"calendarDate": "Datum",
			"calendarTime": "Čas",
			"calendarEvent": "Dogodek",
			"calendarAllDay": "ves dan",
			"calendarWeek": "Teden",
			"calendarWorkWeek": "Delovni teden",
			"calendarDay": "dan",
			"calendarMonth": "mesec",
			"calendarPrevious": "Prejšnji",
			"calendarNext": "Naprej",
			"koledar včeraj": "Včeraj",
			"calendarTomorrow": "Jutri",
			"calendarToday": "Danes",
			"calendarAgenda": "Agenda",

			"view": "Glej",
			"edit": "Uredi",
			"delete": "Izbriši",
			"new": "Dodaj",
			"newRecord": "Novo",

			"noOptions": "Ni možnosti",
			"loading": "Nalaganje ...",
			"selectValue": "",

			"noData": "Ni rezultatov",

			"enableBasicFilters": "Prikaži osnovne filtre",
			"disableBasicFilters": "Skrij osnovne filtre",

			"search": "Iskanje",
			"searchCriteria": "Iskalni kriteriji",

			"deleteSuccess": "element (-i) je bil uspešno odstranjen",

			"virtualAnalyst": "Indikatorji",

			"closeSession": "Odjava",

			"searchFilter": "Iskalni filter",

			"adminGroup": "Skrbnik",
			"admin": "Upravne naloge",
			"backupBackup": "Varnostno kopiranje",
			"downloadBackup": "Izvedite in prenesite varnostno kopijo",

			"deleteDatabase": "Izbriši bazo podatkov",
			"deleteRestoreToDefault": "Izbrišite bazo podatkov in obnovite tovarniške vrednosti",
			"deleteDatabaseConfirmation": "potrditev brisanja",
			"deleteDatabaseQuestion": "Boste izbrisali vse podatke, ste prepričani?",
			"deleteDatabaseSuccess": "Sistem je bil uspešno obnovljen na tovarniške nastavitve",

			"backupRestore": "Obnovi varnostno kopijo",
			"uploadBackup": "Povlecite ali izberite datoteko varnostne kopije, ki jo želite obnoviti",
			"restoreConfirmation": "Potrditev obnove",
			"restoreQuestion": "Obnovili boste varnostno kopijo sistema, vsi podatki bodo izbrisani, ali ste prepričani?",
			"restore": "Obnovi",
			"restoreSuccess": "Varnostna kopija je bila uspešno obnovljena",
			"restoreOnlyOneFile": "Izberite samo eno datoteko"
		},
	},
	es_419: { // Spanish (Latin America)
		translation: {
			"title": "Administración de Datos",
			
			"accessControl": "Control de acceso",
			"username": "Nombre de usuario",
			"password": "Contraseña",
			"language": "Idioma",
			"login": "Acceder",
			"incorrectUsernameOrPassword": "Usuario y/o contraseña incorrectos",

			"primaryKeyWithOnlyOneAttributeNeeded": "La clave primaria solamente puede tener un atributo",
			
			"invalidValue": "El valor introducido no es válido",
			
			"selected": "seleccionados",
			
			"deleteConfirmation": "Confirmación",
			"deleteQuestion": "¿Eliminar",
			
			"cancel": "Cancelar",
			"save": "Guardar",

			"clear": "Borrar",

			"with": "con",
			
			"latitude": "Latitud",
			"longitude": "Longitud",
			
			"tableView": "Vista de tabla",
			"calendarView": "Vista de calendario",
			"mapView": "Vista de mapa",
			
			"calendarCulture": "es",
			"calendarDate": "Fecha",
			"calendarTime": "Hora",
			"calendarEvent": "Evento",
			"calendarAllDay": "Todo el día",
			"calendarWeek": "Semana",
			"calendarWorkWeek": "Semana laboral",
			"calendarDay": "Día",
			"calendarMonth": "Mes",
			"calendarPrevious": "Anterior",
			"calendarNext": "Siguiente",
			"calendarYesterday": "Ayer",
			"calendarTomorrow": "Mañana",
			"calendarToday": "Hoy",
			"calendarAgenda": "Agenda",
			
			"view": "Ver",
			"edit": "Editar",
			"delete": "Eliminar",
			"new": "Añadir",
			"newRecord": "Nuevo",
			
			"noOptions": "No hay opciones",
			"loading": "Cargando...",
			"selectValue": "",
			
			"noData": "No se han encontrado resultados",
			
			"enableBasicFilters": "Mostrar filtros básicos",
			"disableBasicFilters": "Ocultar filtros básicos",
			
			"search": "Buscar",
			"searchCriteria": "Criterios de búsqueda",
			
			"deleteSuccess": "elemento(s) eliminado(s) correctamente",
			
			"virtualAnalyst": "Indicadores",
			
			"closeSession": "Cerrar sesión",
			
			"searchFilter": "Filtro de búsqueda",

			"adminGroup": "Admin",
			"admin": "Tareas administrativas",
			"backupBackup": "Hacer copia de seguridad",
			"downloadBackup": "Realizar y descargar copia de seguridad",

			"deleteDatabase": "Eliminar base de datos",
			"deleteRestoreToDefault": "Eliminar base de datos y restaurar a valores de fábrica",
			"deleteDatabaseConfirmation": "Confirmación de borrado",
			"deleteDatabaseQuestion": "Vas a borrar todos los datos, ¿Estás seguro?",
			"deleteDatabaseSuccess": "El sistema se ha restaurado correctamente a los valores de fábrica",
			
			"backupRestore": "Restaurar copia de seguridad",
			"uploadBackup": "Arrastra o selecciona el archivo de copia de seguridad que quieres restaurar",
			"restoreConfirmation": "Confirmación de restauración",
			"restoreQuestion": "Vas a restaurar una copia de seguridad del sistema, se borrarán todos los datos, ¿Estás seguro?",
			"restore": "Restaurar",
			"restoreSuccess": "La copia de seguridad se ha restaurado correctamente",
			"restoreOnlyOneFile": "Por favor, seleccione solamente un archivo"
		},
	},
	sw: { // Swahili
		translation: {
			"title": "Usimamizi wa Data",

			"accessControl": "Udhibiti wa Upatikanaji",
			"username": "Jina la mtumiaji",
			"password": "Nenosiri",
			"language": "Lugha",
			"login": "Upatikanaji",
			"incorrectUsernameOrPassword": "Jina la mtumiaji usio sahihi na / au nenosiri",

			"primaryKeyWithOnlyOneAttributeIfunguliwa": "Muhimu wa msingi unaweza tu kuwa na sifa moja",

			"invalidValue": "Thamani iliyoingia haifai",

			"selected": "kuchaguliwa",

			"deleteChibitisho": "Uthibitisho",
			"deleteTuestion": "Futa",

			"cancel": "Futa",
			"save": "Hifadhi",

			"clear": "Futa",

			"with": "na",

			"latitude": "Latitude",
			"longitude": "Urefu",

			"tableView": "Jedwali mtazamo",
			"calendarView": "Mtazamo wa Kalenda",
			"mapView": "Ramani ya mtazamo",

			"calendarCulture": "ni",
			"calendarDate": "Tarehe",
			"calendarTime": "Wakati",
			"calendarEvent": "Tukio",
			"calendarAllDay": "Siku zote",
			"calendarWeek": "Wiki",
			"calendarWorkWeek": "Kazi ya wiki",
			"calendarDay": "Siku",
			"calendarMonth": "Mwezi",
			"calendarPrevious": "Iliopita",
			"calendarNext": "Next",
			"calendarJana": "Jana",
			"calendarKesho": "Kesho",
			"calendarToday": "Leo",
			"calendarAgenda": "Agenda",

			"view": "Angalia",
			"edit": "Hariri",
			"delete": "Futa",
			"new": "Ongeza",
			"newRecord": "Mpya",

			"noOptions": "Hakuna chaguo",
			"loading": "Loading ...",
			"selectValue": "",

			"noData": "Hakuna matokeo yaliyopatikana",

			"awezaBasicFilters": "Onyesha filters za msingi",
			"disableBasicFilters": "Ficha filters za msingi",

			"search": "Tafuta",
			"KutafutaCriteria": "Vigezo vya Utafutaji",

			"deleteCuccess": "bidhaa (s) imefutwa kwa ufanisi",

			"virtualAnalyst": "Viashiria",

			"closeSession": "Ingia nje",

			"searchFilter": "Tafuta kichujio",

			"adminGroup": "Admin",
			"admin": "kazi za utawala",
			"backupBackup": "Rudirisha",
			"chapakuaBackup": "Fanya na uhifadhi nakala ya malipo",

			"deleteDatabase": "Futa orodha",
			"deleteRestoreToDefault": "Futa orodha na urejeshe kwa maadili ya kiwanda",
			"deleteDatabaseConfirmation": "Uthibitishaji wa kufuta",
			"deleteDatabaseQuestion": "Utaondoa data zote, una uhakika?",
			"deleteDatabaseSuccess": "Mfumo umefanikiwa kurejeshwa kwenye mipangilio ya kiwanda",

			"backupRestore": "Rudisha salama",
			"uploadBackup": "Drag au chagua faili ya salama unayotaka kurejesha",
			"restoreUthibitisho": "Uhakikisho wa kurejesha",
			"restoreUfakari": "Utaenda kurejesha mfumo wa kuhifadhi, data yote itafutwa, una uhakika?",
			"restore": "Rudisha",
			"restoreSuccess": "Backup ilirejeshwa kwa ufanisi",
			"restoreOnlyOneFile": "Tafadhali chagua faili moja"
		},
	},
	sv: { // Swedish
		translation: {
			"title": "Datahantering",

			"accessControl": "Åtkomstkontroll",
			"username": "Användarnamn",
			"password": "Lösenord",
			"language": "Language",
			"login": "Access",
			"incorrectUsernameOrPassword": "Felaktigt användarnamn och / eller lösenord",

			"primaryKeyWithOnlyOneAttributeNeeded": "Den primära nyckeln kan bara ha ett attribut",

			"invalidValue": "Det angivna värdet är inte giltigt",

			"selected": "selected",

			"deleteConfirmation": "Bekräftelse",
			"deleteQuestion": "Ta bort",

			"cancel": "Avbryt",
			"save": "Spara",

			"clear": "Delete",

			"with": "med",

			"latitude": "Latitude",
			"longitude": "Length",

			"tableView": "Tabellvy",
			"calendarView": "Kalendervy",
			"mapView": "Kartvy",

			"calendarCulture": "är",
			"calendarDate": "Date",
			"calendarTime": "Time",
			"calendarEvent": "Event",
			"calendarAllDay": "hela dagen",
			"calendarWeek": "Vecka",
			"calendarWorkWeek": "Arbetsvecka",
			"calendarDay": "Day",
			"calendarMonth": "Månad",
			"calendarPrevious": "Previous",
			"calendarNext": "Next",
			"calendarYesterday": "Igår",
			"calendarTomorrow": "Imorgon",
			"calendarToday": "Idag",
			"calendarAgenda": "Agenda",

			"view": "See",
			"edit": "Redigera",
			"delete": "Ta bort",
			"ny": "Lägg till",
			"newRecord": "New",

			"noOptions": "Inga alternativ",
			"loading": "Laddar ...",
			"selectValue": "",

			"noData": "Inga resultat hittades",

			"enableBasicFilters": "Visa grundläggande filter",
			"disableBasicFilters": "Dölj grundläggande filter",

			"search": "Sök",
			"searchCriteria": "Sökkriterier",

			"deleteSuccess": "objekt (er) som har tagits bort",

			"virtualAnalyst": "Indikatorer",

			"closeSession": "Logga ut",

			"searchFilter": "Sökfilter",

			"adminGroup": "Admin",
			"admin": "Administrativa uppgifter",
			"backupBackup": "Back up",
			"downloadBackup": "Utför och ladda ner backup",

			"deleteDatabase": "Ta bort databas",
			"deleteRestoreToDefault": "Ta bort databas och återställ till fabriksvärden",
			"deleteDatabaseConfirmation": "Deletion-bekräftelse",
			"deleteDatabaseQuestion": "Du kommer att radera alla data, är du säker?",
			"deleteDatabaseSuccess": "Systemet har återställts till fabriksinställningarna",

			"backupRestore": "Återställ säkerhetskopiering",
			"uploadBackup": "Dra eller välj den backupfil du vill återställa",
			"restoreConfirmation": "Återställningsbekräftelse",
			"restoreQuestion": "Du kommer att återställa en säkerhetskopia av systemet, alla data kommer att raderas, är du säker?",
			"restore": "Återställ",
			"restoreSuccess": "Backupen har återställts framgångsrikt",
			"restoreOnlyOneFile": "Vänligen välj endast en fil"
		},
	},
	th: { // Thai
		translation: {
			"title": "การจัดการข้อมูล",

			"accessControl": "การควบคุมการเข้าถึง",
			"username": "ชื่อผู้ใช้",
			"password": "รหัสผ่าน",
			"language": "ภาษา",
			"login": "การเข้าถึง",
			"incorrectUsernameOrPassword": "ชื่อผู้ใช้และ / หรือรหัสผ่านไม่ถูกต้อง",

			"primaryKeyWithOnlyOneAttributeNeeded": "คีย์หลักสามารถมีได้เพียงแอตทริบิวต์เดียว",

			"invalidValue": "ค่าที่ป้อนไม่ถูกต้อง",

			"selected": "เลือกแล้ว",

			"deleteConfirmation": "การยืนยัน",
			"deleteQuestion": "ลบ",

			"cancel": "ยกเลิก",
			"save": "บันทึก",

			"clear": "ลบ",

			"with": "กับ",

			"latitude": "Latitude",
			"longitude": "ความยาว",

			"tableView": "มุมมองตาราง",
			"calendarView": "มุมมองปฏิทิน",
			"mapView": "มุมมองแผนที่",

			"calendarCulture": "คือ",
			"calendarDate": "Date",
			"calendarTime": "เวลา",
			"calendarEvent": "กิจกรรม",
			"calendar ทุกวัน": "ทั้งวัน",
			"calendarWeek": "Week",
			"calendarWorkWeek": "Work Week",
			"calendarDay": "วัน",
			"calendarMonth": "เดือน",
			"calendar ก่อนหน้า": "ก่อนหน้า",
			"calendar ถัดไป": "ถัดไป",
			"calendarY เมื่อวานนี้": "เมื่อวาน",
			"calendarTomorrow": "พรุ่งนี้",
			"calendar วันนี้": "วันนี้",
			"calendarAgenda": "วาระ",

			"view": "ดู",
			"edit": "แก้ไข",
			"delete": "ลบ",
			"new": "เพิ่ม",
			"newRecord": "ใหม่",

			"noOptions": "ไม่มีตัวเลือก",
			"loading": "กำลังโหลด ... ",
			"selectValue": "",

			"noData": "ไม่พบผลลัพธ์",

			"enableBasicFilters": "แสดงตัวกรองพื้นฐาน",
			"disableBasicFilters": "ซ่อนตัวกรองพื้นฐาน",

			"search": "ค้นหา",
			"searchCriteria": "เกณฑ์การค้นหา",

			"deleteSuccess": "ลบรายการสำเร็จแล้ว",

			"virtualAnalyst": "ตัวชี้วัด",

			"closeSession": "ออกจากระบบ",

			"searchFilter": "ตัวกรองการค้นหา",

			"adminGroup": "ผู้ดูแลระบบ",
			"admin": "งานการบริหาร",
			"backupBackup": "Back up",
			"downloadBackup": "ดำเนินการและดาวน์โหลดข้อมูลสำรอง",

			"deleteDatabase": "ลบฐานข้อมูล",
			"deleteRestoreToDefault": "ลบฐานข้อมูลและคืนค่าเป็นค่าโรงงาน",
			"deleteDatabaseConfirmation": "การยืนยันการลบ",
			"deleteDatabaseQuestion": "คุณกำลังจะลบข้อมูลทั้งหมดคุณแน่ใจหรือไม่",
			"deleteDatabaseSuccess": "ระบบได้รับการกู้คืนเป็นค่าติดตั้งจากโรงงานสำเร็จ",

			"backupRestore": "กู้คืนข้อมูลสำรอง",
			"uploadBackup": "ลากหรือเลือกไฟล์สำรองที่คุณต้องการกู้คืน",
			"restoreConfirmation": "การยืนยันการคืนค่า",
			"restoreQuestion": "คุณกำลังจะกู้คืนข้อมูลสำรองของระบบข้อมูลทั้งหมดจะถูกลบคุณแน่ใจหรือไม่",
			"restore": "กู้คืน",
			"restoreSuccess": "การสำรองข้อมูลได้รับการกู้คืนสำเร็จ",
			"restoreOnlyOneFile": "โปรดเลือกไฟล์เดียวเท่านั้น"
		},
	},
	tr: { // Turkish
		translation: {
			"title": "Veri Yönetimi",

			"accessControl": "Erişim kontrolü",
			"username": "Kullanıcı adı",
			"password": "Şifre",
			"language": "Dil",
			"login": "Erişim",
			"incorrectUsernameOrPassword": "Yanlış kullanıcı adı ve / veya şifre",

			"primaryKeyWithOnlyOneAttributeNeeded": "Birincil anahtarın yalnızca bir özelliği olabilir",

			"invalidValue": "Girilen değer geçerli değil",

			"selected": "seçildi",

			"deleteConfirmation": "Onay",
			"deleteQuestion": "Sil",

			"cancel": "İptal",
			"save": "Kaydet",

			"clear": "Sil",

			"with": "ile",

			"latitude": "Enlem",
			"longitude": "Uzunluk",

			"tableView": "Tablo görünümü",
			"calendarView": "Takvim görünümü",
			"mapView": "Harita görünümü",

			"calendarDate": "Tarih",
			"calendarTime": "Zaman",
			"calendarEvent": "Etkinlik",
			"calendarAllDay": "Bütün gün",
			"calendarWeek": "Hafta",
			"calendarWorkWeek": "Çalışma haftası",
			"calendarDay": "Gün",
			"calendarMonth": "Ay",
			"calendarPrevious": "Önceki",
			"calendarNext": "İleri",
			"calendarYesterday": "Dün",
			"calendarTomorrow": "Yarın",
			"calendarToday": "Bugün",
			"calendarAgenda": "Gündem",

			"view": "Gör",
			"edit": "Düzenle",
			"delete": "Sil",
			"new": "Ekle",
			"newRecord": "Yeni",

			"noOptions": "Seçenek yok",
			"loading": "Yükleniyor ...",
			"selectValue": "",

			"noData": "Sonuç bulunamadı",

			"enableBasicFilters": "Temel filtreleri göster",
			"disableBasicFilters": "Temel filtreleri gizle",

			"search": "Arama",
			"searchCriteria": "Arama kriterleri",

			"deleteSuccess": "öğe başarıyla kaldırıldı",

			"virtualAnalyst": "Göstergeler",

			"closeSession": "Çıkış",

			"searchFilter": "Arama filtresi",

			"adminGroup": "Yönetici",
			"admin": "İdari görevler",
			"backupBackup": "Yedekle",
			"downloadBackup": "Yedekleme gerçekleştir ve indir",

			"deleteDatabase": "Veritabanını sil",
			"deleteRestoreToDefault": "Veritabanını sil ve fabrika değerlerine geri yükle",
			"deleteDatabaseConfirmation": "Silme onayı",
			"deleteDatabaseQuestion": "Tüm verileri sileceksiniz, emin misiniz?",
			"deleteDatabaseSuccess": "Sistem fabrika ayarlarına başarıyla geri yüklendi",

			"backupRestore": "Yedeği geri yükle",
			"uploadBackup": "Geri yüklemek istediğiniz yedekleme dosyasını sürükleyin veya seçin",
			"restoreConfirmation": "Restorasyon onayı",
			"restoreQuestion": "Sistemin bir yedeğini geri yükleyeceksiniz, tüm veriler silinecek, emin misiniz?",
			"restore": "Geri Yükle",
			"restoreSuccess": "Yedekleme başarıyla geri yüklendi",
			"restoreOnlyOneFile": "Lütfen sadece bir dosya seçin"
		},
	},
	uk: { // Ukrainian
		translation: {
			"title": "Керування даними",

			"accessControl": "Керування доступом",
			"username": "Ім'я користувача",
			"password": "Пароль",
			"language": "Мова",
			"login": "Доступ",
			"incorrectUsernameOrPassword": "Неправильне ім'я користувача та / або пароль",

			"primaryKeyWithOnlyOneAttributeNeeded": "Первинний ключ може мати тільки один атрибут",

			"invalidValue": "Введене значення недійсне",

			"selected": "вибрано",

			"deleteConfirmation": "Підтвердження",
			"deleteQuestion": "Видалити",

			"cancel": "Скасувати",
			"save": "Зберегти",

			"clear": "Видалити",

			"with": "with",

			"latitude": "Latitude",
			"longitude": "Довжина",

			"tableView": "Перегляд таблиці",
			"calendarView": "Перегляд календаря",
			"mapView": "Перегляд карти",

			"calendarCulture": "is",
			"calendarDate": "Дата",
			"calendarTime": "Час",
			"calendarEvent": "Подія",
			"calendarAllDay": "Весь день",
			"calendarWeek": "Тиждень",
			"calendarWorkWeek": "Робочий тиждень",
			"calendarDay": "День",
			"calendarMonth": "місяць",
			"calendarPrevious": "Попередній",
			"calendarNext": "Далі",
			"calendarYesterday": "Вчора",
			"calendarTomorrow": "Завтра",
			"calendarToday": "Сьогодні",
			"calendarAgenda": "Порядок денний",

			"view": "Див.",
			"edit": "Редагувати",
			"delete": "Видалити",
			"new": "Додати",
			"newRecord": "Новий",

			"noOptions": "Немає опцій",
			" loading": "Завантаження ...",
			"selectValue": "",

			"noData": "Не знайдено результатів",

			"enableBasicFilters": "Показати основні фільтри",
			"disableBasicFilters": "Приховати базові фільтри",

			" пошук": "Пошук",
			"searchCriteria": "Критерії пошуку",

			"deleteSuccess": "елемент (и) успішно видалено",

			"virtualAnalyst": "Індикатори",

			"closeSession": "Вийти",

			"searchFilter": "Фільтр пошуку",

			"adminGroup": "Адміністратор",
			"admin": "Адміністративні завдання",
			"backupBackup": "Резервне копіювання",
			"downloadBackup": "Виконати та завантажити резервну копію",

			"deleteDatabase": "Видалити базу даних",
			"deleteRestoreToDefault": "Видалити базу даних і відновити заводські значення",
			"deleteDatabaseConfirmation": "Підтвердження видалення",
			"deleteDatabaseQuestion": "Ви збираєтеся стерти всі дані, ви впевнені?",
			"deleteDatabaseSuccess": "Система успішно відновлена ​​до заводських налаштувань",

			"backupRestore": "Відновити резервну копію",
			"uploadBackup": "Перетягніть або виберіть файл резервної копії, який потрібно відновити",
			"restoreConfirmation": "Підтвердження відновлення",
			"restoreQuestion": "Ви збираєтеся відновити резервну копію системи, всі дані будуть видалені, ви впевнені?",
			"restore": "Відновити",
			"restoreSuccess": "Резервне копіювання успішно відновлено",
			"restoreOnlyOneFile": "Будь ласка, виберіть тільки один файл"
		},
	},
	vi: { // Vietnamese
		translation: {
			"title": "Quản lý dữ liệu",

			"accessControl": "Kiểm soát truy cập",
			"username": "Tên người dùng",
			"password": "Mật khẩu",
			"language": "Ngôn ngữ",
			"login": "Truy cập",
			"incorrectUsernameOrPassword": "Tên người dùng và / hoặc mật khẩu không chính xác",

			"primaryKeyWithOnlyOneAttributionNeeded": "Khóa chính chỉ có thể có một thuộc tính",

			"invalidValue": "Giá trị đã nhập không hợp lệ",

			"selected": "đã chọn",

			"deleteConf Confirmation": "Xác nhận",
			"deleteQuestion": "Xóa",

			"celon": "Hủy",
			"save": "Lưu",

			"clear": "Xóa",

			"with": "với",

			"latitude": "Vĩ độ",
			"longitude": "Độ dài",

			"tableView": "Chế độ xem bảng",
			"calWikiView": "Chế độ xem lịch",
			"mapView": "Chế độ xem bản đồ",

			"calWikiCARM": "là",
			"calWikiDate": "Ngày",
			"calWikiTime": "Thời gian",
			"calWikiEvent": "Sự kiện",
			"calWiki ALLDay": "Cả ngày",
			"calWikiWeek": "Tuần",
			"calWikiWorkWeek": "Tuần làm việc",
			"calWikiDay": "Ngày",
			"calWikiMonth": "Tháng",
			"calWikiPreingly": "Trước",
			"calWikiNext": "Tiếp theo",
			"calWikiYday": "Hôm qua",
			"calWikiTomorrow": "Ngày mai",
			"calWikiToday": "Hôm nay",
			"calWikiAgenda": "Chương trình nghị sự",

			"view": "Xem",
			"edit": "Chỉnh sửa",
			"delete": "Xóa",
			"new": "Thêm",
			"newRecord": "Mới",

			"noOptions": "Không có tùy chọn",
			"loading": "Đang tải ...",
			"selectValue": "",

			"noData": "Không tìm thấy kết quả",

			"enableBasicFilters": "Hiển thị các bộ lọc cơ bản",
			"disableBasicFilters": "Ẩn các bộ lọc cơ bản",

			"search": "Tìm kiếm",
			"searchCriteria": "Tiêu chí tìm kiếm",

			"deleteSuccess": "mục (s) đã xóa thành công",

			"virtualAnalyst": "Các chỉ số",

			"closeSession": "Đăng xuất",

			"searchFilter": "Bộ lọc tìm kiếm",

			"admingroup": "Quản trị viên",
			"admin": "Nhiệm vụ quản trị",
			"backupBackup": "Sao lưu",
			"doadBackup": "Thực hiện và tải xuống bản sao lưu",

			"deleteDatabase": "Xóa cơ sở dữ liệu",
			"deleteRestoreToDefault": "Xóa cơ sở dữ liệu và khôi phục giá trị xuất xưởng",
			"deleteDatabaseConf Confirmation": "Xác nhận xóa",
			"deleteDatabaseQuestion": "Bạn sẽ xóa tất cả dữ liệu, bạn có chắc không?",
			"deleteDatabaseSuccess": "Hệ thống đã được khôi phục thành công về cài đặt gốc",

			"backupRestore": "Khôi phục sao lưu",
			"uploadBackup": "Kéo hoặc chọn tệp sao lưu bạn muốn khôi phục",
			"restoreConf Confirmation": "Xác nhận phục hồi",
			"restoreQuestion": "Bạn sẽ khôi phục bản sao lưu của hệ thống, tất cả dữ liệu sẽ bị xóa, bạn có chắc không?",
			"restore": "Khôi phục",
			"restoreSuccess": "Bản sao lưu đã được khôi phục thành công",
			"restoreOnlyOneFile": "Vui lòng chỉ chọn một tệp"
		},
	},
	zu: { // Zulu
		translation: {
			"title": "Ukuphathwa kwedatha",

			"accessControl": "Ukulawula ukufinyelela",
			"username": "Igama lomsebenzisi",
			"password": "Iphasiwedi",
			"language": "Ulimi",
			"login": "Ukufinyelela",
			"incorrectUsernameOrPassword": "Igama lomsebenzisi elingalungile kanye / noma iphasiwedi",

			"primaryKeyWithOnlyOneAttributionNeeded": "Ukhiye oyinhloko unomphumela owodwa kuphela",

			"invalidValue": "Inani elifakiwe alivumelekile",

			"selected": "okukhethiwe",

			"deleteIsiqinisekiso": "Isiqinisekiso",
			"deleteQuestion": "Susa",

			"cancel": "Khansela",
			"save": "Londoloza",

			"clear": "Susa",

			"with": "nge",

			"latitude": "Latitude",
			"longitude": "Ubude",

			"bableBuka": "Ukubuka kwethebula",
			"calendarBuka": "Ukubuka kwekhalenda",
			"mapBuka": "Ukubuka kwemephu",

			"calendarCulture": "is",
			"calendarDate": "Usuku",
			"calendarTime": "Isikhathi",
			"calendarEvent": "Umcimbi",
			"calendarAllDay": "Usuku lonke",
			"calendarWeek": "Iviki",
			"calendarWorkWeek": "Iviki lomsebenzi",
			"calendarDay": "Usuku",
			"calendarMonth": "Inyanga",
			"i-calendarAyikho": "Emuva",
			"calendarNext": "Okulandelayo",
			"calendarIzango": "Izolo",
			"calendarNgomuso": "Kusasa",
			"calendarToday": "Namuhla",
			"calendarAgenda": "I-Agenda",

			"view": "Bheka",
			"edit": "Hlela",
			"delete": "Susa",
			"new": "Engeza",
			"newRecord": "Okusha",

			"noOptions": "Akukho okukhethwa kukho",
			"loading": "Iyalayisha ...",
			"selectValue": "",

			"noData": "Ayikho imiphumela etholakalayo",

			"enableBasicFilters": "Bonisa izihlungi eziyisisekelo",
			"disableBasicFilters": "Fihla izihlungi eziyisisekelo",

			"search": "Search",
			"searchCriteria": "Izindlela zokusesha",

			"deleteSuccess": "into (s) isusiwe ngempumelelo",

			"virtualAnalyst": "Izibonisi",

			"closeSession": "Phuma ngaphandle",

			"searchFilter": "Isihlungi sosesho",

			"adminGroup": "Admin",
			"admin": "imisebenzi yokuphatha",
			"backupBackup": "Emuva",
			"landaBackup": "Yenza futhi ulanda isipele",

			"deleteDatabase": "Susa idatha",
			"deleteRestoreToDefault": "Susa idatha futhi ubuyiselwe kuma-value values",
			"deleteDatabaseConfirmation": "Isiqinisekiso sokususa",
			"deleteDatabaseQuestion": "Uzosula yonke idatha, uqinisekile?",
			"deleteDatabaseSuccess": "Uhlelo lubuyiselwe ngempumelelo kuzilungiselelo zefektri",

			"backupRestore": "Buyisela isipele",
			"uploadBackup": "Hudula noma ukhethe ifayela lokusekelayo ofuna ukuyibuyisela",
			"restoreIsiqinisekiso": "Ukubuyiselwa isiqiniseko",
			"restoreIsixwayiso": "Uzobuyisela isilondolozi sesistimu, yonke idatha izosuswa, uqinisekile?",
			"restore": "Buyisela",
			"restoreUkuphumelela": "Isipele sabuyiselwa ngempumelelo",
			"restoreOnlyOneFile": "Sicela ukhethe ifayela elilodwa kuphela"
		},
	},
};

i18n.use(reactI18nextModule)
	.use(LanguageDetector)
	.init({
		fallbackLng: "en_US",
		resources: resources,
		interpolation: {
			escapeValue: false
		}
	});
